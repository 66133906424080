import AccountTitle from '@/layouts/AccountLayout/AccountTitle'
import ArticleHistoryTable from '@/components/account/ArticleHistoryTable'
import React from 'react'
import { useTranslation } from 'react-i18next'

const ArticleHistory = () => {

  const {t} = useTranslation()

  return (
    <div>
        <AccountTitle title={t('article-history')}/>
        <ArticleHistoryTable  />
    </div>
  )
}

export default ArticleHistory