import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import SwipperCore, { Autoplay, Pagination, Navigation } from "swiper";

import { Card, Col, Row, Skeleton } from "antd";

import { useSelector } from "react-redux";
import fetchSlider from "@/core/slider/fetchSlider";
import "swiper/css";
import { useWindowDimensions } from '@/hooks/useWindowDimensions';

SwipperCore.use([Autoplay])

// import FeaturedBlock from "./FeaturedBlock/FeaturedBlock";

const IndexCarousel = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const settings = useSelector(e => e.settings)
  console.log(settings);
  useEffect(() => {
    setLoading(true)
    fetchSlider()
      .then((res) => {
        setData(res);
        //setData([{URL : 'https://webshop.parts-soft.eu/5vsy223yll9f672hx.jpg'}])
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setLoading(false)
      });
  }, []);

  const { width, height } = useWindowDimensions();

  return (
    <div className="index-carousel" style={
      width > 768 ? {
        height: '100%',
        // maxHeight: "407px",
        border: `1px solid ${settings['HeaderBgColor']}27`,
        borderRadius: "6px"
      } : {
        height: '100%',
        border: `1px solid ${settings['HeaderBgColor']}27`,
        borderRadius: "6px"
      }
    }>
      <Skeleton loading={loading}>
        <div className="index-carousel__container">
          <div className="index-carousel__container__slider">
            <Swiper loop={true} autoplay={{
              delay: 10000,
              disableOnInteraction: false,
            }} spaceBetween={50} slidesPerView={1} onSlideChange={() => 1} onSwiper={(swiper) => swiper}>
              {data.map((item, index) => (
                <SwiperSlide key={index}>
                  <img style={{ width: "100%" }} src={item.URL} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </Skeleton>
      {/* <div>
        <FeaturedBlock/>
      </div> */}
    </div >
  );
};

export default IndexCarousel;
