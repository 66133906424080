const OemIcon = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={7}
      viewBox="0 0 18 7"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.17 4.844c.24-.472.36-1.035.36-1.69 0-.656-.12-1.219-.36-1.688A2.616 2.616 0 0 0 4.186.38 2.666 2.666 0 0 0 2.767 0C2.235 0 1.76.127 1.343.38A2.622 2.622 0 0 0 .36 1.467C.12 1.936 0 2.498 0 3.153c0 .654.12 1.216.36 1.688s.567.834.983 1.088c.418.252.892.378 1.424.378a2.68 2.68 0 0 0 1.418-.378 2.64 2.64 0 0 0 .986-1.085Zm-.8-2.953c.162.342.243.763.243 1.262 0 .5-.08.922-.243 1.265-.16.342-.38.602-.66.78a1.728 1.728 0 0 1-.943.263c-.354 0-.67-.087-.948-.263a1.822 1.822 0 0 1-.659-.78C1 4.075.92 3.653.92 3.153c0-.5.08-.92.24-1.262.162-.343.382-.603.66-.779.277-.178.593-.267.947-.267.351 0 .666.09.944.267.28.176.5.436.66.78Zm2.315 4.332V.084h3.849v.797H7.61v1.87h2.722v.795H7.611v1.88h2.959v.797H6.685ZM11.772.084h1.123l1.955 4.772h.072L16.876.084H18v6.139h-.881V1.78h-.057l-1.81 4.433h-.732l-1.81-4.436h-.057v4.445h-.882V.084Z"
        fill={props.color || "#8D8D8D"}
      />
    </svg>
  )

  export default OemIcon