import { Collapse, message, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import BasketItemCollapseHeader from './BasketItemCollapseHeader';
import { UpOutlined, DownOutlined } from '@ant-design/icons'
import BasketItemCollpaseItems from './BasketItemCollapseItems';
import fetchProductGroupName from '@/core/product/fetchProductGroupName';
const { Panel } = Collapse;

const BasketItemCollapse = ({ product }) => {
  const [description, setDescription] = useState(null);

  useEffect(() => {
    fetchProductGroupName(product.ProductReference, product.BrandId).then(res => {
      setDescription(res)
    }).catch(f => {
      setDescription(null)
      console.log(f)
    })
  }, [product])


  return (
    <Spin spinning={false}>
      <Collapse className='basket-item-collapse' expandIconPosition='right' expandIcon={() => null}  >
        <Panel header={
          <BasketItemCollapseHeader prices={product?.prices} Description={product?.Category} Image={product?.Image} Plate={product?.LicensePlate} Reference={product?.ProductReference} BrandName={product?.BrandName} BrandId={product?.BrandId} />
        } key="1">
          <BasketItemCollpaseItems basketItem={product} />
        </Panel>
      </Collapse>
    </Spin>
  );
};
export default BasketItemCollapse;
