const DimensionalIcon = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={17}
      height={10}
      viewBox="0 0 17 10"
      fill="none"
      {...props}
    >
      <path
        d="M11.958 9.867h-3.15V.133h3.25c.954 0 1.773.195 2.457.585a3.853 3.853 0 0 1 1.573 1.668c.368.723.552 1.59.552 2.6 0 1.014-.186 1.885-.556 2.614-.368.729-.9 1.29-1.597 1.683-.697.39-1.54.584-2.529.584Zm-1.682-1.283h1.601c.742 0 1.358-.14 1.85-.418.49-.282.858-.69 1.102-1.222.244-.535.366-1.188.366-1.958 0-.764-.122-1.412-.366-1.944-.24-.532-.6-.936-1.079-1.212-.478-.276-1.072-.413-1.782-.413h-1.692v7.167ZM3.436 10c-.652 0-1.235-.112-1.749-.337a2.974 2.974 0 0 1-1.212-.937A2.486 2.486 0 0 1 0 7.33h1.492c.02.288.116.539.29.75.178.21.41.372.694.486.285.114.602.17.95.17.384 0 .723-.066 1.018-.199.298-.133.53-.318.699-.556.168-.24.251-.518.251-.832 0-.326-.083-.613-.251-.86a1.64 1.64 0 0 0-.728-.59c-.316-.142-.7-.213-1.15-.213h-.822V4.287h.822c.361 0 .678-.065.95-.195.276-.13.492-.31.647-.542.155-.234.233-.508.233-.822 0-.3-.068-.562-.204-.784a1.373 1.373 0 0 0-.57-.528 1.853 1.853 0 0 0-.866-.19c-.317 0-.613.059-.888.176a1.64 1.64 0 0 0-.666.494 1.271 1.271 0 0 0-.276.766H.205c.015-.526.17-.989.465-1.388.298-.4.69-.712 1.179-.937A3.836 3.836 0 0 1 3.474 0C4.1 0 4.637.122 5.09.366c.457.24.808.562 1.055.965.25.402.374.843.371 1.321.003.545-.149 1.008-.456 1.388-.304.38-.71.635-1.217.765v.076c.647.098 1.147.355 1.502.77.358.415.536.93.532 1.545a2.404 2.404 0 0 1-.446 1.44 3.042 3.042 0 0 1-1.222 1.003c-.516.24-1.107.361-1.773.361Z"
        fill={props.color || "#8D8D8D"}
      />
    </svg>
  )

  export default DimensionalIcon