import React, { useState } from 'react'
import { SortableElement } from 'react-sortable-hoc';
import { BarsOutlined, DeleteOutlined, EditOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import api from '@/services/api'
import TrashIcon from '@/extra/TrashIcon';
import useDeleteCategory from '@/hooks/adminTecdoc/useDeleteCategory';
import CategoryUpdate from './CategoryUpdate';
const CategoryItem = ({ value, onClickDelete, onUpdate, onClick, hideEdit = false,showSubCategory=true }) => {


  const deleteCategory = useDeleteCategory()

  const [showEditModal, setShowEditModal] = useState(false);


  const onClickDeleteHandler = () => {
    deleteCategory.deleteCategory(value?.id).then(e => {
      onClickDelete && onClickDelete(value?.id)
    }).catch(f => {
      onClickDelete && onClickDelete(null)
    }).finally(() => {
    })
  }



  const onClickEditHandler = () => {
    setShowEditModal(true)
  }

  const onUpdateHandler = (e) => {
    onUpdate && onUpdate(e)
    setShowEditModal(false)
  }

  const onClickHandler = () => {
    onClick && onClick(value)
  }

  return (
    <li style={{
      width: '100%',
      padding: '12px',
      // border: '1px solid #ccc',
      marginBottom: '10px',
      cursor: 'move',
      borderRadius: '24px',
      listStyle: 'none',
      //backgroundColor: '#F2F5F6',
      backgroundColor: '#fff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      boxShadow: `rgba(0, 0, 0, 0.05) 0px 0px 0px 1px`
    }}>
      <div style={{
        display: 'flex',
        alignItems: 'center',
      }}>
        <p><BarsOutlined style={{
          fontSize: '22px'
        }} /></p>
        <button style={{
          marginLeft: '10px',
        }} onClick={onClickHandler}>{value?.categoryName + ` (${value?.genericArticleId})` }</button>
      </div>


      <CategoryUpdate onUpdate={onUpdateHandler} id={value?.id} show={showEditModal} onCancel={() => setShowEditModal(false)} />

      <div>
      
        {showSubCategory && <Typography.Text style={{
          marginRight: '15px',
          cursor: 'pointer'
        }}>{`Subcategories (${(value?.childs)})`}</Typography.Text>}

        <Button loading={deleteCategory.loading} style={{
          marginRight: '10px'
        }} type='default' icon={<DeleteOutlined onClick={() => onClickDeleteHandler()} style={{
          fontSize: '20px',
        }} />} />

        {hideEdit == true && <Button onClick={onClickEditHandler} type='primary' icon={
          <EditOutlined style={{

            fontSize: '20px',
            cursor: 'pointer'
          }} />

        } />}






      </div>

    </li >
  )
}

export default SortableElement(CategoryItem)