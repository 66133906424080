const DocumentIcon = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={15}
      height={16}
      viewBox="0 0 15 16"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.174 2.174c.301-.302.713-.48 1.42-.576C4.316 1.501 5.266 1.5 6.6 1.5H8c1.334 0 2.284.001 3.006.098.707.095 1.119.274 1.42.576.302.301.48.713.576 1.42.097.722.098 1.672.098 3.006v2.8c0 1.334-.001 2.284-.098 3.006-.095.707-.274 1.119-.576 1.42-.301.302-.713.48-1.42.576-.722.097-1.672.098-3.006.098H6.6c-1.334 0-2.284-.001-3.006-.098-.707-.095-1.119-.274-1.42-.576-.302-.301-.48-.713-.576-1.42-.097-.722-.098-1.672-.098-3.006V6.6c0-1.334.001-2.284.098-3.006.095-.707.274-1.119.576-1.42ZM8.038.5H6.562C5.274.5 4.257.5 3.461.607c-.818.11-1.476.341-1.994.86-.519.518-.75 1.176-.86 1.994C.5 4.257.5 5.274.5 6.562v2.876c0 1.288 0 2.305.107 3.101.11.818.341 1.476.86 1.994.518.519 1.176.75 1.994.86.796.107 1.813.107 3.101.107h1.476c1.288 0 2.305 0 3.101-.107.818-.11 1.476-.341 1.994-.86.519-.518.75-1.176.86-1.994.107-.796.107-1.813.107-3.101V6.562c0-1.288 0-2.305-.107-3.101-.11-.818-.341-1.476-.86-1.994-.518-.519-1.176-.75-1.994-.86C10.343.5 9.326.5 8.038.5ZM9.714 6v3.569h.646V8.053h1.483V7.51H10.36v-.97H12V6H9.714ZM7.367 9.569h-1.21V6h1.235c.354 0 .658.071.913.214.255.142.452.346.589.612.137.266.205.584.205.955 0 .372-.069.691-.207.958a1.433 1.433 0 0 1-.594.615c-.258.143-.568.215-.93.215Zm-.563-.56h.532c.248 0 .456-.045.622-.135a.87.87 0 0 0 .375-.41c.083-.182.125-.41.125-.683 0-.273-.042-.5-.125-.68a.855.855 0 0 0-.372-.406 1.232 1.232 0 0 0-.606-.136h-.55v2.45ZM3 6v3.569h.647V8.363h.683c.275 0 .507-.05.695-.152.188-.102.33-.242.427-.42.096-.177.144-.38.144-.61 0-.226-.048-.428-.144-.606a1.022 1.022 0 0 0-.424-.422A1.416 1.416 0 0 0 4.338 6H3Zm.647 1.831v-1.29h.592c.161 0 .293.026.396.081a.512.512 0 0 1 .228.225c.05.096.075.208.075.334a.728.728 0 0 1-.075.337.528.528 0 0 1-.227.23.818.818 0 0 1-.394.083h-.595Z"
        fill={props.color || "#8D8D8D"}
      />
    </svg>
  )


  export default DocumentIcon;