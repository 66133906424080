import { message, Modal, Skeleton } from 'antd';
import React, { useEffect } from 'react';
import { Button, Checkbox, Form, Input } from 'antd';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useCreateCategory from '@/hooks/adminTecdoc/useCreateCategory';
import useUpdateCategory from '@/hooks/adminTecdoc/useUpdateCategory';
const onFinish = (values) => {
    console.log('Success:', values);
};
const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
};

const CategoryUpdate = ({ show, onCancel, onUpdate, id, parent = 0 }) => {

    const siteSettings = useSelector(e => e?.settings);
    const languages = siteSettings?.languageList;
    const { t } = useTranslation();
    const updateCategory = useUpdateCategory()

    const [form] = Form.useForm()

    const onFinishForm = () => {
        form.validateFields().then(e => {

            const { categoryName } = form.getFieldsValue()

            updateCategory.update(id, categoryName, parent).then(e => {
                onUpdate && onUpdate(e)
            }).catch(f => {
                message.error(f?.response?.data?.message || "Error")
            })

        }).catch(f => {
            
        })
    }


    useEffect(() => {
        if (!show || !id) return
        updateCategory.fetch(id).then(e => {
            form.setFieldsValue({
                categoryName: e?.categoryName
            })
        })
    }, [id, show])


    return (
        <Modal loading={updateCategory.loading} title="Uodate" onOk={() => {
            onFinishForm()
        }} okButtonProps={
            {
                loading: updateCategory.loading
            }
        } okText={t('save')} cancelButtonProps={
            {
                style: {
                    display: 'none'
                }
            }
        } open={show} onCancel={onCancel}>
            <Skeleton loading={updateCategory.loadingFetch}>
                <Form
                    form={form}
                    name="basic"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >

                    <Form.Item

                        label={`Category Name`}
                        name="categoryName"
                        rules={[
                            {
                                required: true,
                            }
                        ]}
                    >
                        <Input placeholder={t('category-name')} />
                    </Form.Item>
                </Form>
            </Skeleton>
        </Modal>
    )
}

export default CategoryUpdate