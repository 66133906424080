import React, { useState } from 'react'

const TrashIcon = ({onClick}) => {
  const [active,setActive] = useState(false)
    return (
    <svg
    onClick={onClick ? onClick : () => { }}
    onMouseOver={e => setActive(true)}
    onMouseLeave={e => setActive(false)}
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    fill="none"
    style={{cursor:active ? 'pointer' : ''}}

    viewBox="0 0 17 17"
  >
    <path
      fill={active ? 'red' : '#c1c1c1'}
      fillRule="evenodd"
      d="M15.795 2.182a.521.521 0 110 1.044l-1.156-.001-.967 11.613a1.613 1.613 0 01-1.608 1.479h-7.81a1.612 1.612 0 01-1.608-1.479l-.97-11.614-1.154.002a.521.521 0 01-.514-.428L0 2.704c0-.29.234-.522.522-.522l4.387-.001v-.569c0-.84.644-1.532 1.466-1.606L6.521 0h3.274c.89 0 1.612.722 1.612 1.612v.569l4.388.001zm-2.204 1.044H2.725l.962 11.525a.57.57 0 00.568.522h7.81a.57.57 0 00.567-.521l.96-11.526zM6.522 6c.288 0 .522.234.522.522v5.455a.522.522 0 01-1.044 0V6.522C6 6.234 6.234 6 6.522 6zm3.273 0c.288 0 .521.234.521.522v5.455a.522.522 0 01-1.043 0V6.522c0-.288.234-.522.522-.522zm0-4.956H6.522a.569.569 0 00-.57.568v.57h4.411v-.57a.569.569 0 00-.568-.568z"
      clipRule="evenodd"
    ></path>
  </svg>
  )
}

export default TrashIcon