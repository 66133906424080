import React from 'react'
const MakeItem = ({ image, text, redirect, onClick }) => {

  const click = () => {
    if (onClick) {
      onClick();
    }
  }

  return (
    <div onClick={click} style={{
      cursor: 'pointer',
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "5px",
    }}>
      <div className='brand-list' style={{ minWidth: '120px' }}>
        <img style={{ height: '80px' }} src={image} />
      </div>
      <div>
        <p style={{
          color: "#4B465C",
          fontFamily: "Inter",
          fontSize: "13px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "normal",
          textTransform: "uppercase",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "100%",
        }}>{text}</p>
      </div>
    </div >
  )
}

export default MakeItem
