
import { Table, Row, Col, Input, Card, Spin, Button, Result, Skeleton, message, Space, Typography } from "antd";
import { SearchOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import fetchSeries from "@/core/vehicle/fetchVehicleSeries";
import Highlighter from "react-highlight-words";
import VehicleInfo from "./VehicleInfo";




const VehicleSeriesTable = ({ line, onClick, handleReset }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);
  const [originalData, setOriginalData] = useState([])
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const [reRenderCarInformation, setReRenderCarInformation] = useState(parseInt(Math.random() * 1000));
  const { t } = useTranslation();

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };


  const toFilter = (e) => {
    if (e.target.value === '') {
      return setData(originalData)
    }
    let temp = []
    for (const item of originalData) {
      if (item.VehicleReferenceName.toLowerCase().includes(e.target.value.toLowerCase()) || item.EngineType.toLowerCase().includes(e.target.value.toLowerCase()) || item.EngineContents.toLowerCase().includes(e.target.value.toLowerCase())) {
        temp.push(item)
      }
    }

    setData(temp)

  }



  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {

      let parse = null
      try {
        parse = JSON.stringify(record)
        let re = new RegExp(value, 'i');
        if (parse.search(re) != -1)
          return true
        return false

      } catch (e) {
        return false
      }

      //record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  useEffect(() => {
    setLoading(true)
    fetchSeries(line).then(e => {

      let hash = {};
      let newList = []

      let color1 = '#E9FFF9'
      let color2 = '#ECEEF7'

      e.forEach(item => {
        if (!item.VehicleReferenceName)
          item.VehicleReferenceName = 'No Name';
        if (hash[item.VehicleReferenceName]) {
          hash[item.VehicleReferenceName].push(item);
        } else {
          hash[item.VehicleReferenceName] = []
          hash[item.VehicleReferenceName].push(item);
        }
      })

      let sorting = Object.keys(hash).sort()

      for (const item of sorting) {
        newList.push(...hash[item].map(x => ({ ...x, color: (newList.length % 2 === 0) ? color1 : color2 })))
      }



      setData(newList)
      setOriginalData(e)
    })
      .catch(f => console.log(f))
      .finally(() => setLoading(false))
  }, [line])



  const columns = [

    {
      title: t('series'),
      dataIndex: "VehicleReferenceName",
      key: "VehicleReferenceName",
      ...getColumnSearchProps('Series'),
      render: (a, b) => {
        return b.VehicleReferenceName;
      },
    },

    {
      title: t("body-type"),
      dataIndex: "Body Type",
      key: "Body Type",
      ...getColumnSearchProps('Series'),

    },

    {
      title: t("engine-type"),
      dataIndex: "EngineType",
      key: "EngineType",
      ...getColumnSearchProps('EngineType'),

      render: (a, b) => {

        return ({
          children: <div className="td-special">
            <div>{b.EngineType}</div>
          </div>, props: {
            style: { background: b.color }
          }
        })
      }
    },
    {
      title: t("transmission"),
      dataIndex: "Transmission",
      key: "Transmission",
    },
    {
      title: t("fuel-code"),
      dataIndex: "FuelCode",
      key: "FuelCode",
    },
    {
      title: t("horse-power"),
      dataIndex: "HorsePower",
      key: "HorsePower",

    },
    {
      title: t("cyclinder"),
      dataIndex: "Cylinder",
      key: "Cylinder",
      render: (a, b) => {
        return b.TotalCylinders;
      },
    },

    {
      title: t("build-start"),
      dataIndex: "BuildStart",
      key: "BuildStart",
    },
    {
      title: t("build-end"),
      dataIndex: "BuildEnd",
      key: "BuildEnd",
    },

  ];


  return (
    <div>
      <div>
        <VehicleInfo Line={line}  />
      </div>
      <div tip="loading ..." active={true} loading={loading}>
        {!error ? (
          <Row gutter={16}>
            <Col lg={24}>

              <Input.Search onChange={(e) => toFilter(e)} size="large" placeholder={t('search')} />

              <Card className="mt-4" loading={loading}>
                <div>
                  <Table
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: event => {
                          onClick(record.VehicleReferenceId, record.VehicleReferenceName)
                        }, // click row
                        style: { cursor: 'pointer' },
                      };
                    }}
                    size="small"
                    pagination={false}
                    columns={columns}
                    dataSource={data.map((item) => {
                      return { ...item, key: item.Id };
                    })}
                  />
                </div>
              </Card>
            </Col>
          </Row>




        ) : (
          <Card style={{ marginTop: "70px" }}>
            <Result status="warning" title={t("some-problem")} />
          </Card>
        )}
      </div>
    </div>
  );
};

export default VehicleSeriesTable;
