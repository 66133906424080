const CarIcon = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={19}
      height={16}
      viewBox="0 0 19 16"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.733 1.5c-.856 0-1.615.537-1.94 1.297l-.002.002-1.029 2.368H15.57l-1.023-2.355C14.15 2.03 13.38 1.5 12.533 1.5h-5.8Zm-2.86.902C4.349 1.296 5.456.5 6.734.5h5.8c1.282 0 2.375.798 2.914 1.876a.669.669 0 0 1 .011.025l.913 2.099h1.963a.5.5 0 0 1 0 1h-1.257a3.175 3.175 0 0 1 1.756 2.834V11c0 .567-.259 1.075-.665 1.412v1.255c0 1.01-.824 1.833-1.833 1.833H15a1.837 1.837 0 0 1-1.833-1.833v-.833h-7v.833c0 1.01-.824 1.833-1.833 1.833H3a1.837 1.837 0 0 1-1.833-1.833v-1.254A1.831 1.831 0 0 1 .5 11V8.334c0-1.237.717-2.313 1.756-2.834H1a.5.5 0 0 1 0-1h1.962l.911-2.097v-.001ZM17 12.834h-2.832v.833c0 .457.376.833.833.833h1.334a.837.837 0 0 0 .833-.833v-.833H17ZM17.834 11a.839.839 0 0 1-.814.834h-3.716l-.853-1.65c-.295-.668-.96-1.018-1.651-1.018H8.466c-.694 0-1.36.352-1.654 1.026l-.793 1.642H2.314A.837.837 0 0 1 1.5 11V8.334c0-1.19.976-2.167 2.167-2.167h12c1.19 0 2.167.976 2.167 2.167V11Zm-15.53 1.834h-.137v.833c0 .457.376.833.833.833h1.334a.837.837 0 0 0 .833-.833v-.833H2.303Zm5.422-2.237c.108-.253.372-.43.74-.43H10.8c.368 0 .632.177.74.43l.016.033.622 1.203H7.13l.587-1.216a.459.459 0 0 0 .009-.02ZM2.5 9a.5.5 0 0 1 .5-.5h2a.5.5 0 1 1 0 1H3a.5.5 0 0 1-.5-.5Zm11.832-.5a.5.5 0 0 0 0 1h2a.5.5 0 1 0 0-1h-2Z"
        fill={props?.color || '#8D8D8D'}
      />
    </svg>
  )

  export default CarIcon;