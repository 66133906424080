import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data : {
    section : 0,
    showModal:false
  }

}

export const order = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data[action.payload.key] = action.payload.value
    },
    
  },
})

// Action creators are generated for each case reducer function
export const { setData} = order.actions

export default order.reducer

