import { useEffect, useState } from "react";
import { Spin, } from "antd";
import { useSelector } from "react-redux";
import BasketItem from "./BasketItem";




const BasketList = () => {
  const basket = useSelector(e => e.basket)
  const data = basket.basketList

  //const loading = basket.loadingAllPriceList || basket.loadingAllBasketList
  const loading = false;

  // console.log(data)

  return (
    <Spin spinning={loading} tip="Loading">
      {data.map((item, key) => <BasketItem product={item} key={key} />)}
    </Spin>
  );
};

export default BasketList;
