import React, { useEffect, useState } from 'react'
import LoadingPage from '@/components/LoadingPage'
import { useLocation, useNavigate } from 'react-router-dom'
import authControlRequest from '@/core/authentication/authControlRequest'
import { setAccount, setRelationId } from '@/store/session'
import { useDispatch } from 'react-redux'
import jwtDecode from 'jwt-decode'



const AuthContext = ({ children }) => {

    const [isMount, setIsMount] = useState(false);
    const [isAuth, setIsAuth] = useState(false);
    const [loading, setLoading] = useState(false)
    const location = useLocation()
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {

        let token = window.localStorage.getItem('x-access-token')
        let tokenRelation = window.localStorage.getItem('x-access-token-relation')

        if (tokenRelation) {
            window.localStorage.setItem('x-access-token', tokenRelation)
            window.localStorage.removeItem('x-access-token-relation')
            token = tokenRelation
        }


        if (token) {
            //is valid bla bla
            setLoading(true)
            authControlRequest().then(e => {
                const decoded = jwtDecode(token)
                dispatch(setAccount(decoded))
                dispatch(setRelationId(decoded.RelationId))
                setIsAuth(true)
            }).catch(f => {
                setIsAuth(false)
                window.localStorage.removeItem('x-access-token')
                navigate('/login')
            }).finally(() => {
                setLoading(false)
            })
        } else {
            setIsAuth(false)
            navigate('/login')
        }
        setIsMount(true)
    }, [])




    if ((!isMount) || loading) {
        return <LoadingPage />
    }


    if (isAuth == true)
        return children
}

export default AuthContext