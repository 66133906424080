import React from "react";
import { ReactSVG } from "react-svg";
import { useSelector } from "react-redux";


const VehicleMenuItem = ({ text, onClick, icon }) => {
  let color = useSelector((e) => e.settings.menuIconColor);

  return (
    <div className="car-menu-list__column__card__body__item">
      <div className="car-menu-list__column__card__body__item__img">
        <ReactSVG
          beforeInjection={(svg) => {
            svg.classList.add("webshop-menu-icons");
            svg.setAttribute("fill", color);
          }}
          src={`https://material.parts-soft.net/icon.php?iconName=` + icon}
        />
      </div>
      <div onClick={() => onClick(icon,text)} className="car-menu-list__column__card__body__item__text">{text}</div>
    </div>
  );
};

export default VehicleMenuItem;