import api from '@/services/api';
export default (props) => {
    const {series,productGroupId,menuCode,productReference,oem,searchDescription,carCode,catalogBrandId} = props;
    return new Promise((resolve, reject) => {
            let data = {}
            let url = `/catalog/menuContentPartsList`
            
            if(oem){
                url = '/catalog/oem-cross'
                data = {
                    OEM: oem
                }
            }


            else if(productGroupId && series && catalogBrandId && productReference){
                url = '/catalog/searchSubParts'
                data = {
                    reference : productReference,carCode:series,catalogBrandId,partCode:productGroupId
                };
            }

            else if (series && productGroupId) {
                url = '/catalog/searchByProductGroupId'
                data = {
                    CatalogProductGroupId: productGroupId,
                    CatalogVehicleReferenceId: series.toString(),
                };
            } else if (menuCode && series) {
                data = {
                    VehicleReferenceId: series.toString(),
                    MenuCode: menuCode
                };
            } else if (productReference) {
                data = {
                    Reference: productReference,

                };
                if(productGroupId){
                    data.productGroupId = productGroupId
                }
                url = "/catalog/searchArticle"                
            } else if(searchDescription){
                url = '/webshop/description-service/search';
                data = {
                    searchKey : searchDescription
                }
            }

            else if(carCode,productReference,catalogBrandId){
                url = '/webshop/description-service/search';
                data = {
                    searchKey : searchDescription
                }
            }


            api()({
                url,
                data: data,
                method: 'POST'
            }).then((e) => {
                if ((e.data.status == 'false' || e.data.status == false)) reject(false);
                resolve({products : e.data.data,filters : e.data.filters,filterTree :e.data.filterTree});
            }).catch((e) => {
                console.log(e)
                reject(false)
            })
        })


}