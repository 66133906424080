import { Row,Col, Card } from "antd";
import React from "react";
import AccountSidebar from "./AccountSidebar";


const AccountLayout = ({children}) => {
  return (
    <div className="account-page">
      <Row gutter={16}>
        <Col lg={5}>
          <AccountSidebar />
        </Col>
        <Col lg={19}>
          <Card style={{height:'100%',borderRadius:'16px'}} >
          {children}
          </Card>
        </Col>
      </Row>
    </div>
  );
};





export default AccountLayout;
