import React from 'react'


const style={
    fontSize:'30px',
    fontWeight:'500',
    color:'black',
    textDecoration:'underline',
    textDecorationColor:'#dbdbdb',
    marginBottom:'15px'

}

const AccountTitle = ({title}) => {
  return (
    <div><p style={style} >{title}</p></div>
  )
}

export default AccountTitle