const YoutubeIcon = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={19}
      height={14}
      viewBox="0 0 19 14"
      fill="red"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.201.658c-.49.029-.96.059-1.392.089A3.359 3.359 0 0 0 .675 3.793C.583 4.827.5 6.026.5 7c0 .974.083 2.173.175 3.207a3.359 3.359 0 0 0 3.134 3.046c1.705.117 3.998.247 5.762.247 1.764 0 4.058-.13 5.763-.247a3.359 3.359 0 0 0 3.133-3.046 52.2 52.2 0 0 0 .141-1.967c.022-.433.035-.854.035-1.24 0-.974-.083-2.173-.176-3.207A3.359 3.359 0 0 0 15.334.747C13.629.63 11.335.5 9.571.5c-1.317 0-2.928.073-4.37.158Zm1.311.93a65.335 65.335 0 0 1 3.06-.088c1.727 0 3.992.128 5.694.244a2.36 2.36 0 0 1 2.206 2.138c.091 1.026.171 2.19.171 3.118 0 .929-.08 2.092-.171 3.118a2.36 2.36 0 0 1-2.206 2.138c-1.702.116-3.967.244-5.695.244-1.727 0-3.992-.128-5.694-.244a2.36 2.36 0 0 1-2.206-2.138C1.581 9.092 1.5 7.928 1.5 7c0-.929.08-2.092.171-3.118a2.36 2.36 0 0 1 2.206-2.138c.793-.054 1.709-.11 2.635-.156Zm1.131 2.385 4.5 2.598-4.5 2.598V3.973Zm1 1.732 1.5.866-1.5.866V5.705Z"
        fill={props.color || "#8D8D8D"}
      />
    </svg>
  )


  export default YoutubeIcon;