import React from 'react'
import { Card, Col, Row, Typography, Tag } from 'antd'
import { useTranslation } from 'react-i18next'

const { Meta } = Card
const { Title, Text } = Typography

const VehicleSearchVehicleCard = ({ vehicle, onClick }) => {
  const { t } = useTranslation() // useTranslations çağrısı

  return (
    <Card
      onClick={() => onClick(vehicle)}
      hoverable
      cover={
        <img
          alt={vehicle.description}
          src={`https://webshop.parts-soft.eu/tecdoc/${vehicle.linkageTargetId}.jpg`}
          style={{
            objectFit: 'contain',
            height: '150px',
            padding: '10px'
          }}
          onError={(e) => {
            e.target.src = 'https://material.parts-soft.net/image.php?brandId=-1&productreference=-1'
          }}
        />
      }
      style={{
        borderRadius: '8px',
        overflow: 'hidden',
        boxShadow: '0 0px 2px rgba(0, 0, 0, 0.1)',
        minHeight: '300px'
      }}
    >
      <Meta
        title={<div style={{ fontSize: '15px', fontWeight: 'bold' }}>{vehicle.vehicleModelSeriesName}</div>}
        description={
          <>
            <Row>
              <Col style={{ display: 'block', width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <Text strong style={{ fontSize: '13px' }}>
                  {t('vehicle-motorCode')}:
                </Text>
                <Text style={{ fontSize: '13px' }}>{vehicle?.engines?.length ? vehicle.engines[0].code : t('common-unknown')}</Text>
              </Col>
              <Col style={{ display: 'block', width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <Text strong style={{ fontSize: '13px' }}>
                  {t('vehicle-fuelType')}:
                </Text>
                <Text style={{ fontSize: '13px' }}>{vehicle.fuelType || t('common-unknown')}</Text>
              </Col>
              <Col style={{ display: 'block', width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <Text strong style={{ fontSize: '13px' }}>
                  {t('vehicle-horsePower')}:
                </Text>{' '}
                <Text style={{ fontSize: '13px' }}>{vehicle.horsePowerFrom ? `${vehicle.horsePowerFrom} HP` : t('common-unknown')}</Text>
              </Col>
              <Col style={{ display: 'block', width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <Text strong style={{ fontSize: '13px' }}>
                  {t('vehicle-capacity')}:
                </Text>{' '}
                <Text style={{ fontSize: '13px' }}>{vehicle.capacityLiters ? `${vehicle.capacityLiters} L` : t('common-unknown')}</Text>
              </Col>
              <Col style={{ display: 'block', width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <Text strong style={{ fontSize: '13px' }}>
                  {t('vehicle-driveType')}:
                </Text>
                <Text style={{ fontSize: '13px' }}>{vehicle.driveType || t('common-unknown')}</Text>
              </Col>
              <Col style={{ display: 'block', width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <Text strong style={{ fontSize: '13px' }}>
                  {t('vehicle-bodyStyle')}:
                </Text>
                <Text style={{ fontSize: '13px' }}>{vehicle.bodyStyle || t('common-unknown')}</Text>
              </Col>
              <Col style={{ display: 'block', width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <Text strong style={{ fontSize: '13px' }}>
                  {t('vehicle-productionYear')}:
                </Text>{' '}
                <Text style={{ fontSize: '13px' }}>
                  {vehicle.beginYearMonth ? `${vehicle.beginYearMonth} - ${vehicle.endYearMonth}` : t('common-unknown')}
                </Text>
              </Col>
            </Row>
          </>
        }
      />
    </Card>
  )
}

export default VehicleSearchVehicleCard
