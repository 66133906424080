import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Radio, Row, Col } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

const SearchForm = ({
    form,
    searchType,
    setSearchType,
    onFinish,
    loading,
}) => {



  return (
    <Form form={form} onFinish={onFinish} layout="vertical">
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item label="Arama Türü">
            <Radio.Group
              onChange={(e) => setSearchType(e.target.value)}
              value={searchType}
            >
              <Radio value="id">ID'ye göre</Radio>
              <Radio value="motorCode">Motor Koduna göre</Radio>
              <Radio value="vehicleModelSeriesName">Açıklamaya göre</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            name="search"
            rules={[{ required: true, message: 'Arama alanını doldurun!' }]}
          >
            <Input placeholder="Arama kriterinizi girin" />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Button loading={loading} disabled={loading} type="primary" htmlType="submit">
            Ara
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default SearchForm;
