import HistorySection from '@/components/history/HistorySection'
import React from 'react'

const History = () => {
  return (
    <div>
        <HistorySection/>
    </div>
  )
}

export default History