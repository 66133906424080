import React, { useEffect, useState } from 'react'
import BasketItemCollpaseItem from './BasketItemCollapseItem'

const BasketItemCollpaseItems = ({ basketItem }) => {
    console.log(basketItem)
    //const suppliers = Object.keys(items)
    return (
        <div className="basket-item-collapse-items">
            <BasketItemCollpaseItem basketItem={basketItem} />
            {/* {suppliers.map((supplierId, key) => )} */}
        </div>
    )
}

export default BasketItemCollpaseItems
