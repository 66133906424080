import { setLanguage } from '@/store/settings';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux'

const useChangeLanguage = () => {
    const dispatch = useDispatch();
    const { i18n } = useTranslation()

    const change = (lang) => {
        lang = lang?.toLowerCase()
        window.localStorage.setItem('lang', lang);
        dispatch(setLanguage(lang))
        i18n.changeLanguage(lang)
    }

    return change
}

export default useChangeLanguage