import { Form, Input, Button, message } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const UniversalPartsSearch = ({ onClose }) => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const onUniversalPartsSearch = (values) => {
    navigate(`/universal-parts?search=${values.term}&page=1&size=12`)
    message.info('Universal parts search performed')

    form.resetFields()
    onClose()
  }

   const handleSearch = () => {
     form.submit()
   }

  return (
    <div className="custom-input-search">
      <Form layout="vertical" form={form} onFinish={onUniversalPartsSearch} className="space-y-6">
        <Form.Item label={`${t('universal-parts-search')}:`} name="term">
          <Input.Search placeholder={`${t('e.g.')} GDB 1330`} enterButton={<SearchOutlined />} onSearch={handleSearch} size="large" />
        </Form.Item>
      </Form>
    </div>
  )
}

export default UniversalPartsSearch
