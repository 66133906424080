import { Button, Result } from 'antd'
import React from 'react'
import {ShoppingOutlined} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toggle } from '@/store/basket';
const EmptyBasket = () => {

    const {t} = useTranslation();
    const d = useDispatch();

    return (
        <Result
            icon={<ShoppingOutlined />}
            title={t('empty-basket')}
            extra={
                <Button onClick={() => d(toggle(false))} type="primary">
                    {t('next')}
                </Button>
            }
        />
    )
}

export default EmptyBasket