import React, { useEffect, useReducer } from 'react'
import fetchLine from '@/core/vehicle/fetchVehicleLines';
import filtersVehicleLine from '@/core/vehicle/filtersVehicleLine';




const initialValues = {
    lineList: [],
    activeLines: [],
    bodyTypes: [],
    activeBodyTypes: [],
    loading: false,
    data: [],
    originalData: [],
    error: false,
    startYear: 0,
    endYear: 0,
    vehicleLineText : null
}



const reducer = (state, action) => {
    switch (action.type) {

        case "setLineList":
            return { ...state, lineList: action.payload }
        case "setActiveLines":
            return { ...state, activeLines: action.payload }
        case "setBodyTypes":
            return { ...state, bodyTypes: action.payload }
        case "setActiveBodyTypes":
            return { ...state, activeBodyTypes: action.payload }
        case "setLoading":
            return { ...state, loading: action.payload }
        case "setData":
            return { ...state, data: action.payload }
        case "setOriginalData":
            return { ...state, originalData: action.payload }
        case "setError":
            return { ...state, error: action.payload }
        case "setStartYear":
            return { ...state, startYear: action.payload }
        case "setEndYear":
            return { ...state, endYear: action.payload }
        case "setSearchVehicleLineText":
            return {...state,vehicleLineText : action.payload}
        default:
            return state;
    }
};


const useVehicleLineReducer = () => {
    const [state, dispatch] = useReducer(reducer, initialValues);

    const getVehicleLines = (make) => {
        return new Promise((resolve, reject) => {

            dispatch({ type: "setLoading", payload: true })
            dispatch({ type: "setError", payload: false })
            fetchLine(make).then(e => {
                dispatch({ type: "setData", payload: e })
                dispatch({ type: "setOriginalData", payload: e })


                let lines = filtersVehicleLine.createFilterLines(e);
                let bodys = filtersVehicleLine.createBodyTypes(e);

                dispatch({ type: 'setLineList', payload: lines })
                dispatch({ type: 'setBodyTypes', payload: bodys })

                dispatch({ type: "setEndYear", payload: Math.max(...lines) })
                dispatch({ type: "setStartYear", payload: Math.min(...lines) })


                resolve(e)
            }).catch(e => {
                reject(false)
                dispatch({ type: "setError", payload: true })

            }).finally(() => dispatch({ type: "setLoading", payload: false }))
        })
    }

    const changeActiveLines = (year) => {

        dispatch({ type: "setActiveLines", payload: year })

        let max;
        let min;

        if (year.length) {
            max = Math.max(...year)
            min = Math.min(...year)

        } else {
            max = Math.max(...state.lineList)
            min = Math.min(...state.lineList)
        }
        dispatch({ type: 'setEndYear', payload: max })
        dispatch({ type: 'setStartYear', payload: min })
        let filter = filtersVehicleLine.filtering(state.originalData, year, state.activeBodyTypes,state.vehicleLineText);
        dispatch({ type: 'setData', payload: filter })

    }

    const searchLineText = (e) => {
        dispatch({type : 'setSearchVehicleLineText',payload : (e)})
        let filter = filtersVehicleLine.filtering(state.originalData, state.activeLines, state.activeBodyTypes,e);
        dispatch({ type: 'setData', payload: filter })
    }

    const changeActiveBodyTypes = (year) => {
        dispatch({ type: "setActiveBodyTypes", payload: year })
        let filter = filtersVehicleLine.filtering(state.originalData, state.activeLines, year,state.vehicleLineText);
        dispatch({ type: 'setData', payload: filter })

    }


    const changeYearSelector = (startYear, endYear) => {
        let temp = []
        for (let i = startYear; i < endYear; i++) {
            temp.push(i)
        }
        dispatch({ type: "setActiveLines", payload: temp })
    }

    const clearYears = () => {
        let max = Math.max(...state.lineList)
        let min = Math.min(...state.lineList)
        dispatch({ type: 'setEndYear', payload: max })
        dispatch({ type: 'setStartYear', payload: min })
        dispatch({ type: "setActiveLines", payload: [] })
    }

    const resetReducer = () => {
        dispatch({ type: "setLineList", payload: [] })
        dispatch({ type: "setActiveLines", payload: [] })
        dispatch({ type: "setBodyTypes", payload: [] })
        dispatch({ type: "setActiveBodyTypes", payload: [] })
        dispatch({ type: "setLoading", payload: false })
        dispatch({ type: "setData", payload: [] })
        dispatch({ type: "setOriginalData", payload: [] })
        dispatch({ type: "setError", payload: false })
        dispatch({ type: "setStartYear", payload: 0 })
        dispatch({ type: "setEndYear", payload: 0 })
    }


    return { searchLineText,state, dispatch, getVehicleLines, changeActiveLines, changeYearSelector, clearYears, changeActiveBodyTypes,resetReducer }
}


export default useVehicleLineReducer;