import { Alert, Button, Dropdown, message, Modal } from "antd";
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showSearch } from '@/store/settings'
import SearchResult from "./SearchResult";
import { useTranslation } from "react-i18next";
import useSearchReducer from "./useSearchReducer";
import searchVehicle from "@/core/vehicle/searchVehicleFromPlate";
import { useNavigate, useSearchParams } from "react-router-dom";
// import SearchResult from "../../Search/SearchResult";


const SearchIcon = ({ className, color }) => (
  <svg className={className} width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.9136 18.5826L24.9608 24.6299" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M11.3543 20.5984C16.6424 20.5984 20.9291 16.3116 20.9291 11.0236C20.9291 5.73558 16.6424 1.44879 11.3543 1.44879C6.06633 1.44879 1.77954 5.73558 1.77954 11.0236C1.77954 16.3116 6.06633 20.5984 11.3543 20.5984Z"
      stroke={color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Search = () => {
  const settings = useSelector(e => e.settings)
  const show = settings['showSearch']
  const [text, setText] = useState("");
  const d = useDispatch();
  const { t } = useTranslation();
  const reducer = useSearchReducer();
  const navigate = useNavigate();
  const selector = useSelector(e => e)
  const [newModal, setNewModal] = useState(false);

  const [searchParams] = useSearchParams();
  const licensePlate = (searchParams.get('licensePlate'))

  useEffect(() => {
    if (licensePlate) {
      licensePlate && reducer.dispatch({ type: 'setText', payload: licensePlate });

      searchVehicle(licensePlate).then(e => {
        reducer.dispatch({ type: 'setPlateList', payload: e })

        if (e.length == 1) {
          reducer.searchPlate(e[0], licensePlate)
        }
      }).catch(f => { }).finally(() => { })
    }
  }, [licensePlate])

  useEffect(() => {
    if (reducer.state.text == "") {
      d(showSearch(false));
    } else {
      d(showSearch(true));
    }
  }, [reducer.state.text]);

  const handlerEnter = e => {
    if (e.key == 'Enter') {
      if (reducer.state.text.length <= 2) {
        message.info("Please enter minimum 3 character.")
      } else if (reducer.state.isPlate == true) {
        setTimeout(() => {
          if (reducer.state.plateList.length) {
            reducer.searchPlate(reducer.state.plateList[0])
          } else {
            message.info("Vehicle not found !")
          }
        }, 400)

      } else {
        if (reducer.state.productResults.length) {
          reducer.insertSearchProductHistory(reducer.state.productResults[0])
          reducer.searchProduct(reducer.state.productResults[0]?.ProductReference1)
          navigate('/catalog');
        } else {
          reducer.insertSearchProductHistory(reducer.state.text)
          reducer.searchProduct(reducer.state.text)

        }
      }
    }
  }
  return (
    <>
      <Modal title="New Feature !" open={newModal} onCancel={() => setNewModal(false)} onOk={() => {
        setNewModal(false)
        window?.localStorage?.setItem('showNewFeatureText', 'false')
      }}>
        <Alert message={t('new-feature-text')} type="success" showIcon />
      </Modal>

      <Dropdown
      overlay={
        <div style={{
          overflowY:'auto',
          maxHeight:'calc(100vh - 200px)'
        }} className="w-full">
          <SearchResult reducer={reducer} text={text} />
        </div>
      }
      
        open={show}
        onOpenChange={(e) => d(showSearch(e))}
        placement="bottom"
        arrow
        trigger={"click"}
      >
        <div className="header3__search">
          <SearchIcon color={settings['SearchColor']} className={"header3__search__icon"} />
          <input value={reducer.state.text} onKeyUp={(e) => handlerEnter(e)} onChange={(e) => reducer.dispatch({ type: 'setText', payload: e.target.value })} placeholder={t('header-search')} />
        </div>
      </Dropdown>
    </>
  );
};

export default Search;
