import { setLanguage, setLanguageList, setSiteSettings } from '@/store/settings'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import i18n from '@/core/translations/i18n'
import LoadingPage from '@/components/LoadingPage'
import useChangeLanguage from '@/hooks/useChangeLanguage'

const Bootstrap = ({ children }) => {

    const d = useDispatch()

    const [mount, setMount] = useState(false)
    const actChangeLanguage = useChangeLanguage()
    useEffect(() => {


        let settingsDefaultLanguage = "NL"
        let defaultCurrency = 'EUR'

        //console.log(keywords)
        let keywordMap = {}

        try {
            let siteSettings = JSON.parse(document.getElementById("sitesettings").innerHTML)
            let keywords = Object.keys(siteSettings)


            keywords.forEach(keyword => {
                if (keyword && keyword != 'undefined' && keyword != 'null' && keyword != '' && keyword == 'HeaderBgColor') {
                    document.documentElement.style.setProperty('--primaryColor', siteSettings[keyword]);
                }

                // if(siteSettings[keyword] == 'true'){
                //     siteSettings[keyword] = true
                // }

                // if(siteSettings[keyword] == 'false'){
                //     siteSettings[keyword] = false
                // }



                let value = siteSettings[keyword]
                if (keyword == 'menu-icon-color') {
                    keyword = 'menuIconColor'
                    value = siteSettings['menu-icon-color']

                }
                keywordMap[keyword] = value
                d(
                    setSiteSettings({
                        type: keyword,
                        value: value
                    }))
                d(
                    setSiteSettings({
                        type: "fetch",
                        value: true
                    }))
            });




        } catch (e) {
        }







        if (keywordMap['defaultCurrency']) {
            defaultCurrency = keywordMap['defaultCurrency']
        }


        d(
            setSiteSettings({
                type: 'siteCurrency',
                value: defaultCurrency
            }))

        window.localStorage.setItem('siteCurrency', defaultCurrency)




        try {
            const translation = JSON.parse(document.getElementById("translationsdata").innerHTML)
            Object.keys(translation).forEach(key => {
                for (const item of translation[key]) {
                    i18n.addResource(key.toLocaleLowerCase(), 'ns1', item.Keyword, item.Value)
                    i18n.addResource('fff', 'ns1', item.Keyword, item.Value)
                }
            })
        } catch (e) {
            return false;
        }

        let langs = []

        try {
            langs = JSON.parse(document.getElementById("languagelist").innerHTML)
            d(setLanguageList(langs))
        } catch (e) {

        }


        if (keywordMap['defaultLanguage']) {
            settingsDefaultLanguage = keywordMap['defaultLanguage']
        }

        const language = window.localStorage.getItem('lang');
        
        if (language) {
            let find = langs.find(x => x?.Code?.toLocaleLowerCase() == language?.toLocaleLowerCase());
            
            if (find) {
                actChangeLanguage(language);
            } else {
                // settingsDefaultLanguage bulunmazsa langs array'indeki ilk dili al
                let defaultLang = langs.find(x => x?.Code?.toLocaleLowerCase() == settingsDefaultLanguage?.toLocaleLowerCase()) || langs[0];
                actChangeLanguage(defaultLang?.Code);
            }
        } else {
            // settingsDefaultLanguage bulunmazsa langs array'indeki ilk dili al
            let defaultLang = langs.find(x => x?.Code?.toLocaleLowerCase() == settingsDefaultLanguage?.toLocaleLowerCase()) || langs[0];
            actChangeLanguage(defaultLang?.Code);
        }
        



        setMount(true)




    }, [])



    if (!mount) return <LoadingPage />


    return children;
}

export default Bootstrap
