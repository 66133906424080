import {  Card, Input, Result, Row, Skeleton, Col, Typography, List, Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation, initReactI18next } from "react-i18next";
import {  StarTwoTone } from '@ant-design/icons'
import useVehicleLineReducer from './useVehicleLineReducer';
import getCatalogName from '@/helpers/getCatalogName';





const VehicleLineTable = ({ make, onClick, MakePhoto, makeName }) => {

  const vehicleLineReducer = useVehicleLineReducer();

  const tableRef = useRef();

  const [catalogName, setCatalogName] = useState("")


  const { t } = useTranslation()


  useEffect(() => {

    setCatalogName(getCatalogName())

    vehicleLineReducer.resetReducer();
    vehicleLineReducer.getVehicleLines(make).then(e => {
      try {
        tableRef.current.querySelector('.ant-table-row').style.backgroundColor = 'rgb(255, 214, 107)'
      } catch (e) {

      }


    }).catch(f => {
      console.log(f)
      vehicleLineReducer.dispatch({ state: 'setError', paylod: true })
    })




  }, [make])







  return (
    <div>
      {!vehicleLineReducer.state.error ? (
        <>
          {/* <VehicleInfo MakePhoto={MakePhoto} Make={make} className={"mt-10 mb-5"} /> */}

          <div className='flex items-center justify-center'>
            <img src={MakePhoto} style={{ height: '50px' }} />
            <Typography.Title className='mt-3 ml-3' level={2} >{makeName}</Typography.Title>
          </div>

          <Row gutter={16}>
            <Col lg={4}>
              <Select
                loading={vehicleLineReducer.state.loading}
                mode="multiple"
                allowClear
                size='large'
                style={{
                  width: '100%',
                }}
                placeholder={t('body-type')}
                onChange={e => vehicleLineReducer.changeActiveBodyTypes(e)}
                options={
                  vehicleLineReducer.state.bodyTypes.sort().map(item => ({
                    value: item,
                    label: item
                  }))
                }
              />
            </Col>
            <Col lg={16}>
              <Input.Search loading={vehicleLineReducer.state.loading} autoFocus={true}
                value={vehicleLineReducer.state.vehicleLineText} onChange={(e) => vehicleLineReducer.searchLineText(e.target.value)} placeholder={t('search')} size="large"></Input.Search>
            </Col>
            <Col lg={4}>
              <Select
                loading={vehicleLineReducer.state.loading}
                mode="multiple"
                allowClear
                size='large'
                style={{
                  width: '100%',
                }}
                placeholder={t('year')}
                onChange={e => vehicleLineReducer.changeActiveLines(e)}
                options={
                  vehicleLineReducer.state.lineList.sort().map(item => ({
                    value: item,
                    label: item
                  }))
                }
              />
            </Col>




          </Row>





          <Card className='mt-4' loading={vehicleLineReducer.state.loading}>

            <List
              className="demo-loadmore-list"
              itemLayout="horizontal"
              dataSource={vehicleLineReducer.state.data}
              renderItem={(item) => (
                <List.Item
                  actions={[<a href="#" onClick={(e) => e.preventDefault()} key="list-loadmore-more"><StarTwoTone twoToneColor="#eb2f96" /></a>]}
                >
                  <Skeleton avatar title={false} loading={false} active>
                    <List.Item.Meta
                      onClick={() => onClick(item.VehicleMakeId, item.Line, item.Slug)}
                      avatar={catalogName == 'TECDOC' ? null : <img onError={(e) => e.target.src = 'https://material.parts-soft.net/image.php?brandId=0&productreference=0'} width={100} height={100} src={item.Image ? item.Image : "https://material.parts-soft.net/line.php?lineId=" + item.VehicleMakeId} />
                      }
                      title={<a href="/" onClick={(e) => e.preventDefault()}>{item.Line}</a>}
                      description={`${item.Line} - ${item.BodyType} - ${item.LineStart} - ${item.LineEnd || ""}`}
                    />
                  </Skeleton>
                </List.Item>
              )}
            />
          </Card>


        </>
      ) : (
        <Card style={{ marginTop: "70px" }}>
          <Result status="warning" title={("some-problem")} />
        </Card>
      )}
    </div>
  )
}

export default VehicleLineTable