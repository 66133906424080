import React, { useState } from 'react'
import api from '@/services/api'
const useFetchRelation = () => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])


    const fetchRelation = async () => {
        setLoading(true)
        const response = await api(true).get('/webshop/auth/find-all-relations')
        setData(response.data.data)
        setLoading(false)
    }
    return { loading, data, fetchRelation }

}

export default useFetchRelation