import api from '../../services/api';
import axios from 'axios';
export default (data) => {
    return new Promise((resolve, reject) => {
        const config = {
            url: '/webshop/basket',
            data : data,
            method : "POST",
         
        };
        api()(config).then(result => {
            if (result.data.status == 'true') {
                resolve({ status: true, data: result?.data?.data, message: result.data.message,basket : result.data.basket })
            } else {
                reject({ status: false, message: result.data.message });

            }
        }).catch((e) => {
            reject(e);
        })
    })
}