import { Col, Divider, List, Row, Typography } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux';



const TecdocVehicleMenuItem = ({ item, onClick, icon }) => {
    let color = useSelector((e) => e.settings.menuIconColor);

    const onMouseHover = (e) => {
        e.target.style.color = color;
    }
    const onMouseOut = (e) => {
        e.target.style.color = null;
    }

    return (<>
        <Typography.Title level={5} className='tecdoc-vehicle-menu-list-item-title'>{item?.categoryName}</Typography.Title>
        <hr />
        {item?.childs?.map((item2, index2) => <ul key={index2}>
            <li
                onClick={()=>onClick(item2.id)}
                onMouseOver={onMouseHover}
                onMouseOut={onMouseOut}
                className='tecdoc-vehicle-menu-list-item'>{item2?.categoryName}</li>
        </ul>)}
    </>)


}

const TecdocVehicleMenu = ({ list,onClick }) => {
    let color = useSelector((e) => e.settings.menuIconColor);

    return (
        <Row gutter={16}>
            {list.map((item, index) => <Col key={index} style={{
                marginTop: '24px'
            }} lg={6}>
                <TecdocVehicleMenuItem onClick={onClick} item={item} />
            </Col>)}

        </Row>
    )
}

export default TecdocVehicleMenu