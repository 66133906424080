const GarageIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={17} height={16} viewBox="0 0 17 16" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.616.627a2.977 2.977 0 0 0-3.656 0l-5.016 3.9A2.977 2.977 0 0 0 .794 6.88v6.77A2.351 2.351 0 0 0 3.147 16H14.43a2.351 2.351 0 0 0 2.351-2.351v-6.77a2.976 2.976 0 0 0-1.15-2.351l-5.015-3.9Zm-3.079.742a2.037 2.037 0 0 1 2.502 0l5.015 3.901a2.037 2.037 0 0 1 .787 1.608v6.771a1.41 1.41 0 0 1-1.41 1.41h-.136V9.625c0-1.174-1.026-2.125-2.291-2.125H5.586c-1.265 0-2.291.951-2.291 2.125v5.435h-.149a1.41 1.41 0 0 1-1.41-1.411v-6.77c0-.63.29-1.223.785-1.61l5.016-3.9Zm5.842 13.69H4.21v-.857h9.168v.857ZM5.586 8.35c-.713 0-1.3.505-1.368 1.15h9.154c-.068-.645-.655-1.15-1.368-1.15H5.586Zm-1.375 3.032v-.941h9.168v.94H4.21v.001Zm0 .94v.94h9.168v-.94H4.21Z"
        fill={props.color || "#707070"}
      />
    </svg>
  )

  export default GarageIcon