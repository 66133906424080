import React, { useTransition } from 'react'
import Title from "@/layouts/AccountLayout/AccountTitle";
import { useTranslation } from 'react-i18next';
import { Alert, Checkbox, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setHideNetPrice } from '@/store/settings';

const Settings = () => {
    
    const {t} = useTranslation();
    const store = useSelector(e => e?.settings);
    const dispatch = useDispatch();

    const onChangeShowGross = (e) => {
        dispatch(setHideNetPrice(e))
    }


    return (
    <div>
        <Title title={t('settings')}/>
       
        <div className='flex'>
        <Checkbox checked={store?.hideNetPrice} onChange={e => onChangeShowGross(e.target.checked)} disabled={store['ShowGrossPrice'] == 'false' || store['ShowGrossPrice'] == false} >{t('only-show-gross-price')}</Checkbox>
        </div>

    </div>
  )
}

export default Settings


