import { Card, Divider, List, Spin, Typography, Avatar, Tag } from "antd";
import ResultVehicle from "./ResultVehicle";
import ResultProduct from "./ResultProduct";
import ResultHistory from "./ResultHistory";
import ResultVinnNumber from "./ResultVinnNumber";
import { useTranslation } from "react-i18next";

const schaseRegex = new RegExp("^[A-HJ-NPR-Za-hj-npr-zd]{8}[dX][A-HJ-NPR-Za-hj-npr-zd]{2}d{6}$", "i");

const SearchResult = ({ reducer }) => {


  const { t } = useTranslation()
  return (
    <Card className="search-card" style={{ padding: "25px" }}>
      {(reducer.state.isVIN || reducer.state.isKBA) && <ResultVinnNumber text={reducer.state.isVIN ? "VINN" : "KBA"} reducer={reducer} />}

      {reducer.state.isPlate ?
        <ResultVehicle reducer={reducer} plate={reducer.state.text} description={reducer.state.description} /> : null}
      {reducer.state.text.length > 2 ? (
        <>
          <div className="">
            <ResultProduct reducer={reducer} />
          </div>
          <Divider />
        </>
      ) : null}
      <ResultHistory />
    </Card>
  );
};

export default SearchResult;

