import { Card, Row, Segmented, Tabs, Typography, Col, Menu, Divider } from 'antd'
import React from 'react'
import { UnorderedListOutlined, AppstoreOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';



function getItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label,
        type,
    };
}

const TecdocLayout = ({ children }) => {


    const { t } = useTranslation()
    const [selectedKeys, setSelectedKeys] = React.useState(['categories']);
    const items = [
        getItem(t('categories'), "categories", <UnorderedListOutlined />),
        getItem(t('brands'), "brands", <AppstoreOutlined />),
    ];

    const navigate = useNavigate()


    /**
     * url -> /tecdoc/menu-create --> selectedKeys = ['categories']
     */

    React.useEffect(() => {
        //use react router get pathname
        const pathname = window.location.pathname
        if (pathname.includes('/tecdoc/menu-create')) {
            setSelectedKeys(['categories'])
        }

    }, [])




    return (
        <div>








            <div style={{
                padding: '0 50px',
            }}>

                <Typography.Title style={{
                    textAlign: 'center',
                }} level={2}>TecDoc</Typography.Title>

                <Divider />


                


                <div style={{
                    marginTop:'25px'
                }}>
                    <Row gutter={16}>


                        <Col lg={24}>
                            {children}
                        </Col>



                    </Row>
                </div>

            </div>
        </div>
    )
}

export default TecdocLayout