import React, { useState } from 'react'
import api from '@/services/api'
const useCreateCategory = () => {
    const [loading, setLoading] = useState(false)

    const create = async (categoryName, parentId = 0,genericArticleId) => {
        setLoading(true)
        const { data } = await api(true).post('/catalog/admin-category-list/', {
            category: categoryName,
            parentId,
            genericArticleId
        })
        setLoading(false)
        return data
    }



    return { loading, create }
}

export default useCreateCategory