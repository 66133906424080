import { message, Modal } from 'antd';
import React from 'react';
import { Button, Checkbox, Form, Input } from 'antd';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useCreateCategory from '@/hooks/adminTecdoc/useCreateCategory';
const onFinish = (values) => {
    console.log('Success:', values);
};
const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
};

const CreateMainCategory = ({ show, onCancel, onCreate, parentId }) => {

    const siteSettings = useSelector(e => e?.settings);
    const languages = siteSettings?.languageList;
    const { t } = useTranslation();
    const createCategory = useCreateCategory()

    const [form] = Form.useForm()

    const onFinishForm = () => {
        form.validateFields().then(e => {

            const { categoryName } = form.getFieldsValue()

            createCategory.create(categoryName, parentId).then(e => {
                onCreate && onCreate(e)
            }).catch(f => {
                message.error(f?.response?.data?.message || "Error")
            })

        }).catch(f => {
            alert("er")
        })
    }


    return (
        <Modal loading={createCategory.loading} title="Create" onOk={() => {
            onFinishForm()
        }} okButtonProps={
            {
                loading: createCategory.loading
            }
        } okText={t('save')} cancelButtonProps={
            {
                style: {
                    display: 'none'
                }
            }
        } open={show} onCancel={onCancel}>
            <Form
                form={form}
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >

                <Form.Item

                    label={`Category Name`}
                    name="categoryName"
                    rules={[
                        {
                            required: true,
                        }
                    ]}
                >
                    <Input placeholder={t('category-name')} />
                </Form.Item>



            </Form>
        </Modal>
    )
}

export default CreateMainCategory