import React, { useState } from 'react'
import api from '@/services/api'
const useUpdateCategory = () => {
    const [loading, setLoading] = useState(false)
    const [loadingFetch, setLoadingFetch] = useState(false)
    const update = async (id, categoryName, parentId = 0) => {
        setLoading(true)
        try {

            const { data } = await api(true).put('/catalog/admin-category-list/' + id, {
                category: categoryName,
                parentId
            })

            return data
        } catch (e) {
            throw e
        } finally {
            setLoading(false)
        }
    }

    const fetch = async (id,parentId=null) => {
        setLoadingFetch(true)
        let q = ''
        if(parentId){
            q = '?parentId=' + parentId
        }
        const { data } = await api(true).get('/catalog/admin-category-list/' + id  + q)
        setLoadingFetch(false)
        return data.data
    }



    return { loading, update, fetch, loadingFetch }
}

export default useUpdateCategory