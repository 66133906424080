import api from '@/services/api'
const fetchLine = (make) => new Promise((resolve, reject) => {
    api().get(`/catalog/vehicleLineList?MakeCode=${encodeURIComponent(make)}`)
        .then((e) => {
            if (e.data.status == 'true') {
                let s = e.data.data.sort((a,b) => a?.Line?.localeCompare(b?.Line))
                resolve(s)
            }
            else reject(false);
        })
        .catch((e) => {
            console.log(e)
            reject(false)
        })

})


export default fetchLine;