import React from 'react'

const RotateImage = ({ links }) => {

    if (!links.length) return null
    return (
        <div>
           <iframe width="100%" height="500" src={links[0]} ></iframe>

        </div>
    )
}

export default RotateImage