const moment = require('moment-timezone')


const getFastestsStocks = (stocks, deliveries) => {

  

}

const getFastestAvability = (avability, deliveries) => {
  try {
    if (avability?.fastestRowId) {
      let find = avability.data.find(x => x.rowId == avability.fastestRowId);
      if (find) {
        for (let i = 0; i < find.stocks.length; i++) {
          if (deliveries.includes(find.stocks[i].WarehouseId)) {
            find.isExist = true;
            break;
          }
        }
        return find
      }
    }
    throw Error;
  } catch (e) {
    return null;
  }
}


function findStock(arr, quantity) {
  try {
    let totalStock = 0;

    for (let i = 0; i < arr.length; i++) {
      totalStock += parseInt(arr[i].StockQuantity, 10);

      if (totalStock >= quantity) {
        return arr[i];
      }
    }

    return null;
  } catch (e) {
    return null;
  }
}





function getOtherSeller(avability) {
  try {
    if (avability?.fastestRowId) {
      let list = avability.data.filter(x => x.rowId != avability.fastestRowId)
      if (list) {
        return list
      }
    }
    throw Error;
  } catch (e) {
    return [];
  }
}


// const OtherSellerItem = ({ item }) => {
//   const [qty, setQty] = React.useState(1)
//   const stockInformation = findStock(item?.stocks, qty)
//   return (<div>
//     <Divider />
//     <p>sellerName:{item?.supplierId}</p>
//     <p>net:{item?.price?.NetPrice}</p>
//     <p>gross:{item?.price?.GrossPrice}</p>
//     <p>discount:{item?.price?.Discount}</p>
//     <InputNumber value={qty} onChange={setQty} />
//     {stockInformation ? <div>
//       <p>stok:{stockInformation.StockQuantity}</p>
//       <p>lokasyon:{stockInformation.WarehouseName}</p>
//       <p>afterDays : {stockInformation?.logisticTime?.isafter_days}</p>
//       <p>logisticTime : {stockInformation?.logisticTime?.timestampToRegion}</p>
//     </div> : <p>stok yok</p>}
//     <button>Satın Al</button>
//   </div>)
// }


const calculateOtherWarehouseStocks = (stocks) => {
  if (!Array.isArray(stocks)) return 0;
  if (!stocks?.length > 1) return 0;

  let totalStock = 0;

  for (let i = 1; i < stocks.length; i++) {
    totalStock += Number(stocks[i].StockQuantity);
  }

  return totalStock;
}



const timestampToFullDate = (timestamp, timezone) => {

  try {
    const convert = moment.unix(timestamp).tz(timezone)

    let fullDate = convert.format('YYYY-MM-DD HH:mm:ss');
    let hour = convert.format('HH:mm');

    if (fullDate == 'Invalid date') {
      fullDate = null;
    }


    if (hour == 'Invalid date') {
      hour = null;
    }

    return {
      fullDate, hour
    }
  } catch (e) {
    return {
      fullDate: null,
      hour: null
    }
  }
}



const getExistStockByStocks = (stocks, deliveries) => {
  return stocks.filter(x => deliveries.includes(x.WarehouseId)).reduce((total, item) => {
    return total + parseInt(item.StockQuantity, 10);
  }, 0);
}


const calculateTotalStocksFromStocks = (stocks) => {
  return stocks.reduce((total, item) => {
    return total + parseInt(item.StockQuantity, 10);
  }, 0);
}


function formatDateTime(dateString, timezone) {
  // Verilen tarih ve saat dilimine göre Moment nesnesi oluştur
  try {
    const givenDate = moment.tz(dateString, timezone);
    const now = moment().tz(timezone);

    // Verilen tarih ve şu anki tarih arasındaki gün farkını hesapla
    let diffDays = givenDate.diff(now, 'days');

    if (isNaN(diffDays)) diffDays = null

    let formated = givenDate.format('HH:mm')
    if (formated == 'Invalid date') {
      formated = null;
    }
    return [diffDays, formated]
  } catch (e) {
    return [null, null];
  }
}





module.exports = {
  formatDateTime, calculateOtherWarehouseStocks, getOtherSeller, findStock, getFastestAvability, timestampToFullDate, getExistStockByStocks, calculateTotalStocksFromStocks, getFastestsStocks
}
