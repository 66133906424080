// import fetchLanguages from '@/core/bootstrap/languages/fetchLanguages'
import useChangeLanguage from '@/hooks/useChangeLanguage'
import { Select } from 'antd'
import { t } from 'i18next'

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const SelectLanguage = ({ type = "header3" }) => {
  const settings = useSelector(e => e.settings);
  const [langList, setLanguageList] = useState([]);
  const actChangeLanguage = useChangeLanguage();

  const handleChange = (e) => {
    actChangeLanguage(e)
  }

  useEffect(() => {
    setLanguageList(settings['languageList']);
  }, []);

  const activeLang = settings?.Language;

  const selectStyle = {
    border: `1px solid ${settings['HeaderIconColor']}`,
    backgroundColor: settings['HeaderBgColor'],
  };

  const dropdownStyle = {
    border: 'none', // Dropdown içindeki optionların border'ını kaldırmak için
  };

  return (
    <>
      {type === 'header3' ? (
        <div className="header3__top__container__right-side__item__input">
          <select
            value={activeLang}
            style={selectStyle}
            onChange={(e) => handleChange(e.target.value)}
          >
            {langList.map((item, key) => (
              <option key={key} value={item.Code.toLowerCase()}>
                {item.Code}
              </option>
            ))}
          </select>
        </div>
      ) : type === "mobil" ? (
        <>
          <Select
            value={activeLang}
            onChange={(e) => handleChange(e)}
            style={selectStyle}
            dropdownStyle={dropdownStyle}
          >
            {langList.map((item, key) => (
              <Select.Option key={key} value={item.Code.toLowerCase()}>
                {item.Code}
              </Select.Option>
            ))}
          </Select>
        </>
      ) : null}
    </>
  );
};

export default SelectLanguage;
