import getTiresHistoryListService from '../core/tires/getTiresHistoryList';
import deleteTiresHistory from '../core/tires/deleteTiresHistory';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
const initialState = {
    historyList: [],
    loadingHistory: false
}

const getTiresHistoryList = createAsyncThunk(
    'tiresHistory/getTiresHistoryList',
    async () => {
        try{
            return await getTiresHistoryListService()
        }catch(e){
            console.log(e)
        }
    }
)


const deleteHistoryList = createAsyncThunk('tiresHistory/deleteHistoryList',

    async () => {

        return await deleteTiresHistory()
    })




export const tiresHistory = createSlice({
    name: 'tiresHistory',
    initialState,
    reducers: {
    
        setLoading: (state, action) => {
          state.loading = action.payload
        },
        setHistoryList: (state, action) => {
          state.historyList = action.payload
        },
     
    
      },
    extraReducers: (builder) => {
        builder.addCase(getTiresHistoryList.fulfilled, (state, action) => {
            if (typeof action.payload == 'object') {
                state.historyList = action.payload;
            } else state.historyList = [];

            state.loadingHistory = false;
        })

        builder.addCase(getTiresHistoryList.pending, (state, action) => {
            state.loadingHistory = true;
        })

        builder.addCase(getTiresHistoryList.rejected, (state, action) => {
            state.loadingHistory = [];
        })


        builder.addCase(deleteHistoryList.fulfilled, (state, action) => {
            state.historyList = []
            state.loadingHistory = false;
        })


        builder.addCase(deleteHistoryList.pending, (state, action) => {
            state.loadingHistory = true;
        })







    }
})


export const { setLoading,setHistoryList } = tiresHistory.actions

// Action creators are generated for each case reducer function
//export const { test } = history.actions
export default tiresHistory.reducer
export { getTiresHistoryList }
export { deleteTiresHistory }