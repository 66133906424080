import api from '../../services/api';

const insertHistory = (ProductReference,Description,Photo) => {   
    return new Promise((resolve, reject) => {
        api()({
            url : '/webshop/history/product',
            data:{
                ProductReference,Description,Photo
            },
            method : 'POST'
        }).then((e) => {
            resolve(e.data.data)
        }).catch((err) => {
            resolve(false)
        })
    }
    )
}



const getHistory = (offset) => {
    return new Promise((resolve, reject) => {
        api().get('/webshop/history/product?offset='+offset).then((e) => {
            resolve({data : e.data.data, totalCount : e.data.totalCount,offset:e.data.offset})
        }).catch((err) => {
            resolve([])
        })
    }
    )
}

const deleteHistory = (Id) => {
    return new Promise((resolve, reject) => {
        api().delete('/webshop/history/product/' + Id).then((e) => {
            resolve(true)
        }).catch((err) => {
            reject(false)
        })
    }
    )
}



export {getHistory,insertHistory,deleteHistory}