import axios from 'axios'
const instance = (useAdminToken = false,signal=null) => {

  const currency = window.localStorage.getItem('siteCurrency') || 'EUR';

  let authToken = localStorage.getItem('x-access-token');
  let otherToken = localStorage.getItem('x-access-token-relation');

  if (useAdminToken && otherToken) {
    authToken = otherToken;
  }


  let tokens = {
  }
  if (authToken != "" && authToken != "null" && authToken) {
    tokens['x-access-token'] = authToken;
  }


  return axios.create({
    baseURL: process.env.REACT_APP_BACKEND,
    headers: {
      ...tokens,
      currency : currency,
      "ps-token": window?.REST_API_TOKEN
    },
    signal: signal
  });
}


export default instance;