import { Form, Input, Radio, Button, message } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

const TypeSearch = ({ onClose }) => {
  const [form] = Form.useForm()
  const navigate = useNavigate() // Yönlendirme için kullanılıyor
  const [searchType, setSearchType] = useState('motorCode') // Varsayılan arama türü
  const { t } = useTranslation()

  const onTypeSearch = (values) => {
    const { search } = values
    if (!search) {
      message.warning('Lütfen bir arama terimi girin')
      return
    }

    let queryParam = ''

    // Arama türüne göre query parametrelerini ayarlıyoruz
    if (searchType === 'motorCode') {
      queryParam = `motorCode=${search}`
    } else if (searchType === 'id') {
      queryParam = `id=${search}`
    } else if (searchType === 'vehicleModelSeriesName') {
      queryParam = `vehicleModelSeriesName=${search}`
    }

    // Yönlendirme yapılıyor
    navigate(`/vehicle-search?type=${searchType}&search=${search}`)
    message.success('Arama gerçekleştirildi')

    form.resetFields()
    onClose()
  }

  const handleSearch = () => {
    form.submit()
  }

  return (
    <div className="custom-input-search">
      <Form layout="vertical" form={form} onFinish={onTypeSearch} className="space-y-6">
        <Form.Item label={`${t('search-type')}:`} style={{ marginBottom: '10px', marginTop: 0 }}>
          <Radio.Group defaultValue="motorCode" onChange={(e) => setSearchType(e.target.value)}>
            <Radio value="motorCode">{t('motor-code')}</Radio>
            <Radio value="id">{t('tecdoc-type-no')}</Radio>
            <Radio value="vehicleModelSeriesName">{t('name')}</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item name="search" rules={[{ required: true, message: t('please-enter-search-term') }]} style={{ marginBottom: 0, marginTop: 0 }}>
          <Input.Search placeholder={`${t('e.g.')} AHU`} enterButton={<SearchOutlined />} size="large" onSearch={handleSearch} />
        </Form.Item>
      </Form>
    </div>
  )
}

export default TypeSearch
