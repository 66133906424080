import React, { useEffect, useState } from "react";
import { Button, Drawer, message, Modal, Popconfirm, Spin } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { setBasketList, setReload, setTotalPrices, toggle } from "@/store/basket";
import BasketList from "./BasketList";
import { useTranslation } from "react-i18next";
import TrashIcon from "@/extra/TrashIcon";
import deleteBasket from "@/core/basket/deleteBasket";
import EmptyBasket from "./EmptyBasket";
import BasketListFooter from "./BasketListFooter";
import BuyModal from "./BuyModal";
import { setData } from "@/store/order";
import { useWindowDimensions } from "@/hooks/useWindowDimensions";



const getBasketResize = (width) => {

  if (width >= 1660) {
    return '35%'
  }

  if (width <= 1660 && width >= 1500)
    return '35%';

  if (width <= 1500 && width >= 1300) {
    return '40%'
  }
  if (width <= 1300 && width >= 1100) {
    return '50%'
  }
  if (width <= 1100 && width >= 900) {
    return '60%'
  }
  if (width <= 900) {
    return '100%'
  }


  return '25%'
}

const Basket = () => {
  const dispatch = useDispatch();
  const basket = useSelector(e => e.basket)
  const basketVisible = basket.visible;
  const [loadingBasket, setLoadingBasket] = useState(false);
  const order = useSelector(e => e.order)
  const { width } = useWindowDimensions()
  const close = () => {
    dispatch(toggle(false));
  };

  const { t } = useTranslation()

  useEffect(() => {
    if (basketVisible == false) {
      let p = (document.querySelector('.basket-drawer'))
      if (p) {
        p.children[0].style.display = 'none'
      }
    } else if (basketVisible == true) {
      let p = (document.querySelector('.basket-drawer'))
      if (p) {
        p.children[0].style.display = 'block'
      }
    }
  }, [basketVisible])


  const destroyAll = () => {
    setLoadingBasket(true)
    deleteBasket().then(e => {
      dispatch(setBasketList([]))
    })
      .catch(f => {
        message.error(f)
      }).finally(() => {
        setLoadingBasket(false)
      })
  }


  return (
    <Spin spinning={loadingBasket}>
      <Drawer
        // width={getBasketResize(width)}
        className="basket-drawer"
        title={t('shopping-card')}
        placement={"right"}
        onClose={() => close()}
        open={basketVisible}
        // open={true}
        closeIcon={false}
        key={1}
        id="shopping-cart"
        extra={
          <div >
            <Popconfirm placement="bottomLeft" title={t('do-you-want-to-delete-all-products')} onConfirm={() => destroyAll()} okText="Yes" cancelText="No">
              <TrashIcon />
            </Popconfirm>
          </div>
        }
      >
        <Modal open={order.data.showModal} onCancel={() => dispatch(setData({ key: 'showModal', value: false }))} footer={null} >
          <BuyModal />
        </Modal>
        {basket.basketList.length ?
          <div style={{
            height: '100%', overflow: 'hidden', padding: '10px', display: 'flex', flexDirection: 'column',
            justifyContent: 'space-between'
          }}>
            <div style={{ height: '76%', overflowY: 'scroll', overflowX: 'hidden', marginBottom: '10px' }} >
              <BasketList />
            </div>
            <div style={{ overflow: 'hidden' }}>
              <BasketListFooter />
            </div>
          </div> : <EmptyBasket />

        }
      </Drawer>
    </Spin>
  );
};

export default Basket;
