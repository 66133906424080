import { deleteProductHistoryList, getProductHistoryList } from '@/store/productHistory';
import { Avatar, Divider, List, Skeleton, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import TrashIcon from '@/extra/TrashIcon';
import useAddTab from '@/hooks/assistant/useAddTab';

const formatDate = (date) => {
  try {
    return moment(date).format('DD/MM/YYYY HH:mm:ss');
  } catch (e) {
    return date
  }
}

const ProductHistoryList = () => {
  const productHistoryStore = useSelector(e => e.productHistory);
  const loading = productHistoryStore.loadingHistory
  const data = productHistoryStore.historyList || []

  const d = useDispatch();

  const addTab = useAddTab()

  const onClickHandler = (Reference) => {
    addTab({
      type: 'CarParts',
      productReference: Reference.toUpperCase(),
      title: Reference
    }, 'redirect')
  }

  const session = useSelector(e => e?.session);

  useEffect(() => {
    d(getProductHistoryList())
  }, [session?.relationId]);

  return (
    <Skeleton active={true} loading={loading}>
      <div id="scrollableDiv" style={{ height: "100%", overflow: 'auto', padding: '0 10px', width: '100%' }}>
        <List dataLength={data.length}>
          <List
            dataSource={data}
            renderItem={(item) => (
              <Spin spinning={item.loading ? true : false}>
                <List.Item key={item.Id} className='history-list-item'>
                  {console.log(item.Image)}
                  <List.Item.Meta
                    avatar={<img style={{ maxWidth: '25px', minWidth: '25px' }} src={item.Image ? item.Image : 'https://material.parts-soft.net/image.php?brandId=1&productreference=notfound'} />}
                    // title={<a onClick={() => onClickHandler(item.SearchTerm)}  href="javascript:void(0)"  >{item?.SearchTerm}</a>}
                    description={<a onClick={() => onClickHandler(item.SearchTerm)} href="javascript:void(0)"  >
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <span>{item?.SearchTerm}</span>
                        <span>{item?.Description}</span>
                      </div>
                    </a>}
                  />
                  {/* <div style={{ color: '#767B83', fontSize: '13px' }}>{formatDate(item.createdAt)}</div> */}
                  <div className='ml-4'><TrashIcon onClick={() => d(deleteProductHistoryList({ Id: item.Id }))} /></div>
                </List.Item>
              </Spin>
            )}
          />
        </List>
      </div>
    </Skeleton>
  );
};
export default ProductHistoryList;
