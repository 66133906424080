import React from 'react';
import { Card, Modal, Skeleton } from 'antd';

const UniversalProductSkeletonCard = () => {
    return (
        <Card
            className='universal-product-card-skeleton'
            style={{ width: '100%', height: '100%', padding: '10px', position: 'relative' }}
            bodyStyle={{ padding: '0px' }}
            cover={
                <Skeleton.Image style={{ width: '100% !important', height: '150px' }} />
            }
        >
            {/* Placeholder for the Tecdoc icon */}
            <div
                style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    width: '50px',
                    height: '50px',
                    backgroundColor: 'rgba(0, 0, 0, 0.05)',
                    borderRadius: '4px',
                }}
            ></div>


         


            <Skeleton active title={{ width: '60%' }} paragraph={false} />
            <Skeleton.Input style={{ width: '80%', marginTop: '8px' }} active />

            <Skeleton
                active
                paragraph={{
                    rows: 4,
                    width: ['100%', '100%', '100%', '80%'],
                }}
            />

            {/* Placeholder for the IconArea */}
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>
                <Skeleton.Button style={{ width: '30%' }} active />
                <Skeleton.Button style={{ width: '30%' }} active />
                <Skeleton.Button style={{ width: '30%' }} active />
            </div>
        </Card>
    );
};

export default UniversalProductSkeletonCard;