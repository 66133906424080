import transactions from '@/core/transactions/transactions';
import { message, Spin } from 'antd';
import React, { useEffect, useState } from 'react'
import { Document, Page } from 'react-pdf';
// import { pdfjs } from 'react-pdf';
import { Buffer } from 'buffer';

// @ts-ignore

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.1.266/pdf.worker.js`;

const ShowDocument = ({ documentNumber, type }) => {

    const [localId, setLocalId] = useState(parseInt(Math.random() * 1000000));
    const [loading, setLoading] = useState(false);
    const [url, setUrl] = useState("");
    useEffect(() => {
        window.Buffer = Buffer;

        setLoading(true)
        transactions.showDocument(documentNumber, type).then(result => {
            setLoading(false)
            result = result.data
            const buffer = Buffer.from(result.data, 'base64');
            let blob = new Blob([buffer], { type: 'application/pdf' });
            var fileURL = URL.createObjectURL(blob);
            setUrl(fileURL)
            document.getElementById("document-iframe" + localId).src = fileURL

        }).catch(f => {
            console.log(f)
            message.error(f?.response?.data?.message || "Failed !")
        }).finally(() => setLoading(false))
    }, [documentNumber])

    if (!documentNumber)
        return null;


    return (
        <div style={{ height: '100%' }}>

            <iframe width={'100%'} height={(loading ? '0px' : '100%')} type="application/pdf" id={"document-iframe" + localId} src="" >
            </iframe>
        </div>

    )
}

export default ShowDocument