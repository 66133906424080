import { Form, Input, Button, message, Modal } from 'antd'
import { useState } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

import { ResultVinnNumberModal } from '@/layouts/Dashboard/Search/ResultVinnNumber'

const VinSearch = ({ onClose }) => {
  const [form] = Form.useForm()
  const [vinn, setVinn] = useState('')
  const [showModal, setShowModal] = useState(false)

  const { t } = useTranslation()

  const onVinSearch = (values) => {
//    console.log('VIN Search:', values)
  //  message.success('VIN search performed')
    setShowModal(true)

    // form.resetFields() // Formu sıfırla
    // //setVinn('') // VIN inputunu temizle
    // onClose() // Drawer'ı kapat
  }

  const handleSearch = () => {
    form.submit()
  }

  return (
    <div className="custom-input-search">
      <Form layout="vertical" form={form} onFinish={onVinSearch} className="space-y-6">
        <Modal width={'100%'} title={'VINN Number'} footer={null} zIndex={9999999} open={showModal} onCancel={() => setShowModal(false)}>
          <ResultVinnNumberModal cb={() => setShowModal(false)} vinn={vinn} />
        </Modal>
        <Form.Item label={`${t('vin-search')}:`} name="vinn">
          <Input.Search
            placeholder={`${t('e.g.')} JDAL251S001022249`}
            enterButton={<SearchOutlined />}
            value={vinn}
            onChange={(e) => setVinn(e.target.value)}
            size="large"
            onSearch={handleSearch}
          />
        </Form.Item>
      </Form>
    </div>
  )
}

export default VinSearch
