import api from '../../services/api';
export default (id) => {
    return new Promise((resolve, reject) => {
        
        let url = '/webshop/basket/';
        if(id)
            url = url + id
        
        const config = {
            url: url,            
            method : "DELETE"
        };
        api()(config).then(result => {
            if (result.data.status == 'true') {
                resolve({ status: true, data: result.data.data, message: result.data.message })
            } else {
                reject({ status: false, message: result.data.message });

            }
        }).catch((e) => {
            reject({ status: false, message: e?.response?.data?.message || "Some problems. Please try again later" });
        })
    })
}