const BoxIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m8.496.783 2.827 1.632L8.258 4.19l-3.05-1.785L8.017.783a.48.48 0 0 1 .48 0ZM4.826 6.31V3.014l3.071 1.798V8.36L5.065 6.725a.48.48 0 0 1-.24-.415Zm6.622.415L8.616 8.36V4.813l3.071-1.778V6.31c0 .171-.091.33-.24.415ZM8.855.16a1.198 1.198 0 0 0-1.197 0L4.706 1.865c-.37.214-.599.609-.599 1.037V6.31c0 .168.035.33.1.48a1.197 1.197 0 0 0-.542.158L.713 8.652A1.2 1.2 0 0 0 .114 9.69v3.408c0 .428.229.823.6 1.037l2.95 1.704a1.2 1.2 0 0 0 1.199 0l2.951-1.704c.09-.051.171-.114.243-.185.072.071.153.134.243.185l2.951 1.704c.37.214.827.214 1.198 0l2.952-1.704c.37-.214.598-.61.598-1.037V9.69a1.2 1.2 0 0 0-.598-1.038l-2.952-1.704a1.198 1.198 0 0 0-.179-.084c.088-.168.136-.358.136-.554V2.902c0-.428-.228-.823-.599-1.037L8.855.16ZM7.331 9.203 4.503 7.571a.48.48 0 0 0-.479 0L1.215 9.192l3.05 1.786L7.33 9.203Zm-6.498.598v3.297c0 .171.091.33.24.415l2.831 1.635V11.6L.833 9.8Zm3.79 5.347 2.832-1.635a.479.479 0 0 0 .24-.415V9.823L4.622 11.6v3.547Zm10.294-5.945L12.09 7.571a.48.48 0 0 0-.48 0L8.803 9.192l3.05 1.786 3.065-1.775Zm-6.498.598v3.297c0 .171.092.33.24.415l2.832 1.635V11.6L8.419 9.8Zm3.79 5.347 2.832-1.635a.479.479 0 0 0 .24-.415V9.823L12.209 11.6v3.547Z"
        fill={props.color || "#0DA487"}
      />
    </svg>
  )

  
  export default BoxIcon