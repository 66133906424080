import useAddTab from '@/hooks/assistant/useAddTab';
// import VehiclePartsArea from '@/components/vehicle/vehicleParts/VehiclePartsArea';
// import TiresSection from '@/components/tires/TiresSection'
// import { setSiteSettings } from '@/store/settings';
import { Button, Card, Input, Spin, Tabs } from 'antd';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import AssistantSection from '@/components/assistant/AssistantSection';
import tabs, { setActiveKey, setLastKey, setTabs } from '@/store/tabs';
import HistorySection from '@/components/history/HistorySection';
import TiresSection from '@/components/assistant/tires/TiresSection';
import VehiclePartsArea from '@/components/assistant/VehiclePartsArea/VehiclePartsArea';
import useTabSaveLocalStorage from '@/hooks/assistant/useTabSaveLocalStorage';
import LicensePlateOrder from '@/components/licensePlateOrder/LicensePlateOrder';
import { useTranslation } from 'react-i18next';
import { CloseOutlined } from '@ant-design/icons'
const { TabPane } = Tabs;

const Create = (type, props) => {
  switch (type) {
    case "assistant":
      return <div>{React.createElement("div", [], <AssistantSection {...props} />)}</div>;
      break;
    case "CarParts":
      return <div>{React.createElement("div", [], <VehiclePartsArea {...props} />)}</div>;
    case "LicensePlateOrder":
      return <div>{React.createElement("div", [], <LicensePlateOrder {...props} />)}</div>;
    case "UniversalParts":
      return <div>{React.createElement("div", [], <LicensePlateOrder {...props} />)}</div>;

    case "Tires":
      return <div>{React.createElement("div", [], <TiresSection {...props} />)}</div>;
    case "History":
      return <div>{React.createElement("div", [], <HistorySection {...props} />)}</div>;

    default:
      return <div>{React.createElement("div", [], <AssistantSection {...props} />)}</div>;
  }
};

const Catalog = ({ description, title, keywords, noneRoute }) => {
  const [counter, setCounter] = useState(0);
  const tabsStore = useSelector(e => e.tabs);
  const tabList = tabsStore.tabs
  const d = useDispatch();
  const addTab = useAddTab();
  const siteStore = useSelector(e => e.site);
  const localStorageTabs = useTabSaveLocalStorage()

  useEffect(() => {
    let siteSettings = JSON.parse(document.getElementById("sitesettings").innerHTML)
    document.querySelector('.ant-tabs-nav-list').style.backgroundColor = `${siteSettings['HeaderBgColor']}27`;
  }, [])

  const onEdit = (targetKey, action) => {
    if (action === "add") {
      addTab();

    } else {
      let filtering = tabList.filter((item) => item.key != targetKey);
      d(setTabs(filtering));
      d(setActiveKey(filtering.length ? filtering[filtering.length - 1].key : 0))
    }
  };

  const onChange = (e) => {
    return d(setActiveKey(e))

    let tabs = (tabList.map(x => {
      let newData = JSON.parse(JSON.stringify(x));
      if (Number(x.key) == Number(e)) {
        newData.render = newData.render ? newData.render + 1 : 1
        newData.onClickRender = false;
      }
      return newData;
    })
    )

    d(setTabs(tabs))
    d(setActiveKey(e))
  }

  const settings = useSelector(e => e.settings);
  const { t } = useTranslation();

  const onClickCloseAllHandler = () => {
    d(setTabs([]))
    d(setActiveKey(0))
  }

  const tabListItems = tabList.map((tab, key) => {
    return ({
      key: String(tab.key),
      label: tab.title,
      children: Create(tab.type, { searchDescription: tab.searchDescription, oem: tab.oem, tabKey: tab.key, render: tab.render, productGroupId: tab.productGroupId, tiresCode: tab.tiresCode, Id: tab.id, plate: tab.plate, productReference: tab.productReference, description: tab.description, series: tab.vehicleSeriesId }),
      style: { width: '100%', height: 'calc(100vh - 207px)', backgroundColor: '#fff', padding: '10px', borderRadius: '16px', boxShadow: '0px 0px 15px 0px rgba(149, 157, 165, 0.12)', marginTop: '-16px', overflowY: 'auto', overflowX: "hidden" }
    })
  })





  return (
    <div>
      {<div style={{
        visibility: tabList?.length > 1 ? 'visible' : 'hidden'
      }} className='flex justify-end mb-3 mr-5'>
        <div className="inline-flex h-[32px] items-center gap-[10px] px-[10px] py-[5px] relative rounded-[6px_6px_6px_6px] cursor-pointer" onClick={onClickCloseAllHandler} style={{ backgroundColor: `${settings['HeaderBgColor']}27` }}>
          <CloseOutlined style={{ color: `${settings['HeaderBgColor']}` }} />
          <div className="relative w-fit mt-[-1.00px] [font-family:'Inter-Regular',Helvetica] font-normal text-[15px] tracking-[0] leading-[22px] whitespace-nowrap" style={{ color: settings['HeaderBgColor'] }}>
            {t('close-all')}
          </div>
        </div>
      </div>}

      <Spin spinning={siteStore.loadingCatalogPage} tip="Loading Basket">
        <Tabs
          items={tabListItems}
          type="editable-card" onChange={onChange} activeKey={String(tabsStore.activeKey)} onEdit={onEdit}>
        </Tabs>
      </Spin>
    </div>
  )
}

export default Catalog

