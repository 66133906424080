import { getSortKey } from '@/core/avability/avability';
import searchTires from '@/core/tires/searchTires';
import fetchPriceAndRoute from '@/core/price/fetchPriceAndRoute';
import { getCounter } from '@/helpers/filterHelper';
import React, { useReducer } from 'react'
import fetchPriceAndRoutev2 from '@/core/price/fetchPriceAndRoutev2';

const useTiresSectionReducer = () => {


    const initialValues = {
        category: null,
        searchText: "",
        loading: false,
        products: [],
        error: false,
        totalCount: 0,
        perPage: 20,
        currentPage: 1,
        originalProducts: [],
        filtersData: [],
        filterTree: {},
        deliveries:[]


    }

    const reducer = (state, action) => {
        switch (action.type) {
            case "setCategory":
                return { ...state, category: action.payload }
            case "setSearchText":
                return { ...state, searchText: action.payload }
            case "setLoading":
                return { ...state, loading: action.payload }
            case "setProducts":
                return { ...state, products: action.payload }
            case "setError":
                return { ...state, error: action.payload }
            case "setTotalCount":
                return { ...state, totalCount: action.payload }
            case "setCurrentPage":
                return { ...state, currentPage: action.payload }
            case "setPerPage":
                return { ...state, perPage: action.payload }
            case "setOriginalProducts":
                return { ...state, originalProducts: action.payload }
            case "setFiltersData":
                return { ...state, filtersData: action.payload }
            case "setFilterTree":
                return { ...state, filterTree: action.payload }
            case "setDeliveries":
                return {...state,deliveries:action.payload}

            default:
                return state
        }
    };
    const [state, dispatch] = useReducer(reducer, initialValues)

    const searchProduct = (data) => {
        let str = data.replaceAll('/', '').replaceAll('R', '').replaceAll(' ', '');
        dispatch({ type: 'setLoading', payload: true })
        searchTires(str).then(e => {
            let priceListData = [];
            let productsList = e.data.data
            let x = getCounter(e.data.data, e.data.filters, e.data.filterTree)

            dispatch({ type: 'setFiltersData', payload: x })
            dispatch({ type: 'setOriginalProducts', payload: e.data.data })
            dispatch({ type: 'setTotalCount', payload: e.data.data.length })
            dispatch({ type: 'setFiltersData', payload: x })
            dispatch({ type: 'setFilterTree', payload: e.data.filterTree })


            for (let i = 0; i < e.data.data.length; i++) {
                priceListData.push({ key: e.data.data[i]._id, rows: [e.data.data[i]._id] })
                e.data.data[i].loading = true;
            }

            


            dispatch({ type: 'setProducts', payload: e.data.data })


            fetchPriceAndRoutev2(JSON.stringify(priceListData)).then((e) => {
               
                let i = 0;
                //console.log({priceListData});
               // console.log(e.data.findIndex(x => x.key == '9878607'));
                for(const tire of productsList){
                   let tireId = tire._id;
                   let findPriceIndex = e.data.findIndex(x => x.key == tireId)

                    if(findPriceIndex != -1){
                        let price = e.data[findPriceIndex]
                        productsList[i].sortKey = price?.index
                        productsList[i].avability = {
                            data : price.data,
                            fastestRowId : price.fastestRowId
                        }
                        productsList[i].loadingAvability = false
                        
                    }else{
                       productsList[i].sortKey = Infinity
                       productsList[i].avability = null;
                       productsList[i].loadingAvability = false

                    }
                    
                   i++;
                    // console.log(tire._id);
                }
             
                let sortable = productsList.sort((a, b) => a.sortKey - b.sortKey);
                dispatch({ type: 'setProducts', payload: sortable })
                dispatch({ type: 'setDeliveries', payload: e.deliveries })


            }).catch(f => {
                console.log(f)
            })








        }).catch(f => {
            dispatch({ type: 'setError', payload: true })
        }).finally(() => dispatch({ type: 'setLoading', payload: false })
        )
    }




    return ({ state, dispatch, searchProduct })

}

export default useTiresSectionReducer