import api from '@/services/api'
import React, { useState } from 'react'

const useChangeSort = () => {
    const [loading, setLoading] = useState(false)

    const changeSort = async (idList) => {
        setLoading(true)
        try {
            const { data } = await api(true).put('/catalog/admin-category-list/sort/change-sort', {
                data : JSON.stringify(idList)
            })
            return data
        } catch (e) {
            throw e
        } finally {
            setLoading(false)
        }
    }

    return { loading, changeSort }
}

export default useChangeSort