import api from '@/services/api'
export default (productReference) => new Promise((resolve,reject) => {
    api()({
        method : 'POST',
        url : '/webshop/search-product-service',
        data : {
            searchTerm : productReference
        }
    }).then(e => {
        resolve(e.data)
    }).catch(f => {
        console.log(f)
        reject(f)
    })
})