import { Menu, List, Checkbox, Divider, Card, Button, Input, Form, Typography, Row, Col, Tag, Slider, message, Collapse, Switch } from "antd";
import { useTranslation } from "react-i18next";
import { getCounter, filterAction, search } from "@/helpers/filterHelper";
import { useEffect, useState } from "react";

const { Panel } = Collapse;

const ChangeSegmentIcon = () => (
  <svg version={1.0} xmlns="http://www.w3.org/2000/svg" width="32.000000" height="32.000000" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
      <path
        d="M3126 4591 c-16 -11 -35 -30 -42 -43 -18 -32 -17 -99 2 -128 9 -14
256 -264 547 -557 l531 -532 -1209 -4 c-1184 -3 -1212 -4 -1305 -24 -584 -128
-1005 -556 -1116 -1133 -24 -130 -24 -370 0 -500 111 -576 533 -1005 1116
-1133 93 -20 118 -21 1473 -24 1271 -3 1380 -2 1413 13 37 17 74 75 74 115 0
33 -29 86 -58 106 -26 17 -97 18 -1422 23 l-1395 5 -95 27 c-227 64 -394 161
-548 317 -157 159 -272 384 -313 611 -19 105 -16 305 5 413 92 475 466 839
944 922 71 13 271 15 1261 15 l1176 0 -536 -537 c-296 -296 -542 -548 -548
-560 -6 -12 -11 -38 -11 -58 0 -108 117 -162 206 -96 47 35 1284 1275 1308
1311 21 31 21 99 0 130 -24 36 -1261 1276 -1308 1311 -47 35 -106 39 -150 10z"
      />
    </g>
  </svg>
);

const SearchIcon = () => (
  <svg width={14} height={14} viewBox="0 0 30 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.1111 19L27.7778 25" stroke="#E8E8E8" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12.7777 21C18.6074 21 23.3333 16.7467 23.3333 11.5C23.3333 6.25329 18.6074 2 12.7777 2C6.94805 2 2.22217 6.25329 2.22217 11.5C2.22217 16.7467 6.94805 21 12.7777 21Z" stroke="#E8E8E8" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const FilterGroup = ({ title, items, onChange, filterListIndex }) => {
  return (<div>
    {/* <p className="text-[16px]" >{getName(title)}</p> */}
    {items?.map((item, index) => (<FilterItem filterListIndex={filterListIndex} filterItemIndex={index} onChange={onChange} item={item} key={index} />))}
  </div>)
}


const FilterItem = ({ item, onChange, filterItemIndex, filterListIndex }) => {
  return (<div className={`flex p-[2px] ${filterItemIndex % 2 === 0 ? 'bg-[#f6f7f9]' : 'bg-white'} rounded-[4px]`}>
    <Checkbox onChange={(e) => onChange(filterItemIndex, filterListIndex, e.target.checked)} checked={item.checked} disabled={item.count == 0 ? true : false} />
    <div className={`flex w-full justify-between ml-[10px] text-[#565d6c]`}>
      <span>
        {item.Name}
      </span>
      <span>{item.count}</span>
    </div>
  </div>)
}

const Filter = ({ reducer, filterType }) => {
  const { t } = useTranslation()

  const filtersData = reducer.state.filtersData;
  const filterTree = reducer.state.filterTree;
  const originalProducts = reducer.state.originalProducts;
  const onChange = (filterItemIndex, filterListIndex, value) => {
    filtersData[filterListIndex].Items[filterItemIndex].checked = value
    reducer.dispatch({ type: 'setFiltersData', payload: filtersData })
    let x = filterAction(originalProducts, filtersData, filterTree)
    reducer.dispatch({ type: 'setProducts', payload: x })
    let b = getCounter(originalProducts, filtersData, filterTree)
    reducer.dispatch({ type: 'setFiltersData', payload: b })
  }

  const getName = (titleName) => {
    if (titleName === 'Product Group') {
      return t('product-groups');
    } else if (titleName === 'Brands') {
      return t('brands');
    } else {
      return titleName;
    }
  };

  return (
    <div>
      <Collapse style={{ borderRadius: '6px' }} defaultActiveKey={['0', '1']}>
        {filtersData.map((item, key) => (
          <Panel header={getName(item.Name)} key={key} style={{ borderRadius: '6px', color: '#565d6c', backgroundColor: '#f6f7f9' }}>
            <FilterGroup filterType={filterType} onChange={onChange} filterListIndex={key} reducer={reducer} title={item.Name} items={item.Items} />
          </Panel>
        ))}
      </Collapse>

      {/* {filtersData.map((item, key) => {
        return (<div>
          <div >
            <FilterGroup filterType={filterType} onChange={onChange} key={key} filterListIndex={key} reducer={reducer} title={item.Name} items={item.Items} />
          </div>
          <hr className="mt-3 mb-3" />
        </div>)
      })} */}
    </div>
  );
};

export default Filter;
