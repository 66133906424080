import React, { useEffect, useState } from 'react'
import api from '@/services/api'
const useFetchCategories = (parentId = 0) => {
    const [loading, setLoading] = useState(false)
    const [categories, setCategories] = useState([])

    useEffect(() => {
        setLoading(true)
        let url = '/catalog/admin-category-list'
        if (parentId) {
            url = `/catalog/admin-category-list?parentId=${parentId}`
        }
        api().get(url).then(res => {
            setCategories(res.data.data)
        }).finally(() => {
            setLoading(false)
        })
    }, [])

    return {
        loading,
        categories,
        setCategories,
    }
}

export default useFetchCategories