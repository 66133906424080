import api from '@/services/api';


export default async () => {

    return new Promise((resolve, reject) => {
        api().get('/catalog/vehicleMakeList').then(response => {
            resolve(response.data.data);
        }).catch(error => {
            reject(error);
        }
        )
    })

}