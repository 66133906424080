import { setData } from '@/store/order'
import { Button, Input, Modal } from 'antd'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BuyModal from './BuyModal'
import useCalculateTotalBasket from '@/hooks/useCalculateTotalBasket'
import useCurrencyWithCode from '@/hooks/useCurrencyWithCode'
import { useTranslation } from 'react-i18next'
const BasketListFooter = () => {

  const total = useCalculateTotalBasket()
  const d = useDispatch()
  const order = useSelector(e => e.order)
  const settings = useSelector(e => e.settings)
  const currencyWithCode = useCurrencyWithCode()
  const { t } = useTranslation()
  const hideNetPrice = useSelector((e => e.settings['hideNetPrice'])) ? true : false
  const formatedPrice = useCurrencyWithCode()
  const _showGrossPrice = useSelector(e => e.settings.ShowGrossPrice);
  const showGrossPrice = _showGrossPrice == 'true' || _showGrossPrice == true ? true : false;
  return (
    <div id="basket-list-footer" >
      <div className='basket-list-footer-text-group w-full flex flex-col'>
        {showGrossPrice ? <div className='basket-list-footer-text w-full flex flex-row justify-between'>
          <span>{t('gross-price')}:</span>
          <span>{currencyWithCode(total.GrossPrice)}</span>
        </div> : null}

        {!hideNetPrice && <div className='basket-list-footer-text w-full flex flex-row justify-between'>
          <span>{t('total')}:</span>
          <span>{currencyWithCode(total.NetPrice)}</span>
        </div>}
      </div>
      <div className="account-page flex flex-col gap-[5px] w-full">
        <Input value={order.data.note} onChange={(e) => d(setData({ key: 'note', value: e.target.value }))} placeholder='Note' className='rounded-[6px]' />
        <Input value={order.data.reference} onChange={(e) => d(setData({ key: 'reference', value: e.target.value }))} placeholder='Reference' className='' />
        <div className="flex items-center justify-center gap-[10px] p-[10px] relative rounded-[6px] cursor-pointer w-full" style={{ backgroundColor: settings['HeaderBgColor'], color: '#fff' }} onClick={() => d(setData({ key: 'showModal', value: true }))} >
          <div className="relative w-fit text-[18px] text-center font-medium leading-[normal]">
            {t('order')}
          </div>
          <div className="relative w-fit opacity-20 text-[20px] text-center whitespace-nowrap">
            |
          </div>

          <div className="relative w-fit text-[18px] text-center font-medium whitespace-nowrap">
            {
              hideNetPrice ? formatedPrice(total.GrossPrice) : formatedPrice(total.NetPrice)
            }
          </div>
        </div>

        {/* <Button onClick={() => d(setData({ key: 'showModal', value: true }))} type='primary' style={{ borderRadius: '6px', padding: '20px 0' }}>Order</Button> */}
      </div>
    </div>
  )
}

export default BasketListFooter
