import api from '@/services/api'
export default {
    fetchInvoices : async(params) => {
        if(params.currPage){
            params.page = parseInt(params.currPage)
            delete params.currPage
        }

        if(params.unpaid && params.unpaid == 1){
            params.unpaid = 1
        }else{
            delete params.unpaid
        }

        if(params.paid && params.paid == 1){
            params.paid = 1
        }else{
            delete params.paid
        }

        if(params.limit) params.limit = parseInt(params.limit)
        else params.limit = 10


        let query = new URLSearchParams(params).toString()
        return api()({
            url : '/webshop/user/invoices?'+query,
            method : 'GET'
        })
    },

    fetchSlips : (params) => {
        if(params.limit){
            params.limit = parseInt(params.limit)
        }else{
            params.limit = 10
        }


        
        let query = new URLSearchParams(params).toString()
        return api()({
            url : '/webshop/user/slips?'+query,
            method : 'GET'
        })
    },

    searchArticleHistory : (Term)=>{
        
        return api()({
            url : '/webshop/user/search-article',
            method : 'POST',
            data : {
                Term
            }
        })
    },

    showDocument : (DocumentNumber,type)=>{  
        return api()({
            url : '/webshop/user/show-document/'+DocumentNumber+'/'+type,
            method : 'GET'
        })
    }




}