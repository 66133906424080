import React from 'react'
import { useSelector } from 'react-redux'
import currencyCodes from '@/core/currency/currencyCodes'
const useCurrencyWithCode = () => {
  const currency = useSelector((e) => e.settings.siteCurrency)

  const get = (price) => {
    if (!price) return "-"

    price = Number(price)

    if(isNaN(price)) return "-"
    price = parseFloat(price).toFixed(2)
    if (currency == 'EUR') {
      return currencyCodes[currency] + ' ' + price
    } else if (currency == 'TRY') {
      return price + ' ' + currencyCodes[currency]
    }

    return get
  }

  return get
}

export default useCurrencyWithCode