import { configureStore } from '@reduxjs/toolkit'
import session from './session'
import settings from './settings'
import tabs from './tabs'
import basket from './basket'
import history from './history'
import site from './site'
import payment from './payment'
import tiresHistory from './tiresHistory'
import order from './order';
import productHistory from './productHistory';

export const store = configureStore({
  reducer: {
    session,
    settings,
    tabs,
    basket,
    history,
    site,
    payment,
    tiresHistory,
    order,
    productHistory
  },
})
