import BoxIcon from '@/components/icons/BoxIcon'
import QuestionIcon from '@/components/icons/QuestionIcon'
import SellerIcon from '@/components/icons/SellerIcon'
import TruckIcon from '@/components/icons/TruckIcon'
import useLogisticTimeToText from '@/hooks/useLogisticTimeToText'
import React from 'react'

const LogisticTimeIcon = ({logisticTime,logisticTimeToRegion}) => {


    const logisticTimeText = useLogisticTimeToText(logisticTime)
    const logisticTimeToRegionText = useLogisticTimeToText(logisticTimeToRegion)

    
  return (
   <React.Fragment>
    <div className="flex flex-col w-[284px] items-start justify-center p-[5px] relative flex-[0_0_auto] bg-[#d2fbf3] rounded-[6px] overflow-hidden">
            
            <div className="flex items-start justify-between relative self-stretch w-full flex-[0_0_auto]">
                <div className="flex flex-col w-[24px] h-[24px] items-center justify-center relative">
                    <BoxIcon className="relative w-[24px] h-[24px]" />
                </div>
                <div className="flex w-[80px] h-[24px] items-center justify-around relative relative w-fit [font-family:'Poppins-Regular',Helvetica] font-normal text-[#26ae95] text-[11px] tracking-[0] leading-[normal]">
                    {logisticTimeText || "........."}
                </div>
                <div className="flex flex-col w-[24px] h-[24px] items-center justify-center relative">
                    <SellerIcon className="relative w-[24px] h-[24px]" color="#26AE95" />
                </div>
                <div className="flex w-[80px] h-[24px] items-center justify-around relative relative w-fit [font-family:'Poppins-Regular',Helvetica] font-normal text-[#0da487] text-[11px] tracking-[0] leading-[normal]">
                    {logisticTimeToRegionText || "........."}
                </div>
                <div className="flex flex-col w-[24px] h-[24px] items-center justify-center relative">
                    <TruckIcon className="relative w-[24px] h-[24px]" />
                </div>
            </div>
        </div>
   </React.Fragment>
  )
}

export default LogisticTimeIcon