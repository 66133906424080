import { AutoComplete, Button, Input } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setTabs } from '@/store/tabs';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import getProductGroups from '@/core/vehicle/fetchVehicleProductGroups';

const VehicleSearchProduct = ({ TabId }) => {
  let tab = null;
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  const ref = useRef()
  const searchTab = useSelector(e => e.tabs.tabs.find(item => item.id == TabId))
  const tabs = useSelector(e => e.tabs.tabs);
  const d = useDispatch();
  const [searchText, setSearchText] = useState(0);
  const { t } = useTranslation()
  const [a,setA] = useState("")
  useEffect(() => {
    if (TabId) {
      let find = (tabs.filter(item => item.id == TabId)[0])
      tab = find;
      // setSearchText(find.description)
    }

  }, [tabs, TabId])
  const setDescription = (e) => {
    d(setTabs(tabs.map(item => {
      if (item.id == TabId) {
        return ({ ...item, description: e, section: 'vehicleParts', vehicleMenuId: 0 })
      } else {
        return item
      }
    })))
  }

  const [options, setOptions] = useState([]);
  const handleSearch = (value) => {
    let temp = []
    for (const item of list) {
      if (item.label && item.label != '') {
        if (item.label.toLowerCase().indexOf(value.toLowerCase()) > -1) {
          temp.push(item)
        }
      }
    }

    setOptions(temp)
    //setOptions(value ? searchResult(value) : []);
  };
  const onSelect = (value) => {


    d(setTabs(tabs.map(item => {
      if (item.id == TabId) {
        return ({ ...item, productGroupId: value, section: 'vehicleParts', vehicleMenuId: 0 })
      } else {
        return item
      }
    })))
    let name = list.find(x => x.value == value)?.label
    setA(name)
  };

  useEffect(() => {
    setLoading(true)
    getProductGroups().then(x => {
      setList(x)
    }).catch(f => setList([])).finally(()=>setLoading(false))
  }, [])



  return (

    <div>
      <AutoComplete
        dropdownMatchSelectWidth={252}
        style={{
          width: '100%'
        }}
        options={options}
        onSelect={onSelect}
        onSearch={handleSearch}
        autoFocus={true}
        value={a}
        onChange={(e)=>{
          setA(e)
        }}


      >
        <Input.Search  value={a} ref={ref} loading={loading} size="large" placeholder={t('search')}   />
      </AutoComplete>
    </div>


  )
}

export default VehicleSearchProduct
