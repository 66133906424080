const filterByYear = (activeLines, vehicleLine) => {

    let around = []; // vehicle line list

    let start = Number(vehicleLine.LineStart);
    let end = Number(vehicleLine.LineEnd);

    if (vehicleLine.LineEnd == '' || (!vehicleLine.LineEnd)) {
        end = new Date().getFullYear()
    }

    for (let i = start; i <= end; i++) {
        around.push(i);
    }

    for (let i = 0; i < activeLines.length; i++) {
        if (around.find(x => x == activeLines[i])) {
            return true;
        }
    }
    return false;

}



const searchVehicleLineText = (line, text) => {

    let r = new RegExp(text, 'i');
    let x = line.Line;
    return x.search(r) == -1 ? false : true

}


const filterByBodyType = (activeBodyTypes, line) => {

    if (activeBodyTypes.find(x => x == line.BodyType)) {
        return true;
    }
    return false;
}

const filtering = (originalLines, activeLines, activeBodyTypes, lineText) => {
    let byYear = false;
    let byBodyType = false;
    let byLineText = false;
    let list = []

    for (let i = 0; i < originalLines.length; i++) {
        if (activeLines.length == 0) {
            byYear = true;
        } else {
            byYear = filterByYear(activeLines, originalLines[i]);
        }
        if (activeBodyTypes.length == 0) {
            byBodyType = true;
        } else {
            byBodyType = filterByBodyType(activeBodyTypes, originalLines[i])
        }



        if (lineText == '' || (!lineText)) {
            byLineText = true;
        } else {
            byLineText = searchVehicleLineText(originalLines[i], lineText)
        }





        if (byYear && byBodyType && byLineText) {
            list.push(originalLines[i])
        }
    }
    return list;
}


const createBodyTypes = (lines) => {
    let _body = [];
    for (const item of lines) {
        let body = item.BodyType;
        if (!_body.find(x => x == body)) {
            _body.push(item.BodyType);
        }

    }
    return _body.sort((a, b) => a - b);
}


const createFilterLines = (lines) => {
    let _lines = [];
    for (const item of lines) {
        let start = Number(item.LineStart);
        let end = Number(item.LineEnd);
        if (item.LineEnd == '' || (!item.LineEnd)) {
            end = new Date().getFullYear()
        }
        let around = [];
        for (let i = start; i <= end; i++) {
            around.push(i);
            if (!_lines.find(x => x == i)) {
                _lines.push(i);
            }
        }
    }
    return _lines.sort((a, b) => b - a);
}




export default { createFilterLines, createBodyTypes, filtering, filterByYear, filterByBodyType }