const NotificationIcon = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={14}
      height={16}
      viewBox="0 0 14 16"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.966 5.937C2.1 2.297 4.847.5 7.195.5v1c-1.857 0-4.096 1.412-4.228 4.433.085.682.033 2.229-.829 3.521l-.015.023-.017.02c-.274.33-.56.842-.601 1.315a.976.976 0 0 0 .121.598c.101.17.291.358.65.522a16.43 16.43 0 0 0 2.734.601c.333.045.873.072 1.347.089.233.008.444.013.597.016l.181.003h.064l.07 1h-.147l-.186-.004a42.951 42.951 0 0 1-1.31-.048c.053.105.118.212.2.313.251.314.663.598 1.37.598v1c-1.028 0-1.721-.435-2.151-.973a2.885 2.885 0 0 1-.527-1.055 17.012 17.012 0 0 1-2.581-.6l-.023-.007-.022-.01c-.52-.23-.895-.547-1.125-.935a1.974 1.974 0 0 1-.257-1.194c.063-.74.47-1.43.81-1.847.69-1.051.723-2.355.652-2.853l-.007-.044.002-.045Z"
        fill={props.color || "#8D8D8D"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.423 5.937C12.288 2.297 9.542.5 7.194.5v1c1.857 0 4.096 1.412 4.228 4.433-.085.682-.033 2.229.829 3.521l.015.023.017.02c.275.33.56.842.601 1.315a.976.976 0 0 1-.12.598c-.102.17-.292.358-.65.522a16.4 16.4 0 0 1-2.734.601c-.334.045-.874.072-1.348.089-.233.008-.444.013-.597.016l-.18.003H7.19l.01 1h.069l.185-.004a42.951 42.951 0 0 0 1.31-.048 1.79 1.79 0 0 1-.2.313c-.25.314-.663.598-1.37.598v1c1.028 0 1.721-.435 2.152-.973.279-.35.442-.734.526-1.055a17.012 17.012 0 0 0 2.581-.6l.023-.007.022-.01c.52-.23.895-.547 1.125-.935a1.98 1.98 0 0 0 .258-1.194c-.064-.74-.47-1.43-.812-1.847-.69-1.051-.722-2.355-.65-2.853l.006-.044-.002-.045Z"
        fill={props.color || "#8D8D8D"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.195 3.474a.5.5 0 0 1 .5.5v2.973a.5.5 0 1 1-1 0V3.974a.5.5 0 0 1 .5-.5Z"
        fill={props.color || "#8D8D8D"}
      />
    </svg>
  )

  export default NotificationIcon