import React from 'react'
import SortableCategoryItem from './CategoryItem'
import { SortableContainer } from 'react-sortable-hoc';

const CategoryList = ({ items,onClickDelete,onUpdate,onClickItem,hideEdit,showSubCategory }) => {
    return (
        <ul>
            {items.map((value, index) => (
                <SortableCategoryItem showSubCategory={showSubCategory} hideEdit={true} onClick={onClickItem} onUpdate={onUpdate} onClickDelete={onClickDelete} key={value?.id} index={index} value={value} />
            ))}
        </ul>
    )
}

export default SortableContainer(CategoryList)