import React, { useState, useEffect } from "react";
import { Form, Input, Row, Col, Button, Select, message, Spin } from "antd";
import fetchAccount from "@/core/account/fetchAccount";
import updateAccount from "@/core/account/updateAccount";
import helpers from "@/helpers/helpers";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
const AccountUpdate = () => {
    const [form, setForm] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const onFinish = () => { };
    const {t} = useTranslation();
    const get = () => {
        setLoading(true)
        fetchAccount().then((res) => {
            let data = res.data.data;
            form.setFieldsValue(data)
        }).catch(e => {
            message.error("Profile not fetching !")
        }).finally(() => setLoading(false))

    };

    const update = () => {
        setLoading(true)
        let data = helpers.cleanObj(form.getFieldsValue())
        updateAccount(data).then((res) => {
            message.success("Successfully !")
        }).catch(e => {
            message.error("Profile not update !")
        }).finally(() => setLoading(false))
    }

    const session = useSelector(e => e?.session);


    useEffect(() => {
        get()
    }, [session?.relationId]);

    return (
        <Spin tip="Loading" spinning={loading}>
            <Form layout="vertical" onFinish={update} form={form}>
                <Row gutter={16}>
                    <Col lg={12}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your " + t('name'),
                                },
                            ]}
                            label={t('name')}
                            className="w-full"
                            name={"Name"}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col lg={12}>
                        <Form.Item
                            className="w-full"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your FirstName!",
                                },
                            ]}
                            label={t("FirstName")}
                            name={"FirstName"}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col lg={12}>
                        <Form.Item
                            name="Email"
                            className="w-full"
                            label={t("Email")}
                            rules={[{ required: true, message: "Please input your Email!" }]}
                        >
                            <Input type="email" placeholder="Email" />
                        </Form.Item>
                    </Col>

                    <Col lg={12}>
                        <Form.Item
                            name="Phone"
                            className="w-full"
                            label={t("phone")}
                            rules={[{ required: true, message: "Please input your Phone!" }]}
                        >
                            <Input placeholder="Phone" />
                        </Form.Item>
                    </Col>

                    <Form.Item
                        name="Password"
                        className="w-full pl-3"
                        label={t("password")}
                    >
                        <Input type="password" placeholder="Password" />
                    </Form.Item>

                    <Form.Item
                        name="Language"
                        className="w-full ml-3"
                        label={t("language")}
                        rules={[
                            { required: true, message: "Please select your Language!" },
                        ]}
                    >
                        <Select>
                            <Select.Option value="NL">NL</Select.Option>
                            <Select.Option value="TR">TR</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="Address"
                        className="w-full ml-3"
                        label={t("address")}
                        rules={[{ required: true, message: "Please input your Address!" }]}
                    >
                        <Input.TextArea />
                    </Form.Item>

                    <Row gutter={16}>
                        <Col lg={8}>
                            <Form.Item
                                name="City"
                                className="w-full ml-3"
                                label={t("city")}
                                rules={[{ required: true, message: "Please input your City!" }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col lg={8}>
                            <Form.Item
                                name="Country"
                                className="w-full"
                                label={t("country")}
                                rules={[
                                    { required: true, message: "Please input your Country!" },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col lg={8}>
                            <Form.Item
                                name="PostalCode"
                                className="w-full"
                                label={t("postal-code")}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input your Postal Code!",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Col lg={24}>
                        <div className="flex items-center justify-center" > <button className="mega-button" >{t('update')}</button></div>
                    </Col>
                </Row>
            </Form>
        </Spin>
    );
};

export default AccountUpdate;
