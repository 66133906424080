import React from 'react'
import api from '@/services/api'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { setRelationId, setRelationName } from '@/store/session'
import jwtDecode from 'jwt-decode'
import { message } from 'antd'
const useChangeRelation = () => {

    const [loading, setLoading] = useState(false)
    const [token, setToken] = useState(null)
    const dispatch = useDispatch()
    const changeRelation = async (userId, relationName) => {
        try {
            setLoading(true)
            const response = await api(true).post('/webshop/auth/change-relation', {
                userId: userId
            })
            setToken(response.data)
            let adminToken = null


            if (window.localStorage.getItem('x-access-token-relation')) {
                adminToken = window.localStorage.getItem('x-access-token-relation')
            } else {
                adminToken = window.localStorage.getItem('x-access-token')
            }

            window.localStorage.setItem('x-access-token', response.data)
            window.localStorage.setItem('x-access-token-relation', adminToken)
            console.log(response.data, adminToken)
            let decoded = jwtDecode(response.data)
            dispatch(setRelationId(decoded.RelationId))
            dispatch(setRelationName(relationName))
            setLoading(false)


        } catch (e) {
            message.error('Relation not changed')
            setLoading(false)

            if (window.localStorage.getItem('x-access-token-relation')) {
                window.localStorage.removeItem('x-access-token-relation')
                window.localStorage.removeItem('x-access-token')
                let adminToken = window.localStorage.getItem('x-access-token-relation')
                window.localStorage.setItem('x-access-token', adminToken)
                let decoded = jwtDecode(adminToken)
                dispatch(setRelationId(decoded.RelationId))
                dispatch(setRelationName(null))
                return null
            }
        }
    }


    const clear = () => {
        window.localStorage.removeItem('x-access-token')
        let adminToken = window.localStorage.getItem('x-access-token-relation')
        window.localStorage.setItem('x-access-token', adminToken)
        dispatch(setRelationId(null))
        dispatch(setRelationName(null))
    }

    return { loading, changeRelation, token, clear }
}

export default useChangeRelation
