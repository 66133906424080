import { useEffect, useState } from "react";
import { Row, Col, Card, Menu, message, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { ReactSVG } from "react-svg";
import axios from "axios";
import fetchVehicleMenu from "@/core/vehicle/fetchVehicleMenu";



const MenuItem = ({ url, activeMenuCode, onClick, color, MenuCode, title }) => {
  let active = activeMenuCode == MenuCode ? " active" : "";
  let colorItem = active == ' active' ? 'white' : color;
  let settings = useSelector((e) => e.settings);
  let activeStyle = {
    backgroundColor: `${settings['HeaderBgColor']}27`
  }

  if (active == ' active') {
    activeStyle['backgroundColor'] = color;
  }

  return (
    <div onClick={onClick} style={activeStyle} className={"little-menu__items__item" + active}>
      <Tooltip title={title}>
        <p>
          <ReactSVG
            beforeInjection={(svg) => {
              try {
                svg.classList.add("webshop-menu-icons");
                svg.setAttribute("width", "10px");
                svg.setAttribute("height", "100%");

                svg.setAttribute("fill", colorItem);
              } catch (e) {
              }
            }}
            src={url}
          />
        </p>
      </Tooltip>
    </div>
  );
};

const LittleMenu = ({ onClick, menuCode }) => {
  const [data, setData] = useState([]);
  let settings = useSelector((e) => e.settings);
  useEffect(() => {
    fetchVehicleMenu().then(e => {
      let t = [];
      e.forEach((item) => item.Groups.forEach((e) => e.Items.forEach((b) => (t = [...t, b]))));
      setData(t)
    }).catch((e) => {
      message.error("err")
    })
  }, []);

  return (
    <div className="little-menu">
      <div className="little-menu__container p-[10px]">
        <div className="little-menu__items gap-[10px]">
          {data.map((item, index) => (
            <MenuItem title={item.Menu} onClick={() => onClick(item.MenuCode, item.Menu)} color={settings?.menuIconColor} MenuCode={item.MenuCode} key={index} activeMenuCode={menuCode} url={`https://material.parts-soft.net/icon.php?iconName=` + item.Icon.IconName} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default LittleMenu;
