const searchFilterItem = (searchingElement, filterValue, searchMethod, arrayObjectProperties,product) => {
    switch (searchMethod) {
        case 'onkey':
            if (searchingElement == filterValue) {
                return true;
            }
            return false;
            break;
        case 'array':
            let arrayElementProp = arrayObjectProperties.arrayElement;
            let arrayElementType = arrayObjectProperties.type

            if (Array.isArray(searchingElement)) {
                for (const attr of searchingElement) {
                    let productItemValue = null;

                    if (arrayElementType == 'object') {
                        productItemValue = attr[arrayElementProp]
                        if (filterValue == productItemValue) {
                            return true
                        }
                    }
                }
            }
            return false;
            break;

        case 'injson':
            let parseJson = ""
            try {
                parseJson = JSON.stringify(product)
            } catch (e) {
                return false
            }

            let re = new RegExp(filterValue, 'i');

            if (parseJson.search(re) != -1) {
                return true;
            }
            return false;

        default:
            return false
    }
}

let search = (filter, product) => {
    let searchKey = filter.SearchKey;
    let searchMethod = filter.searchMethod;
    let arrayObjectProperties = filter.arrayObjectProperties;


    for (let i = 0; i < filter.Items.length; i++) {
        let find = searchFilterItem(product[searchKey], filter.Items[i].Value, searchMethod, arrayObjectProperties,product);
        if (find) {
            filter.Items[i].count++
        }
        i++;
    }



    return filter;
}




let filterAction = (_products, filters, filterTree) => {

    let selectedFilters = [];

    for (let i = 0; i < filters.length; i++) {
        let isChecked = filters[i].Items.filter(item => item.checked);
        if (isChecked.length) {
            selectedFilters.push({ ...filters[i], Items: isChecked })
        }
    }

    if (selectedFilters.length) {
        let generalFindingProductList = [];
        for (const filter of selectedFilters) {
            let filterName = filter.Name;
            let findingProductList = []
            for (const filterItem of filter.Items) {
                let filterItemValue = filterItem.Value;
                findingProductList.push(...filterTree[filterName][filterItemValue])
            }
            generalFindingProductList.push(findingProductList)

        }

        let result = generalFindingProductList.reduce((a, b) => a.filter(c => b.includes(c)));


        return _products.filter(item => result.includes(item._id));

    } else {
        return _products;
    }
}










let getCounter = (products, filters, filterTree) => {
   try{
    let _filters = JSON.parse(JSON.stringify(filters));
    
    filterGroupLoop:
    for (let i = 0; i < filters.length; i++) {
        for (let j = 0; j < filters[i].Items.length; j++) {
            _filters[i].Items[j].count = 0;

            let fakeFilter = JSON.parse(JSON.stringify(_filters));
            
            fakeFilter[i].Items.map(item => ({...item,checked : false}))
            fakeFilter[i].Items[j].checked = true;
            
            let filteringList = filterAction(products, fakeFilter, filterTree).map(item => item._id);
            filteringList.forEach(productId => {
                if(filterTree[filters[i].Name][filters[i].Items[j].Value].includes(productId)) 
                    _filters[i].Items[j].count++;
            })
        }
    }
    return _filters;
   }catch(e){
    console.log(e)
    return filters;
   }


}


export { getCounter, filterAction, search }
