import React, { useState } from 'react'
import api from '@/services/api'

const useSaveBrandCategory = () => {

    const [loading, setLoading] = useState(false);
    const request = async (brandId, status,genericArticleId) => {
        setLoading(true)
        let res = await api(1).post(`/catalog/admin-save-brands-to-generic-articles`, {
            brandId,
            status,
            genericArticleId
        })
        setLoading(false)
    }

    return { loading, request }
}

export default useSaveBrandCategory