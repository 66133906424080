import { Button,  Input,  message,  Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import fetchApplicationList from "@/core/vehicle/fetchApplicationList";
import { PlusOutlined } from "@ant-design/icons";
import useAddTab from "@/hooks/assistant/useAddTab";
import { useTranslation } from "react-i18next";
const ApplicationList = ({ reference, brandId, Series, onAddVehicle }) => {
  const [data, setData] = useState([{}, {}]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [vehicleCompatibility, setVehicleCompatibility] = useState(false);
  const [compMakes,setCompMakes] = useState([])

  const {t} = useTranslation()

  const addTab = useAddTab();



  useEffect(() => {
    if ((reference, brandId)) {
      setCompMakes([])
      setLoading(true)
      fetchApplicationList(reference, brandId).then((e) => {
        if (e == false) {
          return message.error("Application list not loading !");
        } else {
          setData(e);
          setOriginalData(e);
        }
      }).finally(() => setLoading(false));

    }
  }, [reference, brandId]);


  useEffect(() => {

    if (Series && (typeof Series == 'number' || (typeof Series == 'string' && Series != ''))) {
      let inc = originalData.filter(item => item.TypeCode == Number(Series))
      if (inc.length) {
        setVehicleCompatibility(true);
      } else {
        setVehicleCompatibility(false)
      }
    }
  }, [originalData, Series])



  const addVehicleToTab = (b) => {
    addTab({
      make: b.VehicleMakeId, makeName: b.VehicleMakeName, section: "vehicleMenu",
      vehicleLineId: b.VehicleLineId, vehiclineLineName: b.VehicleReferenceName,
      vehicleSeriesId: b.VehicleReferenceId, vehicleSeriesName: b.VehicleLineName,
      title: b.VehicleMakeId + " " + b.VehicleLineName + " " + b.VehicleReferenceName
    })
    if (onAddVehicle) onAddVehicle()
  }
  const columns = [

    {
      title: 'VehicleMakeName',
      dataIndex: 'VehicleMakeName',
      key: 'VehicleMakeName',
    },
    {
      title: t('line'),
      dataIndex: 'VehicleLineName',
      key: 'VehicleLineName',
    },
    {
      title: t('series'),
      dataIndex: 'VehicleReferenceName',
      key: 'VehicleReferenceName',
    },


    {
      title: 'BodyType',
      dataIndex: 'BodyType',
      key: 'BodyType',
    },
    {
      title: 'DriveType',
      dataIndex: 'DriveType',
      key: 'DriveType',
    },
    {
      title: 'EngineContents',
      dataIndex: 'EngineContents',
      key: 'EngineContents',
    },
    {
      title: 'EngineType',
      dataIndex: 'EngineType',
      key: 'EngineType',
    },
    {
      title: 'FuelCode',
      dataIndex: 'FuelCode',
      key: 'FuelCode',
    },
    {
      title: 'FuelSystem',
      dataIndex: 'FuelSystem',
      key: 'FuelSystem',
    },
    {
      title: 'KwCat',
      dataIndex: 'KwCat',
      key: 'KwCat',
    },
    {
      title: t('build-start'),
      dataIndex: 'BuildStart',
      key: 'BuildStart',
    },
    {
      title: t('build-end'),
      dataIndex: 'BuildEnd',
      key: 'BuildEnd',
    },

    {
      title: t('add'),
      dataIndex: 'Add',
      key: 'Add',
      render: (a, b) => {
        return (<Button onClick={() => addVehicleToTab(b)} type="primary" icon={<PlusOutlined />} />)
      }
    },
  ];


  useEffect(()=>{

    if(searchData == '')
      return setData(originalData)

    let news =[]
    try{
      for(const item of originalData){
        let searchValue = JSON.stringify(item)
        if(searchValue.search(new RegExp(searchData, 'i')) != -1) news.push(item)
      }
    }catch(e){

    }
    setData(news)
  },[searchData,data,originalData])

  return (


    <div>


      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10 }}>
      {compMakes?.map((a,b) => <Tag>{a}</Tag>)}
      <Input.Search loading={loading} style={{
        width: '100%',
      }} size="large" className="ml-3" value={searchData} onChange={(e)=>setSearchData(e.target.value)} enterButton />
      </div>



      <Table className="mt-3" loading={loading} dataSource={data} size="small" columns={columns} />


    </div>


  );
};

export default ApplicationList;



