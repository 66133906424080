import SearchForm from '@/components/vehicleSearch/SearchForm'
import { Button, Card, Col, Divider, Form, Input, Row, message } from 'antd';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import api from '@/services/api';
import BrandListSkeleton from '@/components/vehicleSearch/BrandListSkeleton';
import VehicleSearchVehicleCard from '@/components/vehicleSearch/VehicleSearchVehicleCard';
import useAddTab from '@/hooks/assistant/useAddTab';

const VehicleSearch = () => {

  const [form] = Form.useForm();
  const [searchType, setSearchType] = useState('id'); // Varsayılan arama türü
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState('brand');
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [modelSearchText, setModelSearchText] = useState('');

  const addTab = useAddTab();

  const searchAction = (search, type) => {
    if (!search || !type) {
      return message.warning('Lütfen arama kriterlerini giriniz');
    }

    let queryParam = '';
    if (type === 'motorCode') {
      queryParam = `EngineCode=${search}`;
    } else if (type === 'vehicleModelSeriesName') {
      queryParam = `vehicleModelSeriesName=${search}`;
    } else if (type === 'id') {
      queryParam = `id=${search}`;
    }

    if (queryParam) {
      setView('brand');
      setSelectedBrand(null);
      setModelSearchText(null);
      setLoading(true);

      api().get(`/catalog/searchVehicle?${queryParam}`)
        .then(response => {
          setData(response.data.data); // Başarılı sonuçları işleme
        })
        .catch(error => {
          message.error('Arama sırasında hata oluştu');
        })
        .finally(() => {
          setLoading(false); // Yükleme durumu her koşulda sona erer
        });
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const type = params.get('type') || 'id';
    const search = params.get('search') || '';

    // Formu ve searchType'ı güncelle
    setSearchType(type);
    form.setFieldsValue({ search });

    // Arama yapmak için form güncellendiğinde yeni bir useEffect ekle
    if (search) {
      searchAction(search, type);  // Search action'ı tetikleyelim
    }

  }, [location.search]);

  const onFinish = (values) => {
    const query = `?type=${searchType}&search=${values.search}`;
    navigate(query);
  };

  const onClickBrandAction = (brand) => {
    setSelectedBrand(brand);
    setView('model');
  };

  const onClickVehicleHandler = (vehicle) => {
    let props = {
      title: vehicle.mfrName + " " + vehicle.vehicleModelSeriesName + " / " + (vehicle.kbaNumbers?.[0] || "No Plate"), // KBA numarasını kullanıyoruz
      type: "assistant",
      section: "vehicleMenu",
      make: vehicle.mfrId, // Marka ID'si
      makeName: vehicle.mfrName, // Marka Adı
      vehicleLineId: vehicle.vehicleModelSeriesId, // Model Serisi ID
      vehicleLineName: vehicle.vehicleModelSeriesName, // Model Serisi Adı
      vehicleSeriesName: vehicle.description, // Araç tanımı (motor gücü vb.)
      vehicleMenuName: vehicle.subLinkageTargetType, // Araç alt türü (V: Passenger Car)
      vehicleSeriesId: vehicle.linkageTargetId, // Linkage target ID
      // plate: vehicle.kbaNumbers?.[0] || "No Plate" // Eğer varsa KBA numarası (plaka numarası) yoksa boş
    };
    addTab(props, 'redirect');
  };


  return (
    <div className="vehicle-search-page" style={{ backgroundColor: '#fff', height: '100vh-64px', borderRadius: '16px', padding: '16px' }}>
      <Row gutter={16}>
        <Col span={24}>
          <SearchForm
            form={form}
            searchType={searchType}
            setSearchType={setSearchType}
            onFinish={onFinish}
            loading={loading}
          />

          <Divider />

          {loading && Array.from({ length: 4 }).map((_, index) => (
            <BrandListSkeleton key={index} />
          ))}

          {
            view === 'brand' && <Row gutter={[16, 16]}>
              {data?.map((brand) => (
                <Col
                  xs={24} // Mobil cihazlarda tam genişlik
                  sm={12} // Küçük ekranlarda yarım genişlik
                  md={8} // Orta ekranlarda üçte bir genişlik
                  lg={6} // Büyük ekranlarda dörtte bir genişlik
                  xl={4} // Çok büyük ekranlarda beşte bir genişlik
                  key={brand.mfrName}
                >
                  <Card
                    hoverable
                    cover={
                      <img
                        alt={brand.mfrName}
                        src={brand.logo}
                        style={{
                          width: '100%',
                          height: '150px',
                          objectFit: 'contain',
                        }}
                      />
                    }
                    onClick={() => onClickBrandAction(brand.mfrName)}
                    style={{
                      borderRadius: '8px',
                      overflow: 'hidden',
                    }}
                  >
                    <Card.Meta
                      title={brand.mfrName}
                      style={{ textAlign: 'center' }}
                    />
                  </Card>
                </Col>
              ))}
            </Row>
          }

          {view === 'model' && (
            <>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={16} md={20}>
                  <Input
                    placeholder="Ara"
                    onChange={e => setModelSearchText(e.target.value)}
                    size="large"
                  />
                </Col>

                <Col xs={24} sm={8} md={4}>
                  <Button
                    type="primary"
                    block
                    size="large"
                    onClick={() => {
                      setView('brand');
                      setSelectedBrand(null);
                      setModelSearchText(null);
                    }}
                  >
                    Geri
                  </Button>
                </Col>

                {data?.find(brand => brand.mfrName === selectedBrand)?.vehicles
                  .filter(vehicle => {
                    if (!modelSearchText?.trim()) {
                      return true;
                    }
                    return JSON.stringify(vehicle).toLowerCase().includes(modelSearchText.toLowerCase());
                  })
                  .map((model) => (
                    <Col xs={24} sm={12} md={6} lg={4} key={model.linkageTargetId}>
                      <VehicleSearchVehicleCard onClick={onClickVehicleHandler} vehicle={model} />
                    </Col>
                  ))}
              </Row>
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default VehicleSearch;
