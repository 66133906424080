import api from '@/services/api';
export default (reference,brandId) =>  new Promise((resolve) => {
    api()({
      url: "/catalog/applicationList",
      method: "POST",
      data: {
        Reference: reference,
        BrandId: brandId,
      },
    })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((e) => {
        resolve(false);
      });
  });