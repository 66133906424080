import { Button, Divider, message, Modal, Select, Spin } from 'antd';
import React, { useEffect } from 'react'
import useFetchRelation from './useFetchRelation'

import { Avatar, List } from 'antd';
import useChangeRelation from './useChangeRelation';

const SelectRelationModal = ({ visible, onCancel, setVisible }) => {
  const fetchRelation = useFetchRelation();

  const changeRelation = useChangeRelation()

  const onChange = (value) => {
    let relation = fetchRelation.data.find(item => item.Id == value)
    let name = null
    if(relation){
      name = (relation?.Name ?? '') + ' ' + (relation?.FirstName ?? '') + ' ' + (relation?.RelationId ?? '') + ' ' + (relation?.ShortName ?? '')
    }

    changeRelation.changeRelation(value,name).then(e => setVisible(false))

  };


  const onClear = () => {
    changeRelation.clear()
    message.success('Relation cleared')
    setVisible(false)
  }


  useEffect(() => {
    if (visible)
      fetchRelation.fetchRelation()
  }, [visible])



  return (
    <div>
      <Modal closable={false} footer={null} open={visible} onCancel={onCancel} >
        <Select
          loading={fetchRelation.loading}
          style={{ width: '100%' }}
          showSearch
          placeholder="Select a relation"
          optionFilterProp="children"
          onChange={onChange}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={fetchRelation.data.map((item, index) => {

            let value = item.Id
            let name = (item.Name ?? '') + ' ' + (item.FirstName ?? '') + ' ' + (item.RelationId ?? '') + ' ' + (item.ShortName ?? '')

            return {
              value,
              label: name
            }

          })}
        />

        <Divider />

        <div style={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}>
          <Button onClick={() => onClear()} loading={
            changeRelation.loading
          } >Remove relation</Button>

        </div>






      </Modal>
    </div>
  )
}

export default SelectRelationModal
