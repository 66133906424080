import React, { useEffect } from 'react'
import { Row, Col, Divider, Typography, Card } from 'antd'
import IndexCarousel from '@/components/carousel/IndexCarousel'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import FeaturedBlock from '@/components/featuredBlocks/FeaturedBlock';
import ProductHistoryList from '@/components/history/productHistory/ProductHistoryList';
import TiresHistory from '@/components/history/TiresHistory'
import CarHistorySection from '@/components/history/CarHistorySection'
// import LoadingPage from '@/components/LoadingPage'
import api from '../services/api'

const getWorkingHours = () => new Promise((resolve, reject) =>
  api()({
    method: 'GET',
    url: '/webshop/working-hours'
  }).then(e => {
    resolve(e)
  }).catch(f => {
    reject(f)
  })
)

const getAnnouncements = () => new Promise((resolve, reject) =>
  api()({
    method: 'GET',
    url: '/webshop/announcements'
  }).then(e => {
    resolve(e)
  }).catch(f => {
    reject(f)
  })
)

const Index = () => {
  const { t } = useTranslation()
  const settings = useSelector(e => e.settings);
  let settingsShow = {
    ShowTires: 'true'
  }

  const [workingHours, setWorkingHours] = React.useState([])
  const [announcements, setAnnouncements] = React.useState([])

  useEffect(() => {
    getWorkingHours().then(e => {
      setWorkingHours(e.data.data)
    }).catch(e => {
      console.log(e);
    })

    getAnnouncements().then(e => {
      setAnnouncements(e.data.data)
    }).catch(e => {
      console.log(e);
    })
  }, [])

  return (
    <div
      style={{
        width: "100%",
        minHeight: "calc(100vh - 208px)",
        // height: "calc(100vh - 208px)",
        height: '100%',
        backgroundColor: "rgb(255, 255, 255)",
        padding: "10px",
        borderRadius: "16px",
        boxShadow: "rgba(149, 157, 165, 0.12) 0px 0px 15px 0px",
        marginTop: "30px",
        overflow: "hidden",
      }}
    >
      <Row gutter={{ xs: 12, sm: 10, md: 10, lg: 10 }}>
        <Col xs={24} sm={18} xl={18} className="gutter-row" span={20} style={{ height: 'calc(100vh - 228px)' }}>
          <Row gutter={{ xs: 12, sm: 10, md: 10, lg: 10 }}>
            <Col xs={24} sm={24} xl={24} className="gutter-row" span={18}>
              {/* <div style={{ backgroundColor: 'black' }}>col-1</div> */}
              <IndexCarousel />
            </Col>
          </Row>
          <Row gutter={{ xs: 12, sm: 10, md: 10, lg: 10 }} style={{ marginTop: '5px', height: 'calc(100% - 411px)' }}>
            <Col xs={24} sm={12} xl={12} className="gutter-row" span={18} style={{ height: '100%' }}>
              {/* <div style={{ backgroundColor: 'red' }}>col-2-1</div> */}
              {settingsShow.ShowTires === 'true' || settingsShow.ShowTires === true ? <Card className='product-history' style={{ height: '100%' }}>
                {/* <ProductHistoryList /> */}
                <CarHistorySection />
              </Card> : null}
            </Col>
            <Col xs={24} sm={12} xl={12} className="gutter-row" span={6} style={{ height: '100%' }}>
              {/* <div style={{ backgroundColor: 'blue' }}>col-2-2</div> */}
              {settingsShow.ShowTires === 'true' || settingsShow.ShowTires === true ? <Card className='product-history' style={{ height: '100%' }}>
                <ProductHistoryList />
                {/* <TiresHistory /> */}
              </Card> : null}
            </Col>
          </Row>
        </Col>

        <Col xs={24} sm={6} xl={6} className="gutter-row" span={4} style={{ borderRadius: "6px", border: `1px solid ${settings['HeaderBgColor']}27`, padding: '10px' }}>
          {/* <div style={{ backgroundColor: 'blue', height: '100%' }}>col-3</div> */}
          <section style={{ display: 'flex', flexDirection: "column", gap: "10px" }}>
            <h2 style={{
              color: "#73747A", fontFamily: "Inter", fontSize: "15px", fontStyle: "normal", fontWeight: "600", lineHeight: "normal",
              display: "flex",
              padding: "10px",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "10px",
              alignSelf: "stretch",
              borderBottom: "1px solid #F1F1F1"
            }}>{t('working-hours')}</h2>
            {workingHours.map((e, i) => {
              return <p key={i} style={{
                display: "flex",
                padding: "10px 20px",
                justifyContent: "space-between",
                alignItems: "flex-start",
                alignSelf: "stretch",
                color: "#7E7E7E",
                fontfamily: "Inter",
                fontSize: "15px",
                fontstyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
                borderRadius: "12px",
                border: "1px solid #ECECEC",
                background: "#F8F8F8",
              }}><span>{e.Title}</span>: {e.Time}</p>
            })}
          </section>
          <section style={{ marginTop: '20px', display: 'flex', flexDirection: "column", gap: "10px" }}>
            <h2 style={{
              color: "#73747A", fontFamily: "Inter", fontSize: "15px", fontStyle: "normal", fontWeight: "600", lineHeight: "normal",
              display: "flex",
              padding: "10px",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "10px",
              alignSelf: "stretch",
              borderBottom: "1px solid #F1F1F1"
            }}>{t('announcements')}</h2>
            <div id="scrollableDiv"
              style={{
                height: "185px",
                overflow: 'auto',
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}>
              {announcements.map((e, i) => {
                return <p key={i} style={{
                  display: "flex",
                  padding: "10px 20px",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  alignSelf: "stretch",
                  borderRadius: "12px",
                  border: "1px solid #ECECEC",
                  background: "#F8F8F8",
                  color: " #7E7E7E",
                  fontFamily: "Inter",
                  fontSize: "15px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "normal",
                }}>{e.Title}<span>{e.CreatedAt.split('T')[0].split('-').reverse().join('.')}</span></p>
              })}
            </div>
          </section>
        </Col>
      </Row>
    </div>



    // <div style={{
    //   width: "100%",
    //   height: "calc(100vh - 208px)",
    //   backgroundColor: "rgb(255, 255, 255)",
    //   padding: "10px",
    //   borderRadius: "16px",
    //   boxShadow: "rgba(149, 157, 165, 0.12) 0px 0px 15px 0px",
    //   marginTop: "30px",
    //   overflow: "hidden",
    // }}>
    //   <Row>
    //     <Col style={{ width: '70%' }} >
    //       <IndexCarousel />
    //     </Col>
    //     <Col style={{ width: '29.4%' }}  >
    //       <div style={{ marginLeft: '10px', width: '100%', borderRadius: "6px", border: "1px solid #d7e8ff", padding: '10px', height: "calc(100vh - 632px)", minHeight: '431px' }}>
    //         <section style={{ display: 'flex', flexDirection: "column", gap: "10px" }}>
    //           <h2 style={{
    //             color: "#73747A", fontFamily: "Inter", fontSize: "15px", fontStyle: "normal", fontWeight: "600", lineHeight: "normal",
    //             display: "flex",
    //             padding: "10px",
    //             flexDirection: "column",
    //             alignItems: "flex-start",
    //             gap: "10px",
    //             alignSelf: "stretch",
    //             borderBottom: "1px solid #F1F1F1"
    //           }}>{t('working-hours')}</h2>
    //           {workingHours.map((e, i) => {
    //             return <p key={i} style={{
    //               display: "flex",
    //               padding: "10px 20px",
    //               justifyContent: "space-between",
    //               alignItems: "flex-start",
    //               alignSelf: "stretch",
    //               color: "#7E7E7E",
    //               fontfamily: "Inter",
    //               fontSize: "15px",
    //               fontstyle: "normal",
    //               fontWeight: "400",
    //               lineHeight: "normal",
    //               borderRadius: "12px",
    //               border: "1px solid #ECECEC",
    //               background: "#F8F8F8",
    //             }}><span>{e.Title}</span>: {e.Time}</p>
    //           })}
    //         </section>
    //         <section style={{ marginTop: '20px', display: 'flex', flexDirection: "column", gap: "10px" }}>
    //           <h2 style={{
    //             color: "#73747A", fontFamily: "Inter", fontSize: "15px", fontStyle: "normal", fontWeight: "600", lineHeight: "normal",
    //             display: "flex",
    //             padding: "10px",
    //             flexDirection: "column",
    //             alignItems: "flex-start",
    //             // gap: "10px",
    //             alignSelf: "stretch",
    //             borderBottom: "1px solid #F1F1F1"
    //           }}>{t('announcements')}</h2>
    //           <div id="scrollableDiv"
    //             style={{
    //               height: "185px",
    //               overflow: 'auto',
    //               display: "flex",
    //               flexDirection: "column",
    //               // padding: '0 10px',
    //               gap: "10px",
    //             }}>
    //             {announcements.map((e, i) => {
    //               return <p key={i} style={{
    //                 display: "flex",
    //                 padding: "10px 20px",
    //                 justifyContent: "space-between",
    //                 alignItems: "flex-start",
    //                 alignSelf: "stretch",
    //                 borderRadius: "12px",
    //                 border: "1px solid #ECECEC",
    //                 background: "#F8F8F8",
    //                 color: " #7E7E7E",
    //                 fontFamily: "Inter",
    //                 fontSize: "15px",
    //                 fontStyle: "normal",
    //                 fontWeight: "400",
    //                 lineHeight: "normal",
    //               }}>{e.Title}<span>{e.CreatedAt.split('T')[0].split('-').reverse().join('.')}</span></p>
    //             })}
    //           </div>
    //         </section>
    //       </div>
    //     </Col>
    //   </Row>

    //   <Row style={{ marginTop: '10px', gap: '10px' }}>
    //     <Col style={{ width: '35%' }} >
    //       {settings.ShowTires === 'true' || settings.ShowTires === true ? <Card className='product-history' style={{ height: '100%' }}>
    //         {/* <ProductHistoryList /> */}
    //         <CarHistorySection />
    //       </Card> : null}
    //     </Col>
    //     <Col style={{ width: '35%' }}  >
    //       {settings.ShowTires === 'true' || settings.ShowTires === true ? <Card className='product-history' style={{ height: '100%' }}>
    //         <ProductHistoryList />
    //         {/* <TiresHistory /> */}
    //       </Card> : null}
    //     </Col>
    //     <Col style={{ width: '28%' }}>
    //       {/* <FeaturedBlock /> */}
    //     </Col>
    //   </Row>

    //   {/* <LoadingPage /> */}
    //   {/* <Row style={{ marginTop: '7px' }}>
    //     <Col lg={17} className="mt-5">
    //       <IndexCarousel />
    //     </Col>
    //     <Col lg={7} className="mt-5">
    //       <IndexCarousel />
    //     </Col>
    //   </Row>

    //   <div className="mt-5 mb-5" ><FeaturedBlock /></div>
    //   <Divider />
    //   <Typography.Title className="text-center" level={2}>{t('history')}</Typography.Title>
    //   <Row gutter={16}>
    //     <Col lg={settings.ShowTires === 'true' || settings.ShowTires === true ? 12 : 24} md={24} sm={24} className="w-full">
    //       <Card>
    //         <Typography.Title level={4}>{t('articles')}</Typography.Title>
    //         <ProductHistoryList />
    //       </Card>
    //     </Col>
    //     {settings.ShowTires === 'true' || settings.ShowTires === true ? <Col lg={12} md={24} sm={24} className="w-full">
    //       <Card style={{ height: '100%' }}>
    //         <TiresHistory />
    //       </Card>
    //     </Col> : null}
    //   </Row> */}

    // </div>
  )
}

export default Index
