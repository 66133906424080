import { Form, Input, Modal, message } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ResultVinnNumberModal } from '@/layouts/Dashboard/Search/ResultVinnNumber'

const VehicleSearch = ({ onClose }) => {
  const [form] = Form.useForm()
  const [vinn, setVinn] = useState('')
  const [showModal, setShowModal] = useState(false)
  const { t } = useTranslation()

  const onVehicleSearch = (values) => {
    setShowModal(true)
    form.resetFields()
    onClose()
  }

  const handleSearch = () => {
    form.submit()
  }

  return (
    <div className="custom-input-search">
      <Form layout="vertical" form={form} onFinish={onVehicleSearch} className="space-y-6">
        <Modal width={'100%'} title={'VINN Number'} footer={null} zIndex={9999999} open={showModal} onCancel={() => setShowModal(false)}>
          <ResultVinnNumberModal cb={() => setShowModal(false)} vinn={vinn} />
        </Modal>

        <span style={{ fontWeight: 500, width: '100%', borderBottom: '1px solid #DDDDDD', display: 'block' }}>{`${t('vehicle-search')}:`}</span>
        <Form.Item
          label={`${t('kba-key-number')}:`}
          name="kbaNumber"
          rules={[{ required: true, message: t('please-input-the-kba-number') }]}
          style={{
            marginBottom: '24px',
            marginTop: '10px'
          }}
        >
          <Input.Search
            placeholder={t('kba-number')}
            enterButton={<SearchOutlined />}
            value={vinn}
            onChange={(e) => setVinn(e.target.value)}
            onSearch={handleSearch}
            size="large"
          />
        </Form.Item>
      </Form>
    </div>
  )
}

export default VehicleSearch
