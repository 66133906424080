const SellerIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={19} height={16} viewBox="0 0 19 16" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.258 0H2.345a.973.973 0 0 0-.96.812L.796 4.345a.892.892 0 0 0 .268.815l.004.005.006.004c.104.092.25.208.435.322h-.055v8.365A2.144 2.144 0 0 0 3.598 16H15.31a2.144 2.144 0 0 0 2.143-2.144V5.491h-.066c.153-.094.298-.202.435-.322l.005-.005.005-.005a.891.891 0 0 0 .268-.815L17.51.81a.973.973 0 0 0-.96-.81H5.258ZM2.395 13.856v-7.99c.235.059.494.095.779.095.732 0 1.336-.244 1.75-.48.132-.076.247-.151.342-.22.095.068.209.144.34.22.414.236 1.018.48 1.75.48.733 0 1.337-.244 1.75-.48.132-.076.247-.152.342-.22.095.068.21.144.341.22.414.236 1.017.48 1.75.48.733 0 1.336-.244 1.75-.48.132-.076.246-.152.341-.22.095.068.21.144.342.22.413.236 1.017.48 1.75.48.268 0 .534-.034.79-.1v7.995a1.203 1.203 0 0 1-1.202 1.203h-3.014v-4.55a2.144 2.144 0 0 0-2.144-2.143H8.48a2.144 2.144 0 0 0-2.144 2.143v4.55H3.598a1.203 1.203 0 0 1-1.203-1.203Zm4.882 1.203h4.078v-4.55a1.203 1.203 0 0 0-1.203-1.202H8.48a1.203 1.203 0 0 0-1.202 1.202v4.55ZM4.819 4.384 5.163.94H2.345a.031.031 0 0 0-.031.026L1.726 4.49c.24.203.735.53 1.448.53a2.6 2.6 0 0 0 1.283-.356 3 3 0 0 0 .358-.242l.004-.038Zm.94.07.35-3.513h2.868v3.497a2.885 2.885 0 0 1-.338.225c-.318.182-.76.356-1.282.356s-.965-.174-1.283-.356a2.901 2.901 0 0 1-.316-.209ZM9.918.941v3.497c.081.063.195.144.337.225.319.182.76.356 1.283.356a2.6 2.6 0 0 0 1.283-.356 2.9 2.9 0 0 0 .315-.208L12.786.94H9.919Zm4.161 3.48-.348-3.48h2.819a.031.031 0 0 1 .03.026l.589 3.522a2.233 2.233 0 0 1-1.449.53c-.522 0-.964-.174-1.282-.356a2.865 2.865 0 0 1-.359-.242Z"
        fill={props.color || "#707070"}
      />
    </svg>
  )

  export default SellerIcon