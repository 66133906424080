import NumberRound from 'number-zero-round';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'




const useCalculateTotalBasket = () => {

  const basket = useSelector(e => e.basket)
  const basketList = basket.basketList;
  const priceList = basket.priceList
  const [prices, setPrices] = React.useState({ NetPrice: 0, GrossPrice: 0 })

  useEffect(() => {

    console.log(basketList)

     let _prices = { NetPrice: 0, GrossPrice: 0 }
    for (const item of basketList) {

      if(item?.prices && item?.prices?.data?.length){
        let _net = Number(item?.prices?.data[0]?.price?.NetPrice)
        let _gross = Number(item?.prices?.data[0]?.price?.GrossPrice)
        let _qty = Number(item.Quantity) || 1

        _prices.NetPrice = _prices.NetPrice + (_net * _qty)

        _prices.GrossPrice = _prices.GrossPrice + (_gross * _qty)
      }

      // let qty = Number(item.Quantity) || 1
      // let net = item?.prices?.fastestAvability?.NetPrice
      // let gross = item?.prices?.fastestAvability?.GrossPrice

      // _prices.NetPrice = _prices.NetPrice + (net * qty)
      // _prices.GrossPrice = _prices.GrossPrice + (gross * qty)

    }
    setPrices(_prices)
  }, [basketList])



  return {
    NetPrice: NumberRound(prices.NetPrice),
    GrossPrice: NumberRound(prices.GrossPrice)
  }

}

export default useCalculateTotalBasket