import React, { useEffect, useState } from 'react'
import { arrayMoveImmutable } from 'array-move';
import CategoryListSortable from '@/components/tecdocSettings/TecdocMenuCreate/CategoryList';
import { Alert, Breadcrumb, Button, Skeleton, Typography } from 'antd';
import CreateMainCategory from '@/components/tecdocSettings/TecdocMenuCreate/CreateMainCategory';
import api from '@/services/api';
import useFetchCategories from '@/hooks/adminTecdoc/useFetchCategories';
import useCreateCategory from '@/hooks/adminTecdoc/useCreateCategory';
import useChangeSort from '@/hooks/adminTecdoc/useChangeSort';
import { useNavigate } from 'react-router-dom';











const TecdocMainCategories = () => {
  const fetchCategories = useFetchCategories();
  const changeSort = useChangeSort();
  const navigate = useNavigate()
  const onSortEnd = ({ oldIndex, newIndex }) => {



    let newList = arrayMoveImmutable(fetchCategories.categories, oldIndex, newIndex)

    fetchCategories.setCategories(newList);

    let ids = newList.map((item, index) => {
      return [item?.id, index]
    })

    changeSort.changeSort(ids)

  };


  const [showCreateModal, setShowCreateModal] = useState(false)


  const onClickDeleteHandler = (id) => {
    fetchCategories.setCategories(prev => prev.filter(e => e?.id !== id))
  }


  const onUpdateHandler = (e) => {
    fetchCategories.setCategories(prev => prev.map(f => {
      if (f?.id === e?.id) {
        return e
      }
      return f
    }))
  }

  const onClickHandler = (e) => {
    navigate('/tecdoc/menu-create/categories/' + e?.id)
  }


  return (
    <Skeleton loading={fetchCategories.loading}>
      <CreateMainCategory onCreate={(e) => {
        fetchCategories.setCategories(prev => [...prev, e])
        setShowCreateModal(false)
      }} show={showCreateModal} onCancel={() => setShowCreateModal(false)} />




      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>

        <div>
          
        </div>

        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}>
          <Button onClick={() => setShowCreateModal(true)} type='primary'>New Category</Button>
        </div>






      </div>

      <div className='mt-3'>
        <CategoryListSortable onClickItem={onClickHandler} onUpdate={onUpdateHandler} onClickDelete={onClickDeleteHandler} items={fetchCategories.categories} onSortEnd={onSortEnd} />
      </div>

      <div style={{marginTop:'35px'}}>
        <Alert type='info' banner message="Categorieën en subcategorieën bepalen hoe gebruikers de producten vinden. Aan elke subcategorie kunnen meerdere generieke artikelen worden toegevoegd. De volgorde van de generieke artikelen bepalen de volgorde van de zoekresultaten."></Alert>
      </div>

    </Skeleton>
  )
}

export default TecdocMainCategories