import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  account: {},
  token: "abc",
  catalog: "",
  login: false,
  psToken : null,
  guestUserToken : null,
  isGuest : false,
  relationId : null,
  relationName : null

}

export const session = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setAccount: (state, action) => {
      state.account = action.payload
    },
    setToken: (state, action) => {
      state.token = action.payload
    },
    setCatalog: (state, action) => {
      state.catalog = action.payload
    },
    setLogin: (state, action) => {
      state.login = action.payload
    },
    setRelationId : (state,action) => {
      state.relationId = action.payload
    },
    setPsToken : (state,action) => {
      state.psToken = action.payload
    },
    setGuestUserToken :(state,action) => {
      state.guestUserToken = action.payload
    },
    setIsGuest :(state,action) => {
      state.isGuest = action.payload
    },
    setRelationName : (state,action) => {
      state.relationName = action.payload
    }

  },
})

// Action creators are generated for each case reducer function
export const { setRelationName,setPsToken,setAccount, setToken, setCatalog, setLogin,setRelationId,setGuestUserToken,setIsGuest} = session.actions

export default session.reducer

