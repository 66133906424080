import api from '../../services/api';
import fetchPriceAndRoute from '../price/fetchPriceAndRoute';
import fetchPriceAndRoutev2 from '../price/fetchPriceAndRoutev2';
export default () => {
    return new Promise((resolve, reject) => {
        const config = {
            url: '/webshop/basket?limit=200',
        };
        api()(config).then(async (result) => {
            if (result.data.status == 'true') {
                let rowids = [];
                for (const item of result.data.data) {
                    rowids.push({ key: item.RowId, rows: [item.RowId] });
                }

                let fetchPrices = await fetchPriceAndRoutev2(JSON.stringify(rowids))
                const deliveries = fetchPrices.deliveries
                let prices = fetchPrices.data
                for (let i = 0; i < prices.length; i++) {
                    let find = result.data.data.find(x => x.RowId == prices[i].key)

                    if (find) {
                        result.data.data[i].prices = prices[i]
                    }

                }

                console.log({fetchPrices})

                resolve({ status: true, data: result.data.data, message: result.data.message,deliveries })
            } else {
                reject({ status: false, message: result.data.message,deliveries:[] });

            }
        }).catch((e) => {
            reject({ status: false, message: (e?.response?.data?.message) });
        })
    })
}