import React, { useEffect, useState } from 'react'
import BasketItemCollapse from './BasketItemCollapse';
const BasketItem = ({ product }) => {
  return (
    <div className="mb-[5px]">
      <BasketItemCollapse product={product} />
    </div>
  );
};

export default BasketItem
