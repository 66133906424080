import React from "react";
const LoadingPage = ({ text = `Powered by @2022 Parts-Soft Arge` }) => {
  React.useEffect(() => { }, []);

  return (
    <div>
      <div style={{ height: '100vh' }} className="loading-wrapper">
        <div className="loader">
          <svg xmlns="http://www.w3.org/2000/svg" style={{ borderRadius: '50%' }} xmlnsXlink="http://www.w3.org/1999/xlink" width="100px" height="100px" viewBox="0 0 60 60" version="1.1">
            <g id="surface1">
              <rect x={0} y={0} width={60} height={60} style={{ fill: 'rgb(16.862745%,58.039216%,78.431373%)', fillOpacity: 1, stroke: 'none' }} />
              <path style={{ stroke: 'none', fillRule: 'nonzero', fill: 'rgb(7.45098%,45.882353%,60.784314%)', fillOpacity: 1 }} d="M 38.226562 30.570312 C 38.226562 34.808594 34.792969 38.242188 30.554688 38.242188 C 26.320312 38.242188 22.882812 34.808594 22.882812 30.570312 C 22.882812 26.332031 26.320312 22.898438 30.554688 22.898438 C 34.792969 22.898438 38.226562 26.332031 38.226562 30.570312 Z M 38.226562 30.570312 " />
              <path style={{ stroke: 'none', fillRule: 'nonzero', fill: 'rgb(7.45098%,45.882353%,60.784314%)', fillOpacity: 1 }} d="M 42.433594 18.65625 L 60 36.21875 L 60 60 L 22.882812 60 L 22.882812 45.5625 L 37.390625 39.382812 Z M 42.433594 18.65625 " />
              <path style={{ stroke: 'none', fillRule: 'nonzero', fill: 'rgb(100%,100%,100%)', fillOpacity: 1 }} d="M 30.554688 13.726562 C 21.636719 13.722656 14.265625 20.671875 13.746094 29.574219 L 13.710938 29.574219 L 13.710938 60 L 22.882812 60 L 22.882812 45.5625 C 29.085938 48.71875 36.59375 47.730469 41.769531 43.078125 C 46.941406 38.421875 48.71875 31.0625 46.238281 24.5625 C 43.753906 18.058594 37.527344 13.757812 30.566406 13.734375 Z M 30.554688 38.238281 C 26.3125 38.238281 22.875 34.800781 22.875 30.554688 C 22.875 26.3125 26.3125 22.875 30.554688 22.875 C 34.800781 22.875 38.238281 26.3125 38.238281 30.554688 C 38.238281 34.800781 34.800781 38.238281 30.554688 38.238281 Z M 30.554688 38.238281 " />
              <path style={{ stroke: 'none', fillRule: 'nonzero', fill: 'rgb(7.45098%,45.882353%,60.784314%)', fillOpacity: 1 }} d="M 60 29.175781 L 45.144531 14.316406 L 52.074219 7.382812 L 60 15.304688 Z M 60 29.175781 " />
              <path style={{ stroke: 'none', fillRule: 'nonzero', fill: 'rgb(100%,100%,100%)', fillOpacity: 1 }} d="M 53.847656 11.179688 C 53.847656 13.890625 51.648438 16.085938 48.941406 16.085938 C 46.230469 16.085938 44.035156 13.890625 44.035156 11.179688 C 44.035156 8.472656 46.230469 6.273438 48.941406 6.273438 C 51.648438 6.273438 53.847656 8.472656 53.847656 11.179688 Z M 53.847656 11.179688 " />
            </g>
          </svg>
        </div>


        <div className="fixed-footer">
          <p>
            Powered by{' '}
            <span onClick={() => window.open('https://www.parts-soft.nl/', '_blank')} style={{ cursor: "pointer" }}>
              Parts-Soft
            </span>
          </p>
        </div>
      </div>
    </div >

    // <div>
    //   <div style={{ height: '100vh' }} className="loading-wrapper">
    //     <h1 className="brand">
    //       <svg xmlns="http://www.w3.org/2000/svg" style={{ borderRadius: '50%' }} xmlnsXlink="http://www.w3.org/1999/xlink" width="100px" height="100px" viewBox="0 0 60 60" version="1.1">
    //         <g id="surface1">
    //           <rect x={0} y={0} width={60} height={60} style={{ fill: 'rgb(16.862745%,58.039216%,78.431373%)', fillOpacity: 1, stroke: 'none' }} />
    //           <path style={{ stroke: 'none', fillRule: 'nonzero', fill: 'rgb(7.45098%,45.882353%,60.784314%)', fillOpacity: 1 }} d="M 38.226562 30.570312 C 38.226562 34.808594 34.792969 38.242188 30.554688 38.242188 C 26.320312 38.242188 22.882812 34.808594 22.882812 30.570312 C 22.882812 26.332031 26.320312 22.898438 30.554688 22.898438 C 34.792969 22.898438 38.226562 26.332031 38.226562 30.570312 Z M 38.226562 30.570312 " />
    //           <path style={{ stroke: 'none', fillRule: 'nonzero', fill: 'rgb(7.45098%,45.882353%,60.784314%)', fillOpacity: 1 }} d="M 42.433594 18.65625 L 60 36.21875 L 60 60 L 22.882812 60 L 22.882812 45.5625 L 37.390625 39.382812 Z M 42.433594 18.65625 " />
    //           <path style={{ stroke: 'none', fillRule: 'nonzero', fill: 'rgb(100%,100%,100%)', fillOpacity: 1 }} d="M 30.554688 13.726562 C 21.636719 13.722656 14.265625 20.671875 13.746094 29.574219 L 13.710938 29.574219 L 13.710938 60 L 22.882812 60 L 22.882812 45.5625 C 29.085938 48.71875 36.59375 47.730469 41.769531 43.078125 C 46.941406 38.421875 48.71875 31.0625 46.238281 24.5625 C 43.753906 18.058594 37.527344 13.757812 30.566406 13.734375 Z M 30.554688 38.238281 C 26.3125 38.238281 22.875 34.800781 22.875 30.554688 C 22.875 26.3125 26.3125 22.875 30.554688 22.875 C 34.800781 22.875 38.238281 26.3125 38.238281 30.554688 C 38.238281 34.800781 34.800781 38.238281 30.554688 38.238281 Z M 30.554688 38.238281 " />
    //           <path style={{ stroke: 'none', fillRule: 'nonzero', fill: 'rgb(7.45098%,45.882353%,60.784314%)', fillOpacity: 1 }} d="M 60 29.175781 L 45.144531 14.316406 L 52.074219 7.382812 L 60 15.304688 Z M 60 29.175781 " />
    //           <path style={{ stroke: 'none', fillRule: 'nonzero', fill: 'rgb(100%,100%,100%)', fillOpacity: 1 }} d="M 53.847656 11.179688 C 53.847656 13.890625 51.648438 16.085938 48.941406 16.085938 C 46.230469 16.085938 44.035156 13.890625 44.035156 11.179688 C 44.035156 8.472656 46.230469 6.273438 48.941406 6.273438 C 51.648438 6.273438 53.847656 8.472656 53.847656 11.179688 Z M 53.847656 11.179688 " />
    //         </g>
    //       </svg>
    //     </h1>

    //     <div className="loading-bar"></div>
    //     <h1 className="mt-3">{text}</h1>
    //   </div>
    // </div>
  );
};

export default LoadingPage;
