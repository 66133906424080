import React from 'react'

const ProductLinksComponent = ({ links }) => {
    return (
        <div>
            {links.map((item, index) => {
                return (
                    <div key={index}>
                        <a
                            href={item.url}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {item.url}
                        </a>
                    </div>
                )
            })}
        </div>
    )
}

export default ProductLinksComponent