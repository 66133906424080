import React from 'react'
import { useState } from "react";
// import { Tabs } from "antd";
import LoginForm from '../components/login/LoginForm';
import LayoutLogin from '../layouts/Login/LoginLayout';
// import { useTranslation } from 'react-i18next';
const LoginPage = ({ loading }) => {

  // const { TabPane } = Tabs;
  // const [tab, setTab] = useState("login");
  // const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [email, setEmail] = useState("");
  // const { t } = useTranslation()

  const onClickForgotPasswordHandler = () => {
    console.log(email);
  }

  return (
    <div id="login-layout">
      <LayoutLogin element={
        <LoginForm onChangeEmail={(e) => setEmail(e)} onClickForgotPassword={onClickForgotPasswordHandler} />
      } />

      <div className="fixed-footer">
        <p>
          Powered by{' '}
          <span onClick={() => window.open('https://www.parts-soft.nl/', '_blank')} style={{ cursor: "pointer" }}>
            Parts-Soft
          </span>
        </p>
      </div>

      {/* <Row className="mt-5 mb-5">
        <Col sm={{ span: 24 }} lg={{ span: 8, offset: 8 }} >
          <Tabs animated={true} defaultActiveKey={tab} activeKey={tab} onChange={(e) => setTab(e)}>
            <TabPane tab={t('login')} key="login">
              <LayoutLogin element={<LoginForm onChangeEmail={(e) => setEmail(e)} onClickForgotPassword={onClickForgotPasswordHandler} />} />
            </TabPane>


          </Tabs>
        </Col>
      </Row> */}
    </div>
  )
}

export default LoginPage

