import api from '../../services/api';
export default (quantity,id) => {
    return new Promise((resolve, reject) => {
        const config = {
            url: '/webshop/basket/'+id,
            data: {
                Quantity: quantity
              },
            method : "PUT"
        };
        api()(config).then(result => {
            if (result.data.status == 'true') {
                resolve({ status: true, data: result.data.data, message: result.data.message })
            } else {
                reject({ status: false, message: result.data.message });

            }
        }).catch((e) => {
            reject({ status: false, message: e.response.data.message });
        })
    })
}