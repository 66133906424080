import React, { useEffect, useState } from 'react'
import { Button, Descriptions, Input, Radio, Skeleton } from 'antd';
import api from '@/services/api'

const RdwSection = ({ plate }) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')
    useEffect(() => {
        if (plate) {
            api().get(`/catalog/rdw/${plate}`).then(res => {
                const obj = Object.keys(res.data.data)
                const arr = []

                obj.forEach((item, index) => {
                    arr.push({
                        label: item,
                        value: res.data.data[item]
                    })
                })

                setData(arr)

            }).catch(f => { }).finally(_ => setLoading(false))
        } else {
            setData([])
        }
    }, [plate])


    const onFilter = (data) => {
        if (search) {
            let temp = [];

            for (let i = 0; i < data.length; i++) {
                if (data[i].label.toLowerCase().includes(search.toLowerCase()) || data[i].value.toLowerCase().includes(search.toLowerCase())) {
                    temp.push(data[i])
                }
            }

            return temp


        } else {
            return data
        }
    }


    return (
        <div>
            <Skeleton active loading={loading}>



                <Descriptions
                    size='small'
                    bordered
                    title="RDW Data"
                    extra={<Button type="primary">Edit</Button>}
                >

                    <Descriptions.Item label={"Search"} span={3}>
                        <Input.Search autoFocus placeholder="Search"
                            onChange={(e) => setSearch(e.target.value)}
                            enterButton />
                    </Descriptions.Item>

                    {onFilter(data).map((item, index) => {
                        return (
                            <Descriptions.Item label={item.label} span={3}>
                                {item.value}
                            </Descriptions.Item>
                        )
                    })}

                </Descriptions>

            </Skeleton>

        </div>
    )
}

export default RdwSection