import React, { useEffect, useState, memo, useCallback } from 'react'
import Header from './Header'
import { Col, Row } from 'antd';
import SideBarMenu from '@/layouts/Dashboard/SideBarMenu';
import { useDispatch, useSelector } from 'react-redux';
import { getBasketList, setLoadingAllBasketList } from '@/store/basket';
import { setLoadingCatalogPage } from '@/store/site';

import Basket from '@/components/basket/Basket';
import Whatsapp from '@/extra/Whatsapp';
import useLoadTabFromStorage from '@/hooks/assistant/useLoadTabFromStorage';
import { setHideNetPrice } from '@/store/settings';
import { getHistoryList } from '@/store/history';
import DrawerSearch from './DrawerSearch/DrawerSearch';



const MainLayout = ({ children, loading }) => {
  const d = useDispatch();
  const loadTabFromStorage = useLoadTabFromStorage()
  const store = useSelector(e => e?.settings);
  const session = useSelector(e => e?.session);
  // const store = useSelector(e => e?.settings);
  // // Main layout mount edildiği zaman çalışacak olan işlemler.
  useEffect(() => {
    // Sepet listesini yükle.
    d(setLoadingCatalogPage(true))
    d(setLoadingAllBasketList(true))
    d(getBasketList(() => {
      //basket listesi yüklendiğinde fiyatların getirilme işlemi de çalışması lazım.
      d(setLoadingAllBasketList(false))
      d(setLoadingCatalogPage(false))

    }))
    d(getHistoryList())
  }, [session?.relationId])



  const handleKeyDown = (event) => {
    if (event.ctrlKey && event.key === '0') {
      // İstenilen olayı burada gerçekleştirin
     // if (store['ShowGrossPrice'] === 'false' || store['ShowGrossPrice'] === false) return;
      d(setHideNetPrice(!store?.hideNetPrice))
      
    }



    

  };

  useEffect(() => {
    //event listener ctrl + 0

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    }



  }, [store?.hideNetPrice])


  return (
    <div key={loading} className='main-layout'>
      <div>
        <div className='parts-soft-webshop flex'>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <div className='left-sidebar-menu' style={{
              zIndex: 4, display: 'flex',
              alignItems: 'center',
              height: 'calc(100vh - 127px)',
              width: '100px',
              position: 'fixed',
              // background: 'red',
              flexDirection: 'column',
              bottom: 0,
              // width: '10%',
            }}>
              <SideBarMenu />
            </div>

            <Row>
              <Col span={24}>
                <Header />
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <div className='web-layout'>
                    {children}
                </div>
              </Col>
            </Row>
          </div>
          <div>
            <Basket />
            <DrawerSearch/> 
          </div>
          {/* <Whatsapp /> */}
        </div>
      </div>
    </div>
  )
}

export default MainLayout
