import api from '@/services/api';
export default (plate,signal = null) => {


    return new Promise((resolve,reject) => {
        api(false,signal)({
            url: '/catalog/licensePlateSearch' ,
            method : "POST",
            data : {
              LicensePlate : plate
            },
          }).then(e =>{
            resolve(e.data.data)
          }).catch(e => {
            console.log(e)
            reject(false)
          })
    })
}