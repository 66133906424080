import { Avatar, Divider, List, Skeleton, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useAd } from 'react';
import useAddTab from '@/hooks/assistant/useAddTab';
import { getHistoryList } from '@/store/history';

const HistoryItem = ({ item, onClick }) => {
  const [brandLogo, setBrandLogo] = React.useState("");
  const historyList = useSelector((e) => e.history.historyList);
  const d = useDispatch();
  const addTab = useAddTab();

  useEffect(() => {
    d(getHistoryList());
  }, [])

  const loading = historyList.loadingHistory


  React.useEffect(() => {
    if (item && item.BrandName) {
      setBrandLogo("https://material.parts-soft.net/logo.php?brandName=" + item.BrandName.toLowerCase());
    }
  }, [item]);



  const onClickHandler = (e) => {
    let props = {
      title: e.BrandName + " " + e.VehicleLineName + " / " + e.LicensePlate,
      type: "assistant",
      section: "vehicleMenu",
      make: 0,
      makeName: e.BrandName,
      vehicleLineId: 0,
      vehicleLineName: e.VehicleLineName,
      vehicleSeriesName: e.VehicleReferenceName,
      vehicleMenuName: "",
      vehicleSeriesId: e.VehicleReferenceId,
      source: "history",
      plate: e.LicensePlate

    }
    addTab(props, 'redirect')
  }



  return (
    <Skeleton active={true} loading={loading}>
      <div
        id="scrollableDiv"
        style={{
          height: "100%",
          overflow: 'auto',
          padding: '0 10px',
          width: '100%'
        }}
      >
        {historyList.slice(0, 50).map((item, key) => (
          <Spin spinning={item.loading ? true : false}>
            <List.Item key={key} onClick={() => onClickHandler(item)} className="history-list-item" >
              <List.Item.Meta
                avatar={item.LicensePlate && typeof item.LicensePlate != '' ?
                  <div style={{
                    display: "flex",
                    alignItems: "flex-start",
                    borderRadius: "3px",
                    background: "#FFD057",
                    minWidth: "86px",
                    justifyContent: "center"
                  }}>
                    {item?.LicensePlate?.replace(/-/g, '').match(/.{1,2}/g).join('-').toUpperCase()}
                  </div> : <div style={{
                    display: "flex",
                    alignItems: "flex-start",
                    borderRadius: "3px",
                    background: "#FFD057",
                    minWidth: "86px",
                    visibility: "hidden",
                    justifyContent: "center"
                  }}></div>
                }
                description={
                  <a onClick={onClickHandler}  >
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                      <span>{item.BrandName} - {item.VehicleLineName} </span>
                      <span>{item.VehicleReferenceName}</span>
                    </div>
                  </a>}
              />
            </List.Item>
          </Spin>
        ))}




        {/* {item ? <div className="mt-3">
        <div className="history">
          <div className="history__header">
            <div className="history__header__container">
              <div className="history__header__container__tag-1"></div>
            </div>
          </div>
          <div className="history__container cursor-pointer" onClick={() => onClick(item)}>
            <div className="history__image__container">
              <Image className="history__image__container__img" src={item?.Image} />
              {item.LicensePlate && typeof item.LicensePlate != '' ? <div className="mt-3">
                <Tag style={{ margin: "0", borderRadius: "0 !important" }} color={"#006ee0"}>
                  {"NL"}
                </Tag>
                <Tag id="tag-black-tag" color={"#ffc701"}>
                  {item?.LicensePlate?.toUpperCase()}
                </Tag>
              </div> : null}
            </div>

            <div className="history__container__description">
              <p className="font-medium text-[14px]"> {item.BrandName + " - " + item.VehicleLineName}</p>
              <p>{item.BodyType + " " + item.EngineType + " " + item.EngineContents}</p>
            </div>

            <div>
              <Image className="history__image__container__img mb-5" height={70} src={item.Logo} />
            </div>
          </div>
        </div>
      </div> : null}
 */}
      </div>
    </Skeleton>
  );
};

export default HistoryItem;
