import api from '../../services/api';
export default (note, reference) => {
    return new Promise((resolve, reject) => {
        const config = {
            url: '/webshop/basket/request',
            data: {
                Note: note,
                Reference: reference
              },
            method : "POST"
        };
        api()(config).then(result => {
            if (result.data.status == 'true') {
                resolve({ status: true, wholesaler : result.data.wholesaler })
            } else {
                reject({ status: false, message: result.data.message });

            }
        }).catch((e) => {
            reject({ status: false, message: e.response.data.message });
        })
    })
}