import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'


//Aktif tabları takip eder ve localstorage e kaydeder.

const useTabSaveLocalStorage = () => {

    const tabsStore = useSelector((e) => e.tabs)
    const tabs = tabsStore?.tabs || []
    const lastKey = tabsStore.lastKey
    useEffect(() => {
        try {
            let tabs = JSON.parse(localStorage.getItem("last-tabs"));
            tabs = tabs.slice(-5)
            localStorage.setItem('last-tabs', JSON.stringify(tabs))
        } catch (e) {

        }
    }, [tabs])

    return null;
}

export default useTabSaveLocalStorage