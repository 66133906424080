import api from '../../services/api'

export default (id) => new Promise((resolve, reject) =>
    api()({
        method: 'DELETE',
       
        url: '/webshop/tires-service/history/' + id
    }).then(e => {
        resolve(e)
    }).catch(f => {
        reject(f)
    })
) 