import fetchMakes from '@/core/vehicle/fetchVehicleMakes';
import { Card, Input, Row, Switch, Col, Checkbox } from 'antd';
import React, { useEffect, useState } from 'react'
import MakeItem from './VehicleMakeItem';
import { useTranslation } from 'react-i18next';

const MagnifyingIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="m14.454 15.75-5.09-5.098a5.534 5.534 0 0 1-3.455 1.184c-1.651-.001-3.049-.574-4.193-1.719C.572 8.971 0 7.571 0 5.917c0-1.653.572-3.053 1.716-4.2C2.86.574 4.258 0 5.91 0c1.652 0 3.05.573 4.194 1.718 1.143 1.146 1.715 2.546 1.715 4.2a5.556 5.556 0 0 1-1.182 3.459l5.114 5.12c.167.168.25.373.25.615a.87.87 0 0 1-.273.638.862.862 0 0 1-.636.25.862.862 0 0 1-.636-.25ZM5.91 10.014c1.136 0 2.103-.398 2.898-1.194C9.602 8.023 10 7.055 10 5.918c0-1.138-.398-2.106-1.193-2.903-.795-.796-1.762-1.194-2.898-1.194-1.136 0-2.102.398-2.898 1.194-.795.797-1.193 1.765-1.193 2.903 0 1.137.398 2.105 1.193 2.902.796.796 1.762 1.194 2.898 1.194Z"
      fill="#BCBCBC"
    />
  </svg>
);

const Makes = ({ onClick }) => {

  const [makes, setMakes] = useState([]);
  const [_makes, _setMakes] = useState([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [courant, setCourant] = useState(false);
  const [searchText, setSearchText] = useState("")
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true)
    fetchMakes()
      .then(e => {
        setMakes(e)
        _setMakes(e)
        setCourant(true)
      })
      .catch(data => {

      }).finally(() => setLoading(false))
  }, [])


  const onClickHandler = (CatalogMakeCode, Name, Photo) => {
    if (onClick)
      onClick(CatalogMakeCode, Name, Photo)
  }

  // useEffect(() => {
  //   if (courant) {
  //     setMakes(_makes.filter(item => item.IsMost))
  //   } else {
  //     setMakes(_makes)
  //   }
  // }, [courant])

  // useEffect(() => {
  //   if (searchText == "") {
  //     return setMakes(_makes);
  //   }
  //   else {
  //     let re = new RegExp(searchText, "i");
  //     const temp = [];
  //     let searchEl = []
  //     if (courant) {
  //       searchEl = _makes.filter(item => item.IsMost)
  //     } else {
  //       searchEl = _makes;
  //     }
  //     for (let i = 0; i < searchEl.length; i++) {
  //       if (searchEl[i].Name.search(re) != -1) {
  //         temp.push(searchEl[i]);
  //       }
  //     }
  //     setMakes(temp)
  //   }
  // }, [searchText])

  const onEnterSearchHandler = (e) => {
    if (makes.length == 0) return;

    if (e.key == 'Enter') {
      let item = makes[0];
      onClickHandler(item.CatalogMakeCode, item.Name, item.Photo)
    }
  }

  useEffect(() => {
    if (searchText === "") {
      setMakes(_makes);
    } else {
      const re = new RegExp(searchText, "i");
      const searchEl = courant ? _makes.filter(item => item.IsMost) : _makes;
      const temp = searchEl.filter(item => item.Name.search(re) !== -1);
      setMakes(temp);
    }
  }, [searchText, courant, _makes]);

  const filterMakes = (makes) => {
    let filteredMakes = makes;

    if (searchText !== "") {
      filteredMakes = makes.filter(item =>
        item.Name.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    if (courant) {
      filteredMakes = filteredMakes.filter(item => item.IsMost);
    }

    return filteredMakes;
  };

  return (
    <div>
      <div style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        position: "sticky",
        width: "100%",
        alignItems: "center",
      }}>
        <Input className='vehicle-search-input' onKeyDown={onEnterSearchHandler} size='large' placeholder={t("search")} value={searchText} onChange={(e) => setSearchText(e.target.value)} prefix={<MagnifyingIcon />} style={{
          width: '25%',
          height: "45px",
          padding: "10px !important",
          borderRadius: "12px",
          background: "#F8F8F8",
          marginRight: "10px",
        }} />

        {/* <Switch size='large' checkedChildren={t("courant")} unCheckedChildren={t("courant")} checked={courant} onChange={(e) => setCourant(e)} /> */}

        <Checkbox className='vehicle-checkbox' checked={courant} onChange={(e) => setCourant(e.target.checked)}>{t("courant")}</Checkbox>
      </div>
      <div>
        <Row loading={loading} style={{ marginTop: '10px' }}>
          {
            filterMakes(makes).map((make, key) => (
              <Col sm={12} xs={24} lg={2} style={{ marginTop: '15px' }}>
                <MakeItem onClick={() => onClickHandler(make.CatalogMakeCode, make.Name, make.Logo)} text={make.Name} image={make.Logo} key={key} make={make} />
              </Col>
            ))}
        </Row>
      </div>
    </div>
  )
}

export default Makes;
