import React, { useState } from 'react'
import api from '@/services/api'
const useDeleteCategory = () => {
    const [loading, setLoading] = useState(false)


    const deleteCategory = (id) => {

        setLoading(true)
        return api().delete(`/catalog/admin-category-list/${id}`).then(e => {
            setLoading(false)
            return e
        }).catch(f => {
            setLoading(false)
            throw f
        })


    }

    return {
        loading,
        deleteCategory
    }
}

export default useDeleteCategory