import { useEffect, useState, useCallback } from "react";
import { Button, Form, message, Checkbox, Typography } from "antd";
// import loginAction from "../../core/session/loginAction";
// import useSetAccount from "@/core/session/useSetAccount";
import { useDispatch } from "react-redux";
// import jwtDecode from "jwt-decode";
import { useTranslation } from "react-i18next";
import loginRequest from "@/core/login/loginRequest";
import { useSearchParams } from "react-router-dom";
import { setAccount } from "@/store/session";

import FloatInput from "../floatInput";

const Login = ({ onClickForgotPassword, onChangeEmail }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();

  // const setAccount = useSetAccount();
  const d = useDispatch();
  const { t } = useTranslation()
  //   const q = router.query
  // console.log(searchParams.get('email'))

  const login = useCallback(async (path) => {
    setLoading(true);

    const { Email, Password } = form.getFieldsValue();
    loginRequest(Email, Password).then(e => {

      const { status, message, token } = e
      if (status === 'false' || status === false) {
        throw new Error(message)
      }
      // let token = e.token;
      let decoded = (token)
      localStorage.setItem("x-access-token", token);
      d(setAccount(decoded));

      let rurl = "/"

      if (path && typeof path == 'string') {
        rurl = path
      }

      window.location.href = rurl


      // setTimeout(() => {
      //   if (path)
      //     navigate('/')
      //   else
      //     navigate('/')

      // }, 150)

    }).catch(e => {
      console.log(e);
      message.error(e?.message)
    }).finally(() => setLoading(false))
  }, [form, d]);

  useEffect(() => {

    const email = (searchParams.get('email'))
    const password = (searchParams.get('password'))
    const licensePlate = (searchParams.get('licensePlate'))

    if (email && password) {
      form.setFieldsValue({
        Email: email,
        Password: password
      })
      // let path = ""
      if (licensePlate) {
        return login('/?licensePlate=' + licensePlate)
      }
      login()
    }
  }, [searchParams, form, login])


  // const location = useLocation()
  // const navigate = useNavigate()



  return (
    <div className="login-form">
      {/* <p className="mt-5"></p> */}
      <Form layout="vertical" initialValues={{ remember: true }} form={form} onFinish={login}>
        <Form.Item className="login-form-field" rules={[{ required: true, message: "Please input your email!" }]} name="Email" type="email" >
          {/* <Input className="login-form-input" placeholder="Emaill Address" onChange={(e) => onChangeEmail(e.target.value)} /> */}
          <FloatInput label={t('web-email-address')} placeholder={t('web-email-address')} name="email" onChange={(e) => onChangeEmail(e.target.value)} />
        </Form.Item>

        <Form.Item className="login-form-field" type="password" rules={[{ required: true, message: "Please input your password!" }]} name="Password" >
          <FloatInput.Password label={t('password')} placeholder={t('password')} type="password" />
        </Form.Item>

        <Form.Item name="remember" valuePropName="checked" style={{ marginBottom: "10px" }}>
          <div className="flex justify-between">
            <Checkbox style={{ fontSize: "14px", color: "#9b9b9b" }}>{t('remember-me')}</Checkbox>
            <Typography.Text>
              <p onClick={onClickForgotPassword} style={{ cursor: "pointer", fontSize: "14px", color: "#9b9b9b" }}>
                {t('forgot-password')}
              </p>
            </Typography.Text>
          </div>
        </Form.Item>

        <Button loading={loading} className="login-form-button" type="primary" htmlType="submit">
          {t('login').toUpperCase()}
        </Button>
      </Form>
    </div>


    // <div >
    //   <p className="mt-5"></p>
    //   <Form layout="vertical" initialValues={{ remember: true }} form={form} onFinish={login}>
    //     <Form.Item rules={[{ required: true, message: "Please input your email!" }]} label="E-Mail" name="Email" type="email" style={{ width: "100%" }}>
    //       <Input onChange={(e) => onChangeEmail(e.target.value)} style={{ width: "100%" }} />
    //     </Form.Item>
    //     <Form.Item type="password" label="Password" rules={[{ required: true, message: "Please input your password!" }]} name="Password">
    //       <Input.Password />
    //     </Form.Item>

    //     <div>
    //       <Form.Item name="remember" valuePropName="checked">
    //         <div className="flex justify-between items-center">
    //           <Checkbox>{t('remember-me')}</Checkbox>
    //           <Typography.Text>
    //             <p onClick={onClickForgotPassword} className="font-medium pt-3" style={{ cursor: "pointer" }}>
    //               {t('forgot-password')}
    //             </p>
    //           </Typography.Text>
    //         </div>
    //       </Form.Item>
    //     </div>

    //     <Form.Item>
    //       <Button loading={loading} className="rounded-[15px] w-full" type="primary" htmlType="submit">
    //         {('login')}
    //       </Button>
    //     </Form.Item>


    //     <h1 onClick={() => ('/')} className="text-[15px] text-medium cursor-pointer">{t('return-to-home-page')}</h1>


    //   </Form>
    // </div>
  );
};

export default Login;
