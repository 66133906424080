import AccountTitle from "@/layouts/AccountLayout/AccountTitle";
import AccountUpdate from '@/components/account/AccountUpdate'
import { Card } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

const ProfilePage = () => {

    const {t} = useTranslation()

    return (
        <div>

            <AccountTitle title={t('profil')}></AccountTitle>
            <Card>
                <AccountUpdate />
            </Card>
        </div>
    )
}

export default ProfilePage