import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  menuIconColor : "",
  Address : "",
  Email : "",
  FooterText : "",
  Phone : "",
  Whatsapp : "",
  Language : "",
  showSearch : false,
  siteGeneralLoading : false,
  settings : "",
  pstoken: process.env.NEXT_PUBLIC_PS_TOKEN,
  loadErrorPage : false,
  WebshopLicenseId : null,
  isLoadingSettings : false,
  "base-logo" : "",
  "border-radius-base-logo" : 0,
  FooterLocation : "",
  WorkingHours : "",
  NewsletterBottom : "",
  developerMode : false,
  FooterBottomTextColor : "",
  FooterBottomBgColor : "",
  fetch : false,
  "hideNetPrice" : false,
  "languageList" : [],
  "timezone" : "Europe/Amsterdam"
  

}

export const settings = createSlice({
    name: 'settings',
    initialState,
    reducers: {
      setSiteSettings: (state,action) => {
        state[action.payload.type] = action.payload.value;
      },
      setLanguage : (state,action)=>{
        state.Language = action.payload;
      },
      setShowClassicSearch : (state,action)=>{
        state.showClassicSearch = action.payload;
      },
      setSiteGeneralLoading : (state,action)=>{
        state.siteGeneralLoading = action.payload;
      },
      setPsToken: (state, action) => {
        state.pstoken = action.payload;
      },
      setLoadErrorPage : (state,action) => {
        state.loadErrorPage = action.payload
      },
      setWebshopLicenseId : (state,action) =>{
        state.WebshopLicenseId = action.payload;
      },
      setDeveloperMode : (state,action) => {
        state.developerMode = action.payload
      },

      showSearch : (state,action) => {
        state.showSearch = action.payload
      },
      setHideNetPrice : (state,action) => {
        state.hideNetPrice = action.payload
      },
      setLanguageList : (state,action) => {
        state.languageList = action.payload
      },
      setTimezone: (state,action) => {
        state.timezone = action.payload
      }
      
    
      
      
    },
  })
  
  // Action creators are generated for each case reducer function
  export const { setTimezone,setLanguageList,showSearch,setDeveloperMode,setSiteSettings,setLanguage ,setShowClassicSearch,setSiteGeneralLoading,setPsToken,setLoadErrorPage,setWebshopLicenseId,setHideNetPrice} = settings.actions
  
  export default settings.reducer
  
