import api from '../../services/api';
export default (ProductList) => {
    return new Promise((resolve, reject) => {
            
            let url = `/webshop/price-service/v2/multiple-price-rowId`
            api()({
                url,
                data: {
                    ProductList
                   
                },
                method: 'POST'
            }).then((e) => {
                resolve(e.data);
            }).catch((e) => reject(e))
        })

}

