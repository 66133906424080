import { Button, Card, Col, Divider, Form, Input, Radio, Result, Row, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Icon from '@ant-design/icons'
import { ReloadOutlined, CheckCircleTwoTone, CloseCircleOutlined } from '@ant-design/icons'
import api from '@/services/api'
import { ValidatorFactory } from '@randock/license-plate-validator';
import VehicleInfo from '../assistant/Vehicle/VehicleInfo'
import searchVehicleFromPlate from '@/core/vehicle/searchVehicleFromPlate'
const validator = ValidatorFactory.forCountry('NL');


let wrapperCols = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

const RadioOptionsWithIcon = ({ Icon, text }) => {

  return <div className='flex items-center justify-between'>
    <Icon />
    <span className='ml-2'>{text}</span>
  </div>

}


const VehicleSvg = () => <svg
  fill="#000000"
  width="24px"
  height="24px"
  viewBox="0 -15.37 48.447 48.447"
  xmlns="http://www.w3.org/2000/svg"
>
  <g id="Car" transform="translate(-439.053 -381.9)">
    <circle
      id="Ellipse_3"
      data-name="Ellipse 3"
      cx="3.2"
      cy="3.2"
      r="3.2"
      transform="translate(445.2 393.1)"
    />
    <circle
      id="Ellipse_4"
      data-name="Ellipse 4"
      cx="3.2"
      cy="3.2"
      r="3.2"
      transform="translate(474.6 393.2)"
    />
    <path
      id="Path_68"
      data-name="Path 68"
      d="M487.3,392.2c-.1.2-.2.4-.5.4h-1.1a.5.5,0,0,1,0-1h1.2a8.568,8.568,0,0,1-.3-1.3.894.894,0,0,1-.8.4.713.713,0,0,1-.8-.7v-.4a.773.773,0,0,1,.8-.7.67.67,0,0,1,.7.4,7.084,7.084,0,0,1,.1-1.4l-5.7-.8s-4-5.2-14.8-5.2c-6,0-11.2,2.5-15.1,4.7a26.512,26.512,0,0,1-2.4,1.2,22.676,22.676,0,0,1-3.4,1.1s-4.6.8-5.6,2.9a.1.1,0,0,1-.1.1H441a.5.5,0,0,1,0,1h-1.5c-.1,0-.2-.1-.3-.1a4.215,4.215,0,0,0,.4,3.2l3.4.4h1.2l.3-.1a3.8,3.8,0,0,1,7.6,0L474,396h0a3.818,3.818,0,0,1,7.6-.1c1.6-.1,5.5-.5,5.7-2.3l.2-1.2C487.5,392.3,487.4,392.3,487.3,392.2Zm-22.6-4.6v.1h-12s6.3-3.8,12.9-3.9Zm11.2.1H465.6l.9-3.8v-.1c5.7,0,7.7.9,9.7,2.2Z"
    />
  </g>
</svg>


const MotorSvg = () => <svg
  fill="#000000"
  version="1.1"
  id="Capa_1"
  xmlns="http://www.w3.org/2000/svg"
  xmlnsXlink="http://www.w3.org/1999/xlink"
  width="24px"
  height="24px"
  viewBox="0 0 358.945 358.945"
  xmlSpace="preserve"
>
  <g>
    <g>
      <g>
        <path
          d="M307.633,172.984c-6.389,0-12.61,1.174-18.524,3.479l-2.822-4.597l33.765-4.5c0.456-0.063,11.241-1.459,12.688-9.508
    c2.558-14.259-27.574-37.293-92.126-70.442c-5.915-2.747-10.227-4.086-13.181-4.086c-3.524,0-4.857,1.892-5.338,3.005
    c-2.606,6.008,9.121,21.804,20.645,35.245c-12.677-6.737-33.339-15.783-52.885-15.783c-9.833,0-18.417,2.306-25.517,6.854
    c-5.626,3.591-12.784,13.06-21.344,28.138c-0.375-0.597-0.987-1.015-1.684-1.132l-50.752-8.983l-7.071-21.227
    c-0.282-0.864-1.009-1.486-1.907-1.672c-0.973-0.184-24.085-4.666-44.883-4.666c-22.902,0-35.218,5.338-36.62,15.853
    c-3.278,24.761,99.893,57.601,121.84,64.294c-5.134,11.463-9.206,21.227-11.334,26.469c-6.395-21.432-26.667-36.74-49.146-36.74
    c-28.286,0-51.314,23.031-51.314,51.332c0,28.288,23.028,51.299,51.314,51.299c22.638,0,42.763-15.084,49.164-36.756h121.27
    c0.823,0,1.615-0.414,2.078-1.099l37.308-54.812l1.999,3.255c-10.778,9.733-16.939,23.574-16.939,38.106
    c0,28.294,23.022,51.299,51.317,51.299s51.312-23.005,51.312-51.299C358.945,196.016,335.921,172.984,307.633,172.984z
     M292.639,132.17c0.985-1.36,2.9-2.054,5.717-2.054c1.934,0,4.257,0.324,6.917,0.981c20.903,15.165,23.089,22.71,22.536,25.875
    c-0.78,4.398-8.305,5.419-8.395,5.425l-16.213,2.165C297.557,155.669,288.466,138.072,292.639,132.17z M93.274,219.038
    c-0.459,0.589-1.198,0.942-1.96,0.942H54.924v13.859h34.735c0.834,0,1.625,0.414,2.083,1.135c0.469,0.696,0.556,1.598,0.21,2.359
    c-5.233,12.244-17.219,20.158-30.522,20.158c-18.306,0-33.194-14.892-33.194-33.176c0-18.32,14.889-33.201,33.194-33.201
    c15.574,0,28.85,10.617,32.33,25.797C93.938,217.669,93.76,218.443,93.274,219.038z M307.633,257.492
    c-18.297,0-33.183-14.892-33.183-33.182c0-8.972,3.531-17.391,9.968-23.695c0.559-0.553,1.321-0.841,2.108-0.703
    c0.708,0.091,1.387,0.523,1.789,1.172l14.352,23.322l7.302-4.491l-14.346-23.323c-0.384-0.637-0.48-1.435-0.228-2.161
    c0.258-0.721,0.834-1.285,1.555-1.525c3.482-1.189,7.08-1.802,10.688-1.802c18.291,0,33.183,14.893,33.183,33.201
    C340.81,242.601,325.917,257.492,307.633,257.492z"
        />
      </g>
    </g>
  </g>
</svg>


const TaxiSvg = () => <svg
  version="1.1"
  id="_x32_"
  xmlns="http://www.w3.org/2000/svg"
  xmlnsXlink="http://www.w3.org/1999/xlink"
  width="24px"
  height="24px"
  viewBox="0 0 512 512"
  xmlSpace="preserve"
>
  <style
    type="text/css"
    dangerouslySetInnerHTML={{ __html: "\n\n\t.st0{fill:#000000;}\n\n" }}
  />
  <g>
    <path
      className="st0"
      d="M492.797,262.25h-22.109c-10.563,0-23.313-7.594-28.375-16.875l-36.406-67.094
  c-5.031-9.281-17.813-16.891-28.375-16.891H206.625c-10.563,0-24.5,6.828-30.953,15.203l-54.328,70.438
  c-6.469,8.375-20.391,15.219-30.938,15.219H60.531c-33.313,0-53.813,15.875-58.609,47.906L0,343.891
  c0,10.578,8.656,19.234,19.219,19.234H66.5c2.344,26.969,25.031,48.188,52.625,48.188c27.563,0,50.266-21.219,52.609-48.188
  h186.172c2.313,23.813,22.406,42.438,46.844,42.438s44.531-18.625,46.844-42.438h41.203c10.547,0,19.203-8.656,19.203-19.234
  v-62.422C512,270.891,503.344,262.25,492.797,262.25z M119.125,382.031c-13,0-23.547-10.531-23.547-23.531
  s10.547-23.531,23.547-23.531s23.531,10.531,23.531,23.531S132.125,382.031,119.125,382.031z M291.063,261.375H152.125l7.219-9.375
  l44.375-57.531c3.031-3.906,11.453-8.063,16.406-8.063h70.938V261.375z M314.125,261.375v-74.969h53.844
  c4.031,0,10.578,3.906,12.516,7.469l34.594,67.5H314.125z M404.75,382.031c-13,0-23.531-10.531-23.531-23.531
  s10.531-23.531,23.531-23.531s23.531,10.531,23.531,23.531S417.75,382.031,404.75,382.031z"
    />
    <path
      className="st0"
      d="M225.859,122.844c0.016-6.219,5.063-11.281,11.281-11.281h105.25c6.234,0,11.297,5.063,11.297,11.281v30.5
  h10.875v-30.5c0-12.234-9.922-22.156-22.172-22.156h-105.25c-12.234,0-22.156,9.922-22.172,22.156v30.5h10.891V122.844z"
    />
    <path
      className="st0"
      d="M249.188,149.938h5.531c0.266,0,0.438-0.156,0.438-0.406v-22.297c0-0.172,0.078-0.234,0.25-0.234h7.484
  c0.266,0,0.422-0.188,0.422-0.438v-4.625c0-0.25-0.156-0.438-0.422-0.438h-21.859c-0.281,0-0.438,0.188-0.438,0.438v4.625
  c0,0.25,0.156,0.438,0.438,0.438h7.469c0.172,0,0.266,0.063,0.266,0.234v22.297C248.766,149.781,248.938,149.938,249.188,149.938z"
    />
    <path
      className="st0"
      d="M275.422,121.5c-0.313,0-0.484,0.188-0.563,0.438l-10.172,27.594c-0.094,0.25,0,0.406,0.297,0.406h5.703
  c0.281,0,0.469-0.125,0.563-0.406l1.656-5h10.344l1.688,5c0.094,0.281,0.266,0.406,0.578,0.406h5.641
  c0.313,0,0.391-0.156,0.313-0.406l-10-27.594c-0.094-0.25-0.266-0.438-0.563-0.438H275.422z M281.516,139.313h-6.828l3.344-9.938
  h0.125L281.516,139.313z"
    />
    <path
      className="st0"
      d="M295.516,149.938h6.016c0.375,0,0.563-0.125,0.734-0.406l5.297-8.656h0.125l5.266,8.656
  c0.172,0.281,0.359,0.406,0.734,0.406h6.063c0.281,0,0.375-0.25,0.25-0.5l-8.875-14.172L319.391,122
  c0.125-0.25,0.031-0.5-0.266-0.5h-6.031c-0.359,0-0.531,0.125-0.719,0.438l-4.688,7.688h-0.125l-4.688-7.688
  c-0.188-0.313-0.359-0.438-0.719-0.438h-6.031c-0.313,0-0.391,0.25-0.25,0.5l8.219,13.266l-8.828,14.172
  C295.094,149.688,295.219,149.938,295.516,149.938z"
    />
    <path
      className="st0"
      d="M326.875,121.938v27.594c0,0.25,0.188,0.406,0.438,0.406h5.531c0.25,0,0.438-0.156,0.438-0.406v-27.594
  c0-0.25-0.188-0.438-0.438-0.438h-5.531C327.063,121.5,326.875,121.688,326.875,121.938z"
    />
  </g>
</svg>




const MopedSvg = () => <svg
  height="24px"
  width="24px"
  version="1.1"
  id="_x32_"
  xmlns="http://www.w3.org/2000/svg"
  xmlnsXlink="http://www.w3.org/1999/xlink"
  viewBox="0 0 512 512"
  xmlSpace="preserve"
>
  <style
    type="text/css"
    dangerouslySetInnerHTML={{ __html: "\n\t.st0{fill:#000000;}\n" }}
  />
  <g>
    <polygon
      className="st0"
      points="168.548,269.165 196.66,275.239 213.052,199.29 184.94,193.216 	"
    />
    <path
      className="st0"
      d="M449.358,358.86c2.01,3.679,3.164,7.933,3.164,12.485c0,14.492-11.735,26.224-26.236,26.224
c-14.496,0-26.232-11.732-26.232-26.224c0-4.552,1.153-8.806,3.165-12.485h-32.157c-3.284,0-6.444,0.515-9.433,1.44
c-0.578,3.567-0.922,7.247-0.922,11.046c0,36.239,29.336,65.583,65.579,65.583c36.247,0,65.583-29.344,65.583-65.583
c0-4.262-0.402-8.464-1.205-12.485H449.358z"
    />
    <path
      className="st0"
      d="M103.55,358.86h-2.818c2.015,3.679,3.161,7.933,3.161,12.485c0,14.492-11.735,26.224-26.228,26.224
c-14.497,0-26.232-11.732-26.232-26.224c0-4.552,1.146-8.806,3.164-12.485h-41.31c-0.806,4.022-1.209,8.223-1.209,12.485
c0,36.239,29.344,65.583,65.587,65.583c31.694,0,58.161-22.434,64.258-52.359v-4.597C141.924,360.412,112.524,358.86,103.55,358.86
z"
    />
    <path
      className="st0"
      d="M512,347.374c0,0,0.008-101.643-67.113-101.643H304.564c1.877,4.418,4.53,8.47,8.008,11.515
c15.336,13.426,26.847,61.359-5.754,70.949H176.416l-24.926-24.926l36.434-168.757h57.333c5.403,0,9.784-4.38,9.784-9.783v-9.963
c0-5.404-4.381-9.784-9.784-9.784h-51.583c0,0-15.336-29.911-84.371-29.911v63.285h32.221l-1.541,9.582L97.8,236.149
c0,0-97.8,23.008-97.8,111.225c0,0,80.539,0,103.55,0c23.012,0,49.863,7.672,49.863,32.598h174.991l1.134-3.224
c6.213-17.597,22.855-29.374,41.524-29.374H512z"
    />
    <path
      className="st0"
      d="M444.887,232.313v-11.687c0-12.604-10.22-22.821-22.832-22.821H314.486c-13.343,0-16.325,19.463-9.922,34.508
H444.887z"
    />
  </g>
</svg>

const SnorfietsSvg = () => <svg
  fill="#000000"
  width="24px"
  height="24px"
  viewBox="0 0 80 80"
  data-name="Layer 1"
  id="Layer_1"
  xmlns="http://www.w3.org/2000/svg"
>
  <title />
  <path d="M66,46a9,9,0,1,0,9,9A9,9,0,0,0,66,46Zm0,16a7,7,0,1,1,7-7A7,7,0,0,1,66,62Zm1-27a11.53,11.53,0,0,0-8.21,3.48l-.57-6.88A7,7,0,0,0,63,33.5a1,1,0,0,0,1-1v-12a1,1,0,0,0-1-1,7,7,0,0,0-5.56,2.76L57,16.92A1,1,0,0,0,56,16H47a1,1,0,0,0,0,2h8.08L57,40.69,46.51,54H35V32a1,1,0,0,0-1-1H16.76A3.77,3.77,0,0,0,13,34.76v2.59A19,19,0,0,0,1,55a1,1,0,0,0,1,1H9.06a9,9,0,0,0,17.88,0H47a1,1,0,0,0,.79-.38L58.73,41.69s0-.06.05-.08l0,0A9.78,9.78,0,0,1,67,37,10,10,0,0,1,77,47a1,1,0,0,0,2,0A12,12,0,0,0,67,35ZM18,62a7,7,0,1,1,7-7A7,7,0,0,1,18,62Zm15-8H26.94A9,9,0,0,0,9.06,54H3A17,17,0,0,1,20,38H33Zm0-18H20a18.8,18.8,0,0,0-5,.69V34.76A1.76,1.76,0,0,1,16.76,33H33ZM62,21.6v9.8a5,5,0,0,1,0-9.8Z" />
</svg>


const TaxiIcon = () => <Icon component={TaxiSvg} />
const VehicleIcon = () => <Icon component={VehicleSvg} />
const MotorIcon = () => <Icon component={MotorSvg} />
const MopedIcon = () => <Icon component={MopedSvg} />
const SnorfietsIcon = () => <Icon component={SnorfietsSvg} />




const useCreateLicensePlateOrder = () => {
  const [loading, setLoading] = useState(false)
  const [orderId, setOrderId] = useState(null)
  const create = async (data) => {
    setOrderId(null)
    setLoading(true)
    try {
      let result = await api().post('/webshop/plate-order',
        data
      )

      setOrderId(result.data.orderId)
    } catch (e) {
      return e?.response?.data?.message || "Something went wrong"
    } finally {

      setLoading(false)
    }


  }


  return { loading, create, orderId, setOrderId }

}


const LicensePlateOrder = ({ props }) => {
  const { t } = useTranslation()
  const create = useCreateLicensePlateOrder();

  const [licensePlate, setLicensePlate] = useState(null);
  const [isValidate, setIsValidate] = useState(false);




  useEffect(() => {
    if (!licensePlate) {
      setIsValidate(false)
      return
    }


    let validate = validator.validate(licensePlate)



    setIsValidate(validate)
  }, [licensePlate])



  const optionsWithVehicleType = [
    {
      label: <RadioOptionsWithIcon Icon={VehicleIcon} text={t('auto')} />,
      value: 'auto',
    },
    {
      label: <RadioOptionsWithIcon Icon={MotorIcon} text={t('motor')} />,
      value: 'motor',
    },
    {
      label: <RadioOptionsWithIcon Icon={TaxiIcon} text={t('taxi')} />,
      value: 'taxi',
    },
    {
      label: <RadioOptionsWithIcon Icon={MopedIcon} text={t('moped')} />,
      value: 'moped',
    },
    {
      label: <RadioOptionsWithIcon Icon={SnorfietsIcon} text={t('snorfiets')} />,
      value: 'snorfiets',
    },
  ];

  const optionsColor = [
    {
      label: t('yellow'),
      value: 'yellow',
    },
    {
      label: t('white'),
      value: 'white',
    },
  ];


  const optionsForRequest = [
    {
      label: t('exchange'),
      value: 'exchange',
    },
    {
      label: t('missing'),
      value: 'missing',
    },
    {
      label: t('first-issue'),
      value: 'first-issue',
    },
  ];


  const optionsSize = [
    {
      label: t('pressed'),
      value: 'pressed',
    },
    {
      label: t('luxury'),
      value: 'luxury',
    }
  ];

  const optionsFormat = [
    {
      label: t('long'),
      value: 'long',
    },
    {
      label: t('square'),
      value: 'square',
    }
  ]





  const [form] = Form.useForm();

  const onFinish = () => {
    let values = (form.getFieldsValue())


    create.create(values)
  }


  const onClickReset = () => {
    create.setOrderId(null)
    form.resetFields()
    setLicensePlate(null)
  }


  if (create.orderId) {
    return (<Card>
      <Result status="success" title="Successfully Order!" subTitle={`Order Number : ${create.orderId}`} extra={[
        <Button onClick={() => {
          onClickReset()
        }} type="primary" key="console">
          {t('reset')}
        </Button>,

      ]} />
    </Card>)
  }



  return (
    <Card>

      {licensePlate && isValidate && <div className='mt-5'>
        <VehicleInfo Plate={licensePlate} />
      </div>}

      <Spin spinning={create.loading}>
        <div className='flex justify-end'>
          <Button onClick={onClickReset} type='primary' icon={<ReloadOutlined />} />
        </div>
        <Divider orientation='left'>{t('license-plate-order')}</Divider>

        <Row>
          <Col lg={24} md={24} sm={24} xs={24}>
            <Form labelAlign='left' form={form} onFinish={onFinish}>


              <Form.Item
                {...wrapperCols}

                label={t('license-plate')}
                name="licensePlate"
                rules={[
                  {
                    required: true,
                    message: 'Please input your licensePlate!',
                  },
                ]}>
                <div className='flex items-center'>
                  <Input value={licensePlate?.toUpperCase()} onChange={e => setLicensePlate(e.target.value)} style={{
                    width: '200px'
                  }} placeholder="19FZDV" />
                  <div className='ml-2'>

                    {isValidate ? <CheckCircleTwoTone twoToneColor="#52c41a" style={{
                      fontSize: '24px'
                    }} /> :
                      <CloseCircleOutlined style={{
                        fontSize: '24px',
                        color: '#DC3545'
                      }} />
                    }



                  </div>
                </div>
              </Form.Item>


              <Form.Item label={t('vehicle-type')}
                             {...wrapperCols}

                name="vehicleType"
                rules={[
                  {
                    required: true,
                    message: 'Please input your vehicleType!',
                  },
                ]}>
                <Radio.Group
                  options={optionsWithVehicleType}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Form.Item>





              <Form.Item label={t('color')}
                {...wrapperCols}
                name="color"
                rules={[
                  {
                    required: true,
                    message: 'Please select color!',
                  },
                ]}>
                <Radio.Group
                  options={optionsColor}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Form.Item>


              <Form.Item label={t('type')}
                {...wrapperCols}
                name="type"
                rules={[
                  {
                    required: true,
                    message: 'Please input your username!',
                  },
                ]}>
                <Radio.Group
                  options={optionsSize}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Form.Item>


              <Form.Item label={t('format')}
                {...wrapperCols}
                name="format"
                rules={[
                  {
                    required: true,
                    message: 'Please select your report code!',
                  },
                ]}>
                <Radio.Group
                  options={optionsFormat}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Form.Item>


              <Form.Item label={t('reason-request')}
                {...wrapperCols}
                name="reasonRequest"
                rules={[
                  {
                    required: true,
                    message: 'Please select your reason request!',
                  },
                ]}>
                <Radio.Group
                  options={optionsForRequest}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Form.Item>


              <Form.Item
                {...wrapperCols}
                label={t('duplicateCode')}
                name="duplicateCode"
                rules={[
                  {
                    required: true,
                    message: 'Please input your duplicateCode!',
                  },
                ]}>
                <Input style={{
                  width: '50px'
                }} placeholder={t('2')} />
              </Form.Item>


              <Form.Item
                {...wrapperCols}

                label={t('document-number')}
                name="documentNumber"

                rules={[
                  {
                    required: true,
                    message: 'Please input your document number!',
                  },
                ]}>
                <Input style={{
                  width: '200px'
                }} placeholder={t('2354234')} />
              </Form.Item>




              <Form.Item label={t('identification number')}
                {...wrapperCols}
                name="identificationNumber"
                rules={[
                  {
                    required: true,
                    message: 'Please input your username!',
                  },
                ]}>
                <Input style={{
                  width: '200px'
                }} placeholder={t('4854542354234')} />
              </Form.Item>



              <Form.Item
                {...wrapperCols}
              >
                <Button type="primary" htmlType="submit">
                  {t('order')}
                </Button>
              </Form.Item>




            </Form>
          </Col>

          {/* <Col lg={12} md={12} sm={24} xs={24}>
            <div className='flex items-center justify-center flex-col'>
              <div className="kenteken"
                style={
                  {
                    position: 'relative',
                    background: '#f3bd00',
                    padding: '3px',
                    borderRadius: '5px',
                    boxShadow: '0 0 6px rgb(0 0 0 / 30%)'
                  }
                }
              >
                <div
                  style={{
                    borderRadius: '4px',
                    width: 'auto',
                    height: '100%',
                    position: 'relative',
                    display: 'flex'
                  }}
                  className="inset">
                  <div className="blue"></div>
                  <input value={licensePlate} type="text" placeholder="" onChange={(e) => setLicensePlate(e.target.value)} />
                </div>
              </div>





            </div>
          </Col> */}

        </Row>

      </Spin>

    </Card>
  )
}

export default LicensePlateOrder
