import React, { useEffect, useState } from "react";
import { Card, DatePicker, Form, Input, message, Modal, Select, Table, Tag } from "antd";
import { DownloadOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import moment from 'moment'
import transactions from "@/core/transactions/transactions";
import ShowDocument from "@/components/invoices/ShowDocument";
import { useTranslation } from "react-i18next";
import NumberRound from "number-zero-round";

const { RangePicker } = DatePicker;
const { Option } = Select


const GREEN_COLOR = '#D4F4D9'
const RED_COLOR = '#FADEE3'

const PackingSlipTable = () => {


    const [selectedDocumentNumber, setSelectedDocumentNumber] = useState(null)
    const [showDocumentModal, setShowDocumentModal] = useState(false)
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [form] = Form.useForm();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [documentNumber, setDocumentNumber] = useState(null);
    const onChangePerSizeHandler = (e) => {
        setPageSize(e)
        fetch({
            page: 1,
            limit: e
        })

    }





    const { t } = useTranslation();

    


    const onFinish = () => {
        let StartDate = startDate ? startDate.format('YYYY-MM-DD HH:mm:ss') : null
        let EndDate = endDate ? endDate.format('YYYY-MM-DD HH:mm:ss') : null
        let DocumentNumber = documentNumber ? documentNumber : null


        let params = {}

        if ((StartDate || EndDate || DocumentNumber)) {
            //page i sıfırla
            setPage(1)
            params = {
                ...params,
                currPage: 1,
                startDate: StartDate,
                endDate: EndDate,
                documentNumber: DocumentNumber
            }



        } else {
            if (StartDate) {
                params = {
                    ...params,
                    startDate: StartDate
                }
            }

            if (EndDate) {
                params = {
                    ...params,
                    endDate: EndDate
                }
            }

            if (DocumentNumber) {
                params = {
                    ...params,
                    documentNumber: DocumentNumber
                }
            }
        }

        if (!params.startDate)
            delete params.startDate
        if (!params.endDate)
            delete params.endDate
        if (!params.documentNumber)
            delete params.documentNumber

        params.limit = pageSize
        fetch(params)
    }





    const columns = [
        {
            title: t('invoice-number'),
            dataIndex: "DocumentNumber",
            key: "DocumentNumber",
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.DocumentNumber - b.DocumentNumber,
        },
        {
            title: t('invoice-date'),
            dataIndex: "DocumentDate",
            key: "DocumentDate",
            sorter: (a, b) => moment(a.DocumentDate).unix() - moment(b.DocumentDate).unix(),
            render: (a, b) => moment(a).format('DD/MM/YYYY')
        },
        {
            title: t('total-net-price'),
            dataIndex: "TotalNetPrice",
            key: "TotalNetPrice",
            sorter: (a, b) => a.TotalNetPrice - b.TotalNetPrice,
            render: (a, b) => '€' + NumberRound(a)


        },
        {
            title: t('total-amount'),
            dataIndex: "TotalAmount",
            key: "TotalAmount",
            sorter: (a, b) => a.TotalAmount - b.TotalAmount,
            render: (a, b) => '€' + NumberRound(a)


        },

        {
            title: t('status'),
            dataIndex: "Open",
            key: "Open",
            sorter: (a, b) => a?.Status - b?.Status,

            render: (a, b) => {

                return (<div>
                    {Number(b.Status) == 0 ? <div className="flex justify-center items-center">{t('close')}</div> : <div className="flex justify-center items-center">{t('open')}</div>}
                </div>)
            }
        },

        {
            title: t('old-days'),
            dataIndex: "",
            key: "",
            render: (a, b) => {

                return (<div className="text-center" >{b.Status == 0 ? " - " : b.OldDays}</div>)
            }
        },

        {
            title: "",
            dataIndex: "",
            key: "download",
            render: (a, b) => {
                return (
                    <div onClick={() => {
                        setSelectedDocumentNumber(b.DocumentNumber)
                        setShowDocumentModal(true)
                    }}>
                        <DownloadOutlined style={{ fontSize: "19px", color: "var(--primaryColor)", cursor: "pointer" }} />
                    </div>
                );
            },
        },
    ];


    const [data, setData] = useState([])
    const [totalCount, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(false)
    const fetch = (params) => {
        setLoading(true)
        transactions.fetchSlips({
            page: params?.currPage || page,
            limit: params?.limit || pageSize,
            ...params
        }).then(e => {
            const _data = e.data;
            setData(_data.data)
        }).catch(f => {
            message.error(f?.response?.data?.message || "Failed")
        }).finally(() => setLoading(false))
    }

    useEffect(() => {
        fetch()
    }, [])

    return (
        <div>
            <Modal bodyStyle={{ height: '1000px', padding: 0 }} open={showDocumentModal} footer={null} width="100%" onCancel={() => setShowDocumentModal(false)}>
                <ShowDocument documentNumber={selectedDocumentNumber} type={2} />
            </Modal>

            <Form form={form} layout="vertical">
                <Form.Item name="invoiceNumber" label={t('invoice-number')}>
                    <Input value={documentNumber} onChange={e => setDocumentNumber(e.target.value)} />
                </Form.Item>

                <style>{"\
        .td.ant-table-column-sort{\
          background:inherit !important;\
        }\
      "}</style>

                <Form.Item label={t('date')} name={t('date')} >
                    <RangePicker  onChange={(e) => {
                        if (e) {
                            setStartDate(e[0])
                            setEndDate(e[1])
                        } else {
                            setStartDate(null)
                            setEndDate(null)
                        }
                    }} showTime />
                </Form.Item>
                <Form.Item >
                    <button onClick={onFinish} className="mega-button" >{t('search')}</button>
                </Form.Item>

            </Form >
            <Table pagination={false} summary={pageData => {
                let totalBorrow = 0;
                let totalRepayment = 0;

                pageData.forEach(({ borrow, repayment }) => {
                    totalBorrow += borrow;
                    totalRepayment += repayment;
                });

                return (
                    <>
                        <Table.Summary.Row style={{
                            backgroundColor: "#F2F5F6",
                        }} >
                            <Table.Summary.Cell>Total</Table.Summary.Cell>
                            <Table.Summary.Cell></Table.Summary.Cell>
                            <Table.Summary.Cell ><Tag color={'blue'}>{NumberRound(data.reduce(
                                (accumulator, currentValue) => accumulator + parseFloat(currentValue?.TotalNetPrice),
                                0
                            ))}</Tag></Table.Summary.Cell>
                            <Table.Summary.Cell  ><Tag color={'orange'}>{NumberRound(data.reduce(
                                (accumulator, currentValue) => accumulator + parseFloat(currentValue?.TotalAmount),
                                0
                            ))}</Tag></Table.Summary.Cell>
                            <Table.Summary.Cell></Table.Summary.Cell>
                            <Table.Summary.Cell></Table.Summary.Cell>
                            <Table.Summary.Cell></Table.Summary.Cell>
                            <Table.Summary.Cell></Table.Summary.Cell>


                        </Table.Summary.Row>

                    </>
                );
            }}
                title={() =>
                    <div>
                        <span>{t('show-per-page')}:</span>
                        <Select style={{
                            width: 200,
                            marginLeft: '10px'
                        }} className="oks" value={pageSize} onChange={onChangePerSizeHandler}>
                            <Option value={10}>10</Option>
                            <Option value={50}>50</Option>
                            <Option value={100}>100</Option>
                            <Option value={500}>500</Option>

                        </Select>
                    </div>} size="small" loading={loading} dataSource={data}

                onRow={(record, index) => ({
                    style: {
                        background: record?.Status == 1 ? RED_COLOR : GREEN_COLOR
                    }
                })}


                className="table-2" columns={columns} />
        </div >
    );
};

export default PackingSlipTable;