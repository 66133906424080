import useAddTab from '@/hooks/assistant/useAddTab';
import HistoryItem from './HistoryItem';
import { getHistoryList } from '@/store/history';
import { Col, Row } from 'antd';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';

const HistorySection = ({column=12,perPage=50}) => {
    const historyList = useSelector((e) => e.history.historyList);
    const d = useDispatch();
    const addTab = useAddTab();
    useEffect(() => {
        d(getHistoryList());
    }, [])


    const onClickHandler = (e) => {
        let props = {
            title: e.BrandName + " "  + e.VehicleLineName + " / " + e.LicensePlate,
            type : "assistant",
            section : "vehicleMenu",
            make : 0,
            makeName : e.BrandName,
            vehicleLineId : 0,
            vehicleLineName : e.VehicleLineName,
            vehicleSeriesName : e.VehicleReferenceName,
            vehicleMenuName : "", 
            vehicleSeriesId : e.VehicleReferenceId,
            source : "history",
            plate : e.LicensePlate

        }
        addTab(props,'redirect')
        
    }

    return (
        <Row gutter={16} >{historyList.slice(0,perPage).map((item, key) => (
            <Col lg={column}>
                <HistoryItem key={key} onClick={onClickHandler} item={item} />
            </Col>
        ))}</Row>
    )
}

export default HistorySection