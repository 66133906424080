import React from 'react'
import api from '@/services/api'
import fetchPriceAndRoutev2 from '@/core/price/fetchPriceAndRoutev2'
import { message } from 'antd'




//catalog/getProductsBySearch?search=filter
const useGetProductsBySearchTecdoc = () => {
    const [loading, setLoading] = React.useState(false)

    const [products, setProducts] = React.useState([])
    const [error, setError] = React.useState(null)
    const [total, setTotal] = React.useState(0)
    const [deliveries, setDeliveries] = React.useState([])

    const [deepMode, setDeepMode] = React.useState(false)

    const search = async (searchTerm, page, limit) => {

        //reset

        setLoading(true)


        try {

            let pageQuery = page ? `&page=${page}` : ''
            let limitQuery = limit ? `&limit=${limit}` : ''


            let url = `/catalog/getProductsBySearch?search=${searchTerm}`

            if (pageQuery) {
                url += pageQuery
            }

            if (limitQuery) {
                url += limitQuery
            }

            if(deepMode){
                url += '&deep=1'
            }




            const productResult = await api().get(url);

            const priceListData = []



            for (let i = 0; i < productResult.data.products.length; i++) {
                priceListData.push({ key: i, rows: [productResult.data.products[i].Id] })
                productResult.data.products[i].loadingAvability = true;
            }


            const priceAndRoute = await fetchPriceAndRoutev2(JSON.stringify(priceListData))

            let deliveries = priceAndRoute.deliveries



            //match the result with the products

            let i = 0;

            productResult.data.products.map((item, index) => {
                let findIndex = priceAndRoute.data.findIndex(x => x.key == index)
                let find = priceAndRoute.data[findIndex]
                if (findIndex != -1) {
                    productResult.data.products[i].sortKey = find?.index
                    productResult.data.products[i].avability = {
                        data: find.data,
                        fastestRowId: find.fastestRowId,
                    }
                    productResult.data.products[i].loadingAvability = false
                } else {
                    productResult.data.products[i].sortKey = Infinity
                    productResult.data.products[i].avability = null;
                    productResult.data.products[i].loadingAvability = false
                }
                i++;
            })

            setProducts(productResult.data.products.sort((b, a) => (b.sortKey) - (a.sortKey)))

            setTotal(productResult.data.total)

            setDeliveries(deliveries)



        } catch (e) {
            setProducts([])
            setError(null)
            setTotal(0)
            setDeliveries([])
    
            message.error('An error occured while fetching products')
            setError(e)
        }

        finally {
            setLoading(false)
        }





    }

    return { loading, products, error, total, search, deliveries,deepMode,setDeepMode }



}

export default useGetProductsBySearchTecdoc