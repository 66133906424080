import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    remark: null,
    reference: null,
    billingAddress: null,
    shippingAddress: null,
    cardNumber: null,
    cardName: null,
    month: null,
    year: null,
    cvc: null

}

export const payment = createSlice({
    name: 'payment',
    initialState,
    reducers: {
        setRemark: (state, action) => {
            state.remark = action.payload;
        },
        setReference: (state, action) => {
            state.reference = action.payload;
        },
        setBillingAddress: (state, action) => {
            state.billingAddress = action.payload;

        },
        setShippingAddress: (state, action) => {
            state.shippingAddress = action.payload;
        },
        setCardNumber: (state, action) => {
            state.cardNumber = action.payload;
        },
        setCardName: (state, action) => {
            state.cardName = action.payload;
        },
        setMonth: (state, action) => {
            state.month = action.payload;
        },
        setYear: (state, action) => {
            state.year = action.payload;
        },
        setCvc: (state, action) => {
            state.cvc = action.payload;
        }


    },
})

// Action creators are generated for each case reducer function
export const { setRemark, setReference, setBillingAddress, setShippingAddress, setCardNumber, setCardName, setMonth, setYear, setCvc } = payment.actions

export default payment.reducer

