import api from '@/services/api'
export default () => new Promise((resolve,reject) => {
    api()({
        url : '/webshop/slider',
    }).then((e)=>{
        
        if(e.data.status == false) throw new Error;
        resolve(e.data.data)
    })
    .catch((e) =>{

        console.log(e)
        reject(false)
    } )

})
