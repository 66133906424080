import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RightOutlined } from '@ant-design/icons';
import { Divider, Image, message, Modal, Skeleton, Table } from 'antd';
import api from '@/services/api';
import useAddTab from '@/hooks/assistant/useAddTab';

export const ResultVinnNumberModal = ({ vinn, cb }) => {
  const [loading, setLoading] = React.useState(false);
  const { t } = useTranslation();
  const [data, setData] = React.useState([]);
  const addTab = useAddTab();

  useEffect(() => {
    if (vinn) {
      setLoading(true);
      api()
        .post('/catalog/search-vinn-number', {
          Vinn: vinn,
        })
        .then((e) => {
          setData(e.data.data.sort((a, b) => a?.LineName?.localeCompare(b?.LineName)));
          if (e.data.data.length === 1) {
            let item = e.data.data[0];
            addTab(
              {
                vehicleLineId: item.LineCode,
                vehicleLineLineName: item.LineName,
                section: 'vehicleMenu',
                make: item.MakeCode,
                plate: null,
                makeName: item.VehicleMakeName,
                type: 'assistant',
                vehicleSeriesName: item.VehicleReferenceName,
                vehicleSeriesId: item.VehicleReferenceId,
                title: item.VehicleMakeName + ' ' + item.LineName + ' ' + item.VehicleReferenceName,
              },
              'redirect'
            );
            cb();
          }
        })
        .catch((f) => {
          message.error(t('vinn-not-found'));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [vinn]);

  const SelectArea = ({ children, item }) => {
    const onRowClick = () => {
      api()
        .post('/catalog/validate-type-code', {
          Vinn: vinn,
          SeriesId: item.VehicleReferenceId,
        });
      addTab(
        {
          vehicleLineId: item.LineCode,
          vehicleLineLineName: item.LineName,
          section: 'vehicleMenu',
          make: item.MakeCode,
          plate: null,
          makeName: item.VehicleMakeName,
          type: 'assistant',
          vehicleSeriesName: item.VehicleReferenceName,
          vehicleSeriesId: item.VehicleReferenceId,
          title: item.VehicleMakeName + ' ' + item.LineName + ' ' + item.VehicleReferenceName,
        },
        'redirect'
      );
      cb();
    };

    return (
      <div
        onClick={() => {
          onRowClick(item);
        }}
        style={{
          cursor: 'pointer',
        }}
      >
        {children}
      </div>
    );
  };

  const columns = [
    {
      title: 'Make',
      dataIndex: 'VehicleMakeName',
      key: 'VehicleMakeName',
      render: (text, record) => {
        return <SelectArea item={record}>{text}</SelectArea>;
      },
    },
    {
      title: 'Line Name',
      dataIndex: 'LineName',
      key: 'LineName',
      render: (text, record) => {
        return <SelectArea item={record}>{text}</SelectArea>;
      },
    },
    {
      title: 'Engine Type',
      dataIndex: 'EngineType',
      key: 'EngineType',
      render: (text, record) => {
        return <SelectArea item={record}>{text}</SelectArea>;
      },
    },
    {
      title: 'Vehicle Reference',
      dataIndex: 'VehicleReferenceName',
      key: 'VehicleReferenceName',
      render: (text, record) => {
        return <SelectArea item={record}>{text}</SelectArea>;
      },
    },
    {
      title: 'Drive Type',
      dataIndex: 'DriveType',
      key: 'DriveType',
      render: (text, record) => {
        return <SelectArea item={record}>{text}</SelectArea>;
      },
    },
    {
      title: 'Body Type',
      dataIndex: 'BodyType',
      key: 'BodyType',
      render: (text, record) => {
        return <SelectArea item={record}>{text}</SelectArea>;
      },
    },
    {
      title: 'Engine Contents (cc)',
      dataIndex: 'EngineContents',
      key: 'EngineContents',
      render: (text, record) => {
        return <SelectArea item={record}>{text}</SelectArea>;
      },
    },
    {
      title: 'Fuel Type',
      dataIndex: 'FuelCode',
      key: 'FuelCode',
      render: (text, record) => {
        return <SelectArea item={record}>{text}</SelectArea>;
      },
    },
    {
      title: 'KW',
      dataIndex: 'KWCat',
      key: 'KWCat',
      render: (text, record) => {
        return <SelectArea item={record}>{text}</SelectArea>;
      },
    },
    {
      title: 'Horsepower',
      dataIndex: 'HorsePowerFrom',
      key: 'HorsePower',
      render: (text, record) => {
        record.HorsePowerFrom = record.HorsePowerFrom ? record.HorsePowerFrom : 0;
        record.HorsePowerTo = record.HorsePowerTo ? record.HorsePowerTo : 0;

        if(!(record.HorsePowerFrom || record.HorsePowerTo)) {
            return null
        }

        return <SelectArea item={record}>{`${record.HorsePowerFrom} - ${record.HorsePowerTo}`}</SelectArea>;
      },
    },
    {
      title: 'Cylinders',
      dataIndex: 'TotalCylinders',
      key: 'TotalCylinders',
      render: (text, record) => {
        return <SelectArea item={record}>{text}</SelectArea>;
      },
    },
    {
      title: 'Build Period',
      dataIndex: 'BuildStart',
      key: 'BuildPeriod',
      render: (text, record) => {
        return <SelectArea item={record}>{`${record.BuildStart} - ${record.BuildEnd}`}</SelectArea>;
      },
    },
    {
      title: 'Engines',
      dataIndex: 'Engines',
      key: 'Engines',
      render: (text, record) => {
        return <SelectArea item={record}>{text}</SelectArea>;
      },
    },
    {
      title: 'Image',
      dataIndex: 'Photo',
      key: 'Photo',
      render: (text, record) => {
        return (
          <SelectArea item={record}>
            <Image src={record.Photo} height={40} />
          </SelectArea>
        );
      },
    },
  ];


  return (
    <div>
      <Skeleton loading={loading} active={true}>
        <Table dataSource={data} columns={columns} rowKey="VehicleReferenceId" />
      </Skeleton>
    </div>
  );
};

const ResultVinnNumber = ({ reducer, text = 'VINN' }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = React.useState(false);

  return (
    <>
      <Modal
        width={'100%'}
        title={'VINN Number'}
        footer={null}
        zIndex={9999999}
        open={showModal}
        onCancel={() => setShowModal(false)}
      >
        <ResultVinnNumberModal cb={() => setShowModal(false)} key={reducer.state.text} vinn={reducer.state.vinnNumber} />
      </Modal>

      <div className="flex">
        <p style={{ color: '#A5A5A5', fontSize: '20px' }}>{t(text)}</p>
        <div className="flex items-center cursor-pointer" onClick={() => setShowModal(true)}>
          {<RightOutlined />}
          {
            <p style={{ fontWeight: 500, fontSize: '13px', padding: 0 }} className="ml-2">
              <a style={{ color: '#2b94c8' }}>{reducer.state.vinnNumber?.toUpperCase()}</a>
            </p>
          }
        </div>
      </div>
      <Divider />
    </>
  );
};

export default ResultVinnNumber;
