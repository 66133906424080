import { Card, Col, Row, Skeleton } from "antd";

const BrandListSkeleton = () => {
    return (
      <Row gutter={[16, 16]} >
        {Array.from({ length: 8 }).map((_, index) => (
          <Col
            xs={24}
            sm={12}
            md={8}
            lg={6}
            xl={4}
            key={index}
          >
            <Card
              style={{
                borderRadius: '8px',
                overflow: 'hidden',
              }}
              cover={
                <Skeleton.Image
                  style={{
                    width: '100%',
                    height: '150px',
                  }}
                />
              }
            >
              <Skeleton active title={false} paragraph={{ rows: 1, width: '80%' }} />
            </Card>
          </Col>
        ))}
      </Row>
    );
  };

  export default BrandListSkeleton