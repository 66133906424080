import React from 'react';
import { Modal, Table, Alert } from 'antd';

const OemModal = ({ modalVisibles, setModalVisibles, oemData }) => {
    // Hata kontrolü için durumu belirleme
    const hasError = !Array.isArray(oemData) || oemData.length === 0;

    const columns = [
        {
            title: 'Article Number',
            dataIndex: 'articleNumber',
            key: 'articleNumber',
        },
        {
            title: 'Manufacturer ID',
            dataIndex: 'mfrId',
            key: 'mfrId',
        },
        {
            title: 'Manufacturer Name',
            dataIndex: 'mfrName',
            key: 'mfrName',
        },
    ];

    return (
        <Modal
            footer={null}
            open={modalVisibles.Oem}
            onCancel={() => setModalVisibles({ ...modalVisibles, Oem: false })}
            title="OEM Information"
        >
            {hasError ? (
                <Alert 
                    message="No OEM data available or the data format is incorrect." 
                    type="error" 
                />
            ) : (
                <Table 
                    dataSource={oemData}
                    columns={columns}
                    pagination={false}
                    rowKey="articleNumber"
                />
            )}
        </Modal>
    );
};

export default OemModal;
