import api from '@/services/api';
export default () => {
    return new Promise((resolve, reject) => {

        api()({
            url: "/catalog/vehicleMenuList",
            method: "GET",
        }).then((e) => {
            if (e.data.status == 'false') reject(false);
            resolve(e.data.data)
        }).catch(e => reject(false))
    })
}