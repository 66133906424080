import React, { useState } from 'react'
import api from '@/services/api'
const useGetGenericArticles = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [addedGenerics, setAddedGenerics] = useState([]);
    const fetch = async () => {
        setLoading(true);
        try {
            const { data } = await api(true).get('/catalog/admin-category-list-get-generic-articles');
            setData(data.data);
            setAddedGenerics(data.added);
        }
        catch (e) {
            throw e;
        }
        finally {
            setLoading(false);
        }
    }

    return {loading,data,setData,fetch,addedGenerics, setAddedGenerics}
}

export default useGetGenericArticles