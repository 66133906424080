import React, { useEffect, useState } from "react";
import { Button, Card, Col, DatePicker, Pagination, Form, Input, message, Row, Table, Spin, Modal, Tag, Divider, Select } from "antd";
import { DownloadOutlined, SearchOutlined } from "@ant-design/icons";
import moment from 'moment'
import axios from "axios";
import transactions from '@/core/transactions/transactions'
import NumberRound from "number-zero-round";
import ShowDocument from "@/components/invoices/ShowDocument";
import { useTranslation } from "react-i18next";

const { RangePicker } = DatePicker;
const {Option} = Select

const calcUnpaid = (b) => Number(b.TotalAmount) - Number(b.PaidAmount)

const TransactionsTable = ({ paid, unpaid }) => {

  const [data, setData] = useState([]);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [documentNumber, setDocumentNumber] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const [selectedNumber, setSelectedNumber] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const showDocumentHandler = (e) => {
    message.info("Document loading ...")
    setShowDocumentModal(true);
    setSelectedNumber(e)
  }

  const columns = [
    {
      title: t('invoice-number'),
      dataIndex: "DocumentNumber",
      key: "DocumentNumber",
      sorter: (a, b) => a.DocumentNumber - b.DocumentNumber,

    },
    {
      title: t('invoice-date'),
      dataIndex: "DocumentDate",
      key: "DocumentDate",
      sorter: (a, b) => moment(a.DocumentDate).unix() - moment(b.DocumentDate).unix(),
      render: (a, b) => moment(a).format('DD/MM/YYYY')


    },
    {
      title: t('total-net-price'),
      dataIndex: "TotalNetPrice",
      key: "TotalNetPrice",
      render: (a, b) => {
        return NumberRound(a, 2)
      },
      sorter: (a, b) => moment(a.TotalNetPrice).unix() - moment(b.TotalNetPrice).unix(),

    },
    {
      title: t('total-amount'),
      dataIndex: "TotalAmount",
      key: "TotalAmount",
      sorter: (a, b) => a.TotalAmount - b.TotalAmount,
      render: (a, b) => '€' + NumberRound(a)


    },
    {
      title: t('paid-amount'),
      dataIndex: "PaidAmount",
      key: "PaidAmount",
      sorter: (a, b) => Number(a.PaidAmount) - Number(b.PaidAmount),
      render: (a, b) => '€' + NumberRound(a)
    },
    {
      title: t('unpaid-amount'),
      dataIndex: "Unpaid",
      key: "Unpaid",
      sorter: (a, b) => Number(a.PaidAmount) - Number(b.PaidAmount),
      render: (a, b) => '€' + NumberRound(b.UnpaidAmount)
    },
    {
      title: t('status'),
      dataIndex: "Status",
      key: "status",
      sorter: (a, b) => Number(a.UnpaidAmount) - Number(b.UnpaidAmount),
      render: (a, b) => <Tag color={
        (!Number(b.UnpaidAmount)) ? "green" : "red"
      } >
        {!Number(b.UnpaidAmount) ? t('paid') : t('unpaid')}
      </Tag>
    },
    {
      title: "",
      dataIndex: "",
      key: "download",
      render: (a, b) => {
        return (
          <div onClick={() => showDocumentHandler(b.DocumentNumber)}>
            <DownloadOutlined style={{ fontSize: "19px", color: "var(--primaryColor)", cursor: "pointer" }} />
          </div>
        );
      },
    },
  ];


  const onFinish = () => {
    let StartDate = startDate ? startDate.format('YYYY-MM-DD HH:mm:ss') : null
    let EndDate = endDate ? endDate.format('YYYY-MM-DD HH:mm:ss') : null
    let DocumentNumber = documentNumber ? documentNumber : null


    let params = {}

    if ((StartDate || EndDate || DocumentNumber)) {
      //page i sıfırla
      setPage(1)
      params = {
        ...params,
        currPage: 1,
        startDate: StartDate,
        endDate: EndDate,
        documentNumber: DocumentNumber
      }



    } else {
      if (StartDate) {
        params = {
          ...params,
          startDate: StartDate
        }
      }

      if (EndDate) {
        params = {
          ...params,
          endDate: EndDate
        }
      }

      if (DocumentNumber) {
        params = {
          ...params,
          documentNumber: DocumentNumber
        }
      }
    }

    if (!params.startDate)
      delete params.startDate
    if (!params.endDate)
      delete params.endDate
    if (!params.documentNumber)
      delete params.documentNumber

    params.limit = pageSize
    params.paid = paid
    params.unpaid = unpaid


    fetch(params)
  }


  useEffect(() => {
    fetch({ currPage: 1, paid, unpaid, limit: pageSize })
  }, [])

  const fetch = (params) => {
    setLoading(true)
    transactions.fetchInvoices({
      ...params
    }).then(e => {
      setData(e.data.data)
      setTotalPage(e.data.totalCount)
    }).catch(f => {
      message.error(f?.response?.data?.message || "Failed !")
    }).finally(() => setLoading(false))

  }


  const onChangePaginationHandler = (e) => {
    setPage(e)
    fetch({ currPage: e })
  }

  const onChangePerSizeHandler = (e) => {
    setPageSize(e)
    fetch({ currPage: 1, paid, unpaid, limit: e })
  }



  return (
    <Spin spinning={loading}>

      <Modal bodyStyle={{ height: '1000px', padding: 0 }} open={showDocumentModal} footer={null} width="100%" onCancel={() => setShowDocumentModal(false)}>
        <ShowDocument documentNumber={selectedNumber} type={1} />
      </Modal>

      <Form onFinish={onFinish} form={form} layout="vertical">
        <Form.Item name="DocumentNumber" label={t('document-number')}>
          <Input value={documentNumber} onChange={e => setDocumentNumber(e.target.value)} />
        </Form.Item>



        <Form.Item label="Date" name={t('date')} >
          <RangePicker onChange={(e) => {
            if (e) {
              setStartDate(e[0])
              setEndDate(e[1])
            } else {
              setStartDate(null)
              setEndDate(null)
            }
          }} showTime />
        </Form.Item>
        <Form.Item >
          <button onClick={onFinish} className="mega-button" >{t('search')}</button>
        </Form.Item>

      </Form>
      <Table
        pagination={false}
        title={() =>
          <div>
            <span>Show per page:</span>
            <Select style={{
              width: 200,
              marginLeft: '10px'
            }} className="oks" value={pageSize} onChange={onChangePerSizeHandler}>
              <Option value={10}>10</Option>
              <Option value={50}>50</Option>
              <Option value={100}>100</Option>
              <Option value={500}>500</Option>

            </Select>
          </div>}

        summary={pageData => {
          let totalBorrow = 0;
          let totalRepayment = 0;

          pageData.forEach(({ borrow, repayment }) => {
            totalBorrow += borrow;
            totalRepayment += repayment;
          });

          return (
            <>
              <Table.Summary.Row style={{
                backgroundColor: "#F2F5F6",
              }} >
                <Table.Summary.Cell>Total</Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell style={{
                  background: "red !important",

                }} ><div style={{
                  color: "#088F8F"
                }}>
                    {'€' +NumberRound(data.reduce(
                      (accumulator, currentValue) => accumulator + parseFloat(currentValue?.TotalNetPrice),
                      0
                    ))}
                  </div></Table.Summary.Cell>
                <Table.Summary.Cell ><div style={{
                  color: "green"
                }} >{'€' + NumberRound(data.reduce(
                  (accumulator, currentValue) => accumulator + parseFloat(currentValue?.PaidAmount),
                  0
                ))}</div></Table.Summary.Cell>
                <Table.Summary.Cell><div style={{
                  color: "red"
                }}>
                  {'€' + NumberRound(data.reduce(
                    (accumulator, currentValue) => accumulator + parseFloat(currentValue?.UnpaidAmount),
                    0
                  ))}
                </div></Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>

              </Table.Summary.Row>

            </>
          );
        }} loading={loading} uid={"DocumentNumber"} size="small" dataSource={data} className="table-2" columns={columns} />
      {loading ? null : <Pagination onChange={onChangePaginationHandler} className="mt-3" current={page} total={totalPage} pageSize={pageSize} />}
    </Spin>
  );
};

export default TransactionsTable;
