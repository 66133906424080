import getTiresGroups from '@/core/tires/getTiresGroups';
import {  Input, AutoComplete, Divider } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';



const SelectTiresCategory = ({ reducer }) => {
  const [options, setOptions] = useState([]);
  const searchText = reducer.state.searchText
  const [list,setList] = useState([])

  const {t} = useTranslation();


  useEffect(()=>{
    getTiresGroups().then(e => {
      setList(e)
    }).catch(f => {
      setList([])
    })
  },[])

  const onSearch = (searchText) => {

    let _ = []
    for (const items of list) {
      console.log(items)
      let re = new RegExp(searchText, 'i');
      if (items.Value.search(re) !== -1 || items.Name.search(re) !== -1) {
        setOptions(
          _.push({ value: items.Name })
        );
      }
    }


    setOptions(
      _
    );
  };
  const onSelect = (data) => {
    reducer.dispatch({ type: 'setSearchText', payload: data })
    reducer.searchProduct(data)
  };

  return (
    <div>

      <AutoComplete
        options={options}
        style={{
          width: '100%',
        }}
        onSelect={onSelect}
        onSearch={onSearch}
      >
        <Input.Search value={searchText} size="large" placeholder={t('search') + ' ' + t('tires') + ' ...'} enterButton />
      </AutoComplete>

      <Divider />
    </div>
  )
}

export default SelectTiresCategory