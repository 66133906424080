import { useEffect, useMemo, useState } from "react";
import { Button, Col, Input, message, Row, Spin } from "antd";
import fetchVehicleMenu from "@/core/vehicle/fetchVehicleMenu";
import VehicleMenuGroup from "./VehicleMenuGroup";
import VehicleInfo from ".//VehicleInfo";
import { useDispatch } from "react-redux";
import { insertHistoryList } from "@/store/history";
import getCatalogName from "@/helpers/getCatalogName";
import TecdocVehicleMenu from "../TecdocVehicleMenu/TecdocVehicleMenu";
// import CatalogMenu from "../../core/CatalogMenu";
const VehicleMenu = ({ onClick, searchArea, series, plate, defaultDescription }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [showError, setShowError] = useState("");
  const [search, setSearch] = useState("");
  const [error, setError] = useState(false);
  const d = useDispatch();

  useEffect(() => {
    setLoading(true)
    fetchVehicleMenu().then(e => {
      setData(e)
    }).catch(() => setError(true))
      .finally(() => setLoading(false))
  }, [series])


  useEffect(() => {
    d(insertHistoryList({ Plate: plate, Series: series }))
  }, [series, plate])

  const catalogName = useMemo(() => getCatalogName(), [])

  return (
    <>
      <div>
        <VehicleInfo Plate={plate} Series={series} />
      </div>
      {searchArea}

      <Spin tip="Loading" spinning={loading}>
        {catalogName == 'TECDOC' ? <div style={{
          marginTop : 20
        }}>
          <TecdocVehicleMenu onClick={onClick} list={data} />
        </div> : <div className="car-menu-list">
          <Row gutter={16}>
            {data?.map((item, key) => (
              <Col lg={8} sm={24} xs={24} key={key}>
                <div className="car-menu-list__column">
                  {item?.Groups.map((group, key) => (
                    <VehicleMenuGroup t={group} key={key} MenuCode={group.MenuCode} onClick={onClick} title={group.Menu} items={group.Items} />
                  ))}
                </div>
              </Col>
            ))}
          </Row>
        </div>}
      </Spin>
    </>
  );
};

export default VehicleMenu;
