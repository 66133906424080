import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {  Button, Tabs } from 'antd'
import Makes from '@/components/assistant/Vehicle/VehicleMakes';
import VehicleSeriesTable from '@/components/assistant/Vehicle/VehicleSeriesTable';

import { setTabs } from '@/store/tabs';
import AssistantBackButton from './AssistantBackButton';
import VehicleLineTable from '@/components/assistant/Vehicle/VehicleLineTable';

import VehiclePartsArea from './VehiclePartsArea/VehiclePartsArea';
import SearchProduct from './Vehicle/VehicleSearchProductGroup';
import VehicleMenu from './Vehicle/VehicleMenu';
const { TabPane } = Tabs;











const AssistantSection = ({ Id }) => {
    const [tab, setTab] = useState({})
    const tabs = useSelector(e => e.tabs.tabs);
    const d = useDispatch();
    const [renderList, setRenderList] = useState([])
    useEffect(() => {
        setTab(tabs.filter(item => item.id == Id)[0])
    }, [Id, tabs])




    useEffect(() => {
        if (tabs.length) {
            let k = JSON.parse(JSON.stringify(tabs))
            let save = JSON.stringify(k.slice(-4))
            localStorage.setItem('last-tabs', save);
        }
    }, [tabs])

    const onClickMakeHandler = (CatalogMakeCode, Name, MakePhoto) => {



        d(setTabs(tabs.map(item => {
            if (item.id == Id) {
                return { ...item, make: CatalogMakeCode, makeName: Name, section: "vehicleLine", MakePhoto: MakePhoto,title : Name }
            } else return item;
        })))
    }

    const onClickLineHandler = (a, b) => {

        d(setTabs(tabs.map(item => {
            if (item.id == Id) {
                return { ...item, vehicleLineId: a, vehiclineLineName: b, section: "vehicleSeries",title : item.makeName + " " + b }
            } else return item;
        })))
    }

    const onClickSeriesHandler = (a, b) => {

        let tab = null

        d(setTabs(tabs.map(item => {
            if (item.id == Id) {
                tab = item;
                return { ...item, vehicleSeriesId: a, vehicleSeriesName: b, section: "vehicleMenu" }
            } else return item;
        })))

        //add History



    }
    const onClickMenuHandler = (a, b) => {
        d(setTabs(tabs.map(item => {
            if (item.id == Id) {
                return { ...item, vehicleMenuId: a, vehicleMenuName: b, section: "vehicleParts", description: null, productGroupId: null }
            } else return item;
        })))
    }
    const onClickMenu = (a, b) => {
        d(setTabs(tabs.map(item => {
            if (item.id == Id) {
                return { ...item, vehicleMenuId: a, vehicleMenuName: b, description: null, productGroupId: null }
            } else return item;
        })))
    }






    useEffect(() => {
        if (!tab.section) return;
        if (!renderList.find(x => x == tab.section))
            setRenderList([...renderList, tab.section])
    }, [tab.section])





    return (
        <div>
            
            <AssistantBackButton Id={Id} />
            <Tabs className="assistant-section-component-tabs" animated activeKey={String(tab.section)}>
                <TabPane tab="Tab 1" key="makes">
                    <Makes onClick={onClickMakeHandler} />
                </TabPane>
                <TabPane tab="Tab 2" key="vehicleLine">
                    <VehicleLineTable makeName={tab.makeName} MakePhoto={tab.MakePhoto} onClick={onClickLineHandler} make={tab.make} />
                </TabPane>
                <TabPane tab="Tab 3" key="vehicleSeries">
                    <VehicleSeriesTable line={tab.vehicleLineId} onClick={onClickSeriesHandler} />
                </TabPane>
                <TabPane tab="Tab 3" key="vehicleMenu">
                    <VehicleMenu searchArea={<SearchProduct TabId={tab.id} />} onClick={onClickMenuHandler} series={tab.vehicleSeriesId} plate={tab.plate} />
                </TabPane>
                <TabPane tab="Tab 3" key="vehicleParts">
                    <VehiclePartsArea Id={Id} oem={tab.oem} render={tab.render} productGroupId={tab.productGroupId} searchArea={<SearchProduct TabId={tab.id} />} onClickMenu={onClickMenu} menu={tab.vehicleMenuId} plate={tab.plate} series={tab.vehicleSeriesId} />
                </TabPane>
            </Tabs>

        </div>
    )
}

export default AssistantSection
