import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  tabs: [],
  lastKey : 0,
  activeKey : 0,
  // makeId : 0,
  // vehicleLineId : 0,
  // vehicleSeriesId : 0,
  // menuId : 0,
  // plate : ""


}

export const tabs = createSlice({
    name: 'tabs',
    initialState,
    reducers: {
      setLastKey: (state,action) => {
        state.lastKey = action.payload;
      
      },
      setTabs : (state,action)=>{
        state.tabs = action.payload;
      },
      setActiveKey : (state,action)=>{
        state.activeKey = action.payload;
      },
      
    
      
      
    },
  })
  
  // Action creators are generated for each case reducer function
  export const { setLastKey,setTabs,setActiveKey} = tabs.actions
  
  export default tabs.reducer
  
