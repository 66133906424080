import Filter from './Filter';
import fetchProducts from '@/core/product/searchProductByVehicle'
import { Card, Result, Row, Spin, Col, Tabs, Collapse, Button, List, Tooltip, Checkbox, Divider, Form, Input, Tag, message, Select, Pagination, Progress } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import VehicleInfo from '@/components/assistant/Vehicle/VehicleInfo'
import LittleMenu from '@/components/assistant/Vehicle/VehicleLittleMenu';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

import useProductApplicationReducer from './useApplicationReducer';

import Product from '@/components/products/Product';


import fetchPriceAndRoute from '@/core/price/fetchPriceAndRoute';
import fetchPriceAndRoutev2 from '@/core/price/fetchPriceAndRoutev2';

// import useUpdatePartsArea from './useUpdatePartsArea';
import { getSortKey } from '@/core/avability/avability';
import { useSelector } from 'react-redux';
import getCatalogName from '@/helpers/getCatalogName';




const VehiclePartsArea = ({ catalogBrandId,searchArea, series, plate, menu, productGroupId, productReference, onClickMenu, render, oem, searchDescription }) => {
  const appReducer = useProductApplicationReducer()
  const { t } = useTranslation();
  const [keyProductsRender, setKeyProductsRender] = useState(0)
  const refSearch = useRef()

  const paginationRef = useRef();
  

  const account = useSelector(e => e.session)







  const onSetDescriptionHandler = () => {


    if (!(refSearch.current.value.length >= 3 || refSearch.current.value == ''))
      return message.info("Description minimum 3 character.")
    appReducer.dispatch({ type: 'setDescription', payload: refSearch.current.value })

  }
  
  useEffect(() => {
    appReducer.dispatch({ type: 'setProducts', payload: [] })
    appReducer.dispatch({ type: 'setLoadingProducts', payload: true })
    fetchProducts({
      series: series,
      productGroupId: productGroupId,
      menuCode: menu,
      productReference: productReference,
      oem: oem,
      searchDescription: searchDescription,
      catalogBrandId
    }).then((result) => {
      let priceListData = []


      // let testP = ['K015499XS']

      // result.products = result.products.filter(x => testP.includes(x.ProductReference))

      appReducer.dispatch({ type: 'setOriginalProducts', payload: result.products })
      appReducer.dispatch({ type: 'setFiltersData', payload: result.filters })
      appReducer.dispatch({ type: 'setFilterTree', payload: result.filterTree })



      for (let i = 0; i < result.products.length; i++) {
        // priceListData.push({ ProductReference: result.products[i].ProductReference, BrandId: result.products[i].BrandId })

        priceListData.push({ key: result.products[i]._id, rows: result.products[i].RowIds })
        result.products[i].loadingAvability = true;
      }
      appReducer.dispatch({ type: 'setProducts', payload: result.products })
      fetchPriceAndRoutev2(JSON.stringify(priceListData)).then((e) => {
        let i = 0;
        for (const item of result.products) {


          let findIndex = e.data.findIndex(x => x.key == item._id)
          let find = e.data[findIndex]
          if (findIndex != -1) {

            result.products[i].sortKey = find?.index
            result.products[i].avability = {
              data: find.data,
              fastestRowId: find.fastestRowId,
            }
            result.products[i].loadingAvability = false
          } else {
            result.products[i].sortKey = Infinity
            result.products[i].avability = null;
            result.products[i].loadingAvability = false
          }
          i++;
        }

        console.log(result.products);

        result.products = result.products.sort((b, a) => (b.sortKey) - (a.sortKey));
        appReducer.dispatch({ type: 'setProducts', payload: result.products })
        appReducer.dispatch({ type: 'setDeliveries', payload: e.deliveries })


      }).catch(f => {
      }).finally(() => {
        setKeyProductsRender(keyProductsRender + 1)
      })

    }).catch(e => {
      appReducer.dispatch({ type: 'setError', payload: true })
    }).finally(e => {
      appReducer.dispatch({ type: 'setLoadingProducts', payload: false })


    })
  }, [series, plate, menu, productGroupId, oem, searchDescription, account?.relationId])



  const catalogName = useMemo(() => getCatalogName(), [])



  return (
    <div>


      {series || plate ? <VehicleInfo Plate={plate} Series={series} /> : null}
      {catalogName != 'TECDOC' && (series || plate || menu) ? <LittleMenu onClick={onClickMenu} menuCode={menu} /> : null}

      <div className='mt-[10px]'>
        {appReducer.state.loadingProducts ? <Card className="searching-card mt-[10px]">
          <Result
            icon={
              <div>
                <img src="https://webshop.parts-soft.eu/searching.gif" />
              </div>
            }
            title={t('we-are-looking-for-your-product-please-wait')}
          />
        </Card> :
          <div style={{ position: 'relative' }}>
            {/* <Card style={{ marginLeft: '12px' }}>
              <Row>
                <Col lg={4}>
                  <Select style={{ width: '200px' }} placeholder={t('sort-by')}>
                    <Select.Option value={"decreasing-by-price"} >{t('decreasing-by-price')}</Select.Option>
                    <Select.Option value={"increasing-by-price"}>{t('increasing-by-price')}</Select.Option>
                  </Select>
                </Col>
                <Col lg={16}>
                  {appReducer.state.filters.ProductGroupName.filter(item => item.selected).map((item, index) => <Tag className='mt-3' >{item.Name}</Tag>)}
                  {appReducer.state.filters.BrandName.filter(item => item.selected).map((item, index) => <Tag className='mt-3' >{item.Name}</Tag>)}

                </Col>
                <Col lg={4}>
                  <div onKeyDown={(event) => event.key == 'Enter' ? onSetDescriptionHandler() : null}>
                    <input ref={refSearch} placeholder={t('search')} className='bordered-input' />
                    <SearchOutlined onClick={onSetDescriptionHandler} style={{ position: 'absolute', right: '10px', top: '0', fontSize: '16px' }} />
                  </div>
                </Col>
              </Row>
            </Card> */}



            <Row gutter={12}>
              <Col lg={6} sm={{ span: 24, justify: 'end', }} xs={{ span: 24, justify: 'end' }} >
                <div className='mb-[10px]'>
                  {searchArea}
                </div>
                <Filter reducer={appReducer} />

                {/* <Card size='small' >
                  <div className='mb-[10px]'>
                    {searchArea}
                  </div>
                  <Filter reducer={appReducer} />
                </Card> */}

              </Col>
              <Col key={keyProductsRender} lg={18} sm={{
                span: 24,
                justify: 'end',

              }} xs={{
                span: 24,
                justify: 'end'
              }}>
                {appReducer.state.products.length == 0 ? <Card className="searching-card mt-[10px]">
                  <Result
                    icon={
                      <>
                        <img src="https://webshop.parts-soft.eu/product-not-found.png" />
                      </>
                    }
                    title={t('not-found-product-text')}
                    subTitle={productReference?.toUpperCase()}
                  />
                </Card> : <div key={keyProductsRender}>{appReducer.state.products.slice((appReducer.state.currentPage - 1) * appReducer.state.perPage, appReducer.state.currentPage * appReducer.state.perPage).map((item, key) =>
                  <Product  Series={series} deliveries={appReducer.state.deliveries} plate={plate} ProductReference={item.ProductReference} onlyAvability={false} product={item} key={key} />
                )}
                  <div ref={paginationRef} ></div>
                  <Pagination style={{ marginTop: '20px' }} onChange={(e) => appReducer.dispatch({ type: 'setCurrentPage', payload: e })} current={appReducer.state.currentPage} total={appReducer.state.totalCount} pageSize={appReducer.state.perPage} />
                </div>}
              </Col>
            </Row>



            <div >

            </div>
          </div>
        }</div>
    </div>
  )
}

export default VehiclePartsArea


