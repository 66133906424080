import { Form, Input, message } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

import useAddTab from '@/hooks/assistant/useAddTab'

const TyreSearch = ({ onClose }) => {
  const [form] = Form.useForm()
  const addTab = useAddTab()
  const { t } = useTranslation()

  const add = (e) => {
    addTab({
      title: 'Tires' + ' - ' + e,
      key: 'tires',
      type: 'Tires',
      tiresCode: e
    })
  }

  const onTyreSearch = (values) => {
    message.success(`Searching for tyre: ${values.width}/${values.height} R${values.diameter}`)
    // Add your search logic here
    let code = values.width + values.height + values.diameter
    add(code)
    form.resetFields()
    onClose()
  }

  const handleSearch = () => {
    form.submit()
  }

  return (
    <div className="custom-input-search">
      <Form layout="vertical" form={form} onFinish={onTyreSearch} className="space-y-6">
        <Form.Item label={`${t('tyre-search')}:`}>
          <Input.Group compact style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Form.Item name="width" noStyle rules={[{ required: true, message: 'Please input width!' }]}>
              <Input style={{ width: '20%', borderRadius: '6px 0 0 6px' }} placeholder={t('width')} />
            </Form.Item>
            <span style={{ padding: '9px 5px', borderRight: 'none' }}>/</span>
            <Form.Item name="height" noStyle rules={[{ required: true, message: 'Please input height!' }]}>
              <Input style={{ width: '20%' }} placeholder={t('height')} />
            </Form.Item>
            <span style={{ padding: '9px 5px', borderRight: 'none' }}>R</span>
            <Form.Item name="diameter" noStyle rules={[{ required: true, message: 'Please input diameter!' }]}>
              <Input.Search
                style={{ width: '50%', borderRight: 'none', borderRadius: '6px' }}
                placeholder={t('diameter')}
                enterButton={<SearchOutlined />}
                size="large"
                onSearch={handleSearch}
              />
            </Form.Item>
          </Input.Group>
        </Form.Item>
      </Form>
    </div>
  )
}

export default TyreSearch
