import { Form, Input, Button, message } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

import useAddTab from '@/hooks/assistant/useAddTab'

const PartsLookup = ({ onClose }) => {
  const [form] = Form.useForm()
  const addTab = useAddTab()
  const { t } = useTranslation()

  const onClickPartsLookup = (values) => {
    message.success('Parts lookup searching ...')
    addTab(
      {
        type: 'CarParts',
        productReference: values.partsLookup.toUpperCase(),
        title: values.partsLookup.toUpperCase()
      },
      'redirect'
    )
    form.resetFields()
    onClose()
  }

  const handleSearch = () => {
    form.submit()
  }

  return (
    <div className="custom-input-search">
      <Form layout="vertical" form={form} onFinish={onClickPartsLookup} className="space-y-6">
        <Form.Item label={`${t('parts-lookup')}:`} name="partsLookup">
          <Input.Search
            placeholder={`${t('e.g.')} GDB 1330`}
            enterButton={<SearchOutlined />}
            onSearch={handleSearch}
            size="large"
          />
        </Form.Item>
      </Form>
    </div>
  )
}

export default PartsLookup
