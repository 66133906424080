import useFetchBrandsByGenericArticles from '@/hooks/adminTecdoc/useFetchBrandsByGenericArticles'
import useSaveBrandCategory from '@/hooks/adminTecdoc/useSaveBrandCategory'
import { Checkbox, Col, Input, Row, Skeleton, Spin } from 'antd'
import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'

const DispatchBrandCategory = () => {
  const { genericArticleId } = useParams()

  const brands = useFetchBrandsByGenericArticles(genericArticleId)
  const [searchTerm, setSearchTerm] = React.useState('');
  const saveCategory = useSaveBrandCategory()
  const search = useMemo(() => {
    if (!brands?.brands) return []
    if (!searchTerm) return brands?.brands
    return brands?.brands?.filter((item) => {
      return item?.mfrName?.toLowerCase().includes(searchTerm.toLowerCase())
    })
  }, [searchTerm, brands?.brands])

  const onChangeChecked = async (brandId, status) => {
    if (status) {
      await saveCategory.request(brandId, 'save', genericArticleId)
      brands.setCheckedBrands(prev => [...prev, brandId])
    } else {
      await saveCategory.request(brandId, 'remove', genericArticleId)
      brands.setCheckedBrands(prev => prev.filter(e => e !== brandId))
    }
  }


  return (
    <Skeleton loading={brands.loading}>


      


      <Input value={searchTerm} onChange={e => setSearchTerm(e.target.value)} size='large' placeholder='Search' style={{ marginBottom: '20px' }} />

      <Spin spinning={saveCategory.loading}>
        <Row>


          {search.map((item, index) => <Col key={index} lg={4}> <div><Checkbox

            onChange={(e) => onChangeChecked(item?.dataSupplierId, e.target.checked)}
            checked={
              brands?.checkedBrands?.includes(item?.dataSupplierId)
            } value={item?.dataSupplierId}>{item.mfrName}</Checkbox></div></Col>)}

        </Row>
      </Spin>
    </Skeleton>
  )
}

export default DispatchBrandCategory