import React from 'react';
import { Card, Descriptions, Image, Typography, Button, Modal } from 'antd';
import { ShoppingCartOutlined, EllipsisOutlined } from '@ant-design/icons';
import { getFastestAvability, getOtherSeller } from '@/core/avability/avability2';
import BuyAreav2 from '../products/BuyAreav2/BuyAreav2';
import { useSelector } from 'react-redux';
import DocumentIcon from '../icons/DocumentIcon';
import CarIcon from '../icons/CarIcon';
import OemIcon from '../icons/OemIcon';
import ApplicationListTecdoc from '../products/ApplicationListTecdoc';
import OemModal from './OEMModal';
import ProductDocuments from '../products/ProductDocuments';
import { useTranslation } from 'react-i18next';

const { Title, Text } = Typography;


const IconArea = ({ settings, modalVisibles, setModalVisibles, product }) => {


    return (<div

        style={{
        }}

        className='pb-[10px] pt-[10px] h-[50px] flex'>
        {product.TecdocArticleId
            ?
            <a className="flex items-center justify-center p-[5px] relative self-stretch flex-[0_0_auto] rounded-[6px] border-[1px] border-solid border-[#f0f0f0]" style={{ backgroundColor: `${settings['HeaderBgColor']}27` }} onClick={() => setModalVisibles({ ...modalVisibles, ApplicationList: true })}>
                <CarIcon color={settings['HeaderBgColor']} />
            </a>
            :
            <a className="flex items-center justify-center p-[5px] relative self-stretch flex-[0_0_auto] rounded-[6px] border-[1px] border-solid border-[#f0f0f0]" >
                <CarIcon />
            </a>
        }

        {product?.OEM?.length ?
            <a className="ml-[6px] flex items-center justify-center p-[5px] relative self-stretch flex-[0_0_auto] rounded-[6px] border-[1px] border-solid border-[#f0f0f0]" style={{ backgroundColor: `${settings['HeaderBgColor']}27` }} onClick={() => setModalVisibles({ ...modalVisibles, Oem: true })} >
                <OemIcon color={settings['HeaderBgColor']} />
            </a> :

            <a className="ml-[6px] flex items-center justify-center p-[5px] relative self-stretch flex-[0_0_auto] rounded-[6px] border-[1px] border-solid border-[#f0f0f0]" >
                <OemIcon />
            </a>
        }



        {product.Documents && product.Documents.length ?
            <a className="ml-[6px]  flex items-center justify-center p-[5px] relative self-stretch flex-[0_0_auto] rounded-[6px] border-[1px] border-solid border-[#f0f0f0]" style={{ backgroundColor: `${settings['HeaderBgColor']}27` }} onClick={() => setModalVisibles({ ...modalVisibles, Documents: true })}>
                <DocumentIcon color={settings['HeaderBgColor']} />
            </a>
            :
            <a className="ml-[6px] flex items-center justify-center p-[5px] relative self-stretch flex-[0_0_auto] rounded-[6px] border-[1px] border-solid border-[#f0f0f0]" >
                <DocumentIcon />
            </a>
        }

    </div>)

}

const descriptionParser = (description, lang) => {

    let obj = {};


    if (typeof description === 'string') {
        try {
            obj = JSON.parse(description);
        }
        catch {
            return description
        }
    } else if (typeof description === 'object') {
        obj = description
    }
    let string = "";
    try {
        if (obj[lang]) {
            return obj[lang]
        } else {
            return obj[Object.keys(obj)[0]]
        }
    } catch {
        return ""
    }

}

const UniversalProductCard = ({ product, deliveries }) => {

    const { t } = useTranslation()

    const fastestAvability = getFastestAvability(product?.avability, deliveries)

    const otherSellers = getOtherSeller(product?.avability)

    const settings = useSelector(e => e.settings);

    const activeLang = settings?.Language;

    const desc = descriptionParser(product.Description, activeLang);

    const [modalVisibles, setModalVisibles] = React.useState({
        Oem: false,
        Documents: false,
        ApplicationList: false
    });

    return (
        <Card
            style={{ width: '100%', height: '100%', padding: '10px', position: 'relative' }}
            cover={
                <Image
                    alt={product.ProductReference1}
                    src={product?.Image || product.Photo || "https://material.parts-soft.net/image.php?brandId=-1&productreference=-1"}
                    style={{ objectFit: 'contain', height: '150px' }}
                    onError={(e) => {
                        e.target.src = 'https://material.parts-soft.net/image.php?brandId=-1&productreference=-1';
                    }}
                />
            }
            bodyStyle={{
                padding: '0px',
            }}
            actions={[
                <BuyAreav2 
                    externalInformation={{
                        Image: null,
                        Category: null
                    }}
                    deliveries={deliveries} 
                    otherSellers={otherSellers} 
                    onClickOtherSellers={() => (!0)} 
                    fastestAvability={fastestAvability} 
                />
            ]}
        >
            <Modal width={'100%'} title={t('application-list-title')} footer={null} open={modalVisibles.ApplicationList} onCancel={() => setModalVisibles({ ...modalVisibles, ApplicationList: false })}>
                <ApplicationListTecdoc onSelectVehicle={() => {
                    setModalVisibles({ ...modalVisibles, ApplicationList: false })
                }} reference={product.TecdocReference} brandId={product.TecdocBrandId} />
            </Modal>

            <OemModal modalVisibles={modalVisibles} setModalVisibles={setModalVisibles} oemData={product.OEM} />

            <Modal width={"100%"} footer={null} title={t('documents-title')} open={modalVisibles.Documents} onCancel={() => setModalVisibles({ ...modalVisibles, Documents: false })}>
                <ProductDocuments Documents={product.Documents} />
            </Modal>

            {product.TecdocArticleId ? <img
                style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    width: '50px',
                    height: '50px',
                    objectFit: 'contain'
                }}
                src="https://webshop.parts-soft.eu/tecdocIcon.png" /> : null}

            <Title level={4}> {product.BrandName || '\u00A0'}</Title>
            <Title level={5}> {product.CategoryName || '\u00A0'}</Title>
            <Descriptions column={1} size="small" bordered>
                <Descriptions.Item label={t('product-reference-label')}>
                    {product.ProductReference1}
                </Descriptions.Item>
                <Descriptions.Item label={t('description-label')}>
                    <div style={{ height: '40px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {desc || t('no-description-available')}
                    </div>
                </Descriptions.Item>

                <Descriptions.Item label="OEM">
                    {product?.OEM?.length ? <div>
                        <div>{product.OEM[0]?.articleNumber + ' ' + product.OEM[0]?.mfrName}</div>
                    </div> :
                        <div style={{ color: 'rgba(0, 0, 0, 0.25)', pointerEvents: 'none' }}>
                            {t('no-oem-available')}
                        </div>
                    }
                </Descriptions.Item>

                <Descriptions.Item label={t('minimum-label')}>
                    {product.MinimumOrderQuantity || 1}
                </Descriptions.Item>
            </Descriptions>

            <IconArea
                settings={settings}
                modalVisibles={modalVisibles}
                setModalVisibles={setModalVisibles}
                product={product}
            />
        </Card>
    );
};


export default UniversalProductCard;
