import { setTabs } from '@/store/tabs';
import { Button } from 'antd';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux'
import {CloseOutlined} from '@ant-design/icons'

const BackIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="none"
    {...props}
  >
    <path
      d="M3 7h11c1.87 0 2.804 0 3.5.402A3 3 0 0 1 18.598 8.5C19 9.196 19 10.13 19 12s0 2.804-.402 3.5a3 3 0 0 1-1.098 1.098C16.804 17 15.87 17 14 17H7M3 7l3-3M3 7l3 3"
      stroke={props.color ? props.color : "#4F719D"}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const AssistantBackButton = ({ Id }) => {
  const tabsStore = useSelector(e => e.tabs);
  const settings = useSelector(e => e.settings);
  const d = useDispatch()
  const { t } = useTranslation();
  const [tab, setTab] = useState(tabsStore.tabs.filter(item => item.id == Id)[0])

  useEffect(() => {
    setTab(tabsStore.tabs.filter(item => item.id == Id)[0])
  }, [tabsStore])

  const onClick = () => {
    let x = tabsStore.tabs
    let section = x.filter(item => item.id == Id)[0].section;
    let sc = {}
    switch (section) {
      case "makes":
        return;
        break;
      case "vehicleLine":
        sc = { ...tab, section: "makes" };
        break;
      case "vehicleSeries":
        sc = { ...tab, section: "vehicleLine" };
        break;

      case "vehicleMenu":
        sc = { ...tab, section: "vehicleSeries" };
        break;
      case "vehicleParts":
        sc = { ...tab, section: "vehicleMenu" };
        break;
    }

    d(setTabs(tabsStore.tabs.map(item => {
      if (item.id == Id) {
        return sc
      } else return item;
    })))
  }


  // const onClickCloseAllHandler = () =>{
  //   alert()
  // }

  return (
    <div className='flex justify-end absolute -top-8 right-2'>
      {
        tab.section != 'makes'
          ?
          // <Button className="" type="primary" onClick={onClick}>
          //   {t('back')}
          // </Button>
          <React.Fragment>
            
            <div className="mr-3 inline-flex h-[32px] items-center gap-[10px] px-[10px] py-[5px] relative rounded-[6px_6px_0px_0px] cursor-pointer" onClick={onClick} style={{ backgroundColor: `${settings['HeaderBgColor']}27` }}>
            <BackIcon color={settings['HeaderBgColor']} />
            <div className="relative w-fit mt-[-1.00px] [font-family:'Inter-Regular',Helvetica] font-normal text-[15px] tracking-[0] leading-[22px] whitespace-nowrap" style={{ color: settings['HeaderBgColor'] }}>
              {t('back')}
            </div>
          </div>

            {/* <div className="inline-flex h-[32px] items-center gap-[10px] px-[10px] py-[5px] relative rounded-[6px_6px_0px_0px] cursor-pointer" onClick={onClickCloseAllHandler} style={{ backgroundColor: `${settings['HeaderBgColor']}27` }}>
            <CloseOutlined style={{color : `${settings['HeaderBgColor']}`}}/>
            <div className="relative w-fit mt-[-1.00px] [font-family:'Inter-Regular',Helvetica] font-normal text-[15px] tracking-[0] leading-[22px] whitespace-nowrap" style={{ color: settings['HeaderBgColor'] }}>
              {t('close-all')}
            </div>
          </div> */}
          
          </React.Fragment>
          : null
      }
    </div>
  )
}

export default AssistantBackButton
