import React, { useEffect, useState } from 'react'
import api from '@/services/api'
const useFetchBrandsByGenericArticles = (genericArticleId) => {

    const [loading, setLoading] = useState(false);

    const [brands, setBrands] = useState([]);
    const [checkedBrands, setCheckedBrands] = useState([])

    const fetch = async () => {
        setLoading(true)
        let res = await api(1).post(`/catalog/admin-get-brands-from-generic-articles`, {
            genericArticleIds: JSON.stringify([genericArticleId])
        })
        setBrands(res.data.brands)
        setCheckedBrands(res.data.checkedBrands)
        setLoading(false)
    }

    useEffect(() => {
        fetch()
    }, [genericArticleId])

    return { loading, brands, checkedBrands, setCheckedBrands }

}

export default useFetchBrandsByGenericArticles