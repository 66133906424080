import tabs, { setActiveKey, setLastKey, setTabs } from '@/store/tabs';
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { message } from 'antd';
import { setShowMobileSearch } from '@/store/site';
import { showSearch } from '@/store/settings';
import { useLocation, useNavigate } from 'react-router-dom';
const useAddTab = () => {
    const tabsStore = useSelector(e => e.tabs);
    const navigate = useNavigate()
    const location = useLocation()

    const { t, i18n } = useTranslation();
    const d = useDispatch();
    const addTab = React.useCallback((props, redirect) => {
        let type = props?.type ? props?.type : "assistant";
        let section = props?.section ? props?.section : "makes";
        let make = props?.make ? props?.make : null;
        let makeName = props?.makeName ? props?.makeName : null;
        let vehicleLineId = props?.vehicleLineId ? props?.vehicleLineId : null;
        let vehicleLineName = props?.vehicleLineName ? props?.vehicleLineName : null;
        let vehicleSeriesName = props?.vehicleSeriesName ? props?.vehicleSeriesName : null;
        let vehicleMenuName = props?.vehicleMenuName ? props?.vehicleMenuName : null;
        let title = props?.title ? props?.title : null;
        let vehicleSeriesId = props?.vehicleSeriesId ? props?.vehicleSeriesId : null;
        let productReference = props?.productReference ? props?.productReference : null;
        let description = props?.description ? props?.description : null;
        let plate = props?.plate ? props?.plate : null;
        let tiresCode = props?.tiresCode ? props?.tiresCode : null;
        let selectedProductGroup = 0;
        let oem = props?.oem ? props?.oem : null;
        let lastKey = tabsStore.lastKey;
        let tabList = tabsStore.tabs
        let uniqId = parseInt(Math.random() * 10000000);
        let newLastKey = lastKey + 1;
        let searchDescription = props?.searchDescription || null;
        let newTab = {
            id: uniqId,
            key: newLastKey,
            title: title ? title : (t('assistant') + " -" + (newLastKey)),
            type,
            section,
            make,
            makeName,
            vehicleLineId,
            vehicleLineName,
            vehicleSeriesName,
            vehicleMenuName,
            vehicleSeriesId,
            productReference,
            description,
            source: props?.source ? props.source : "default",
            plate,
            tiresCode,
            selectedProductGroup,
            oem,
            searchDescription

        };
        d(setShowMobileSearch(false))
        d(showSearch(false))
        
        d(setTabs([...tabList, newTab]));
        d(setLastKey(newLastKey))
        d(setActiveKey(newLastKey))

        
        try {

            if(location.pathname != '/catalog')
              navigate('/catalog')
        } catch (e) {
            message.error("Page not redirect.")
        }
    



    }, [tabsStore,location?.pathname])

    return addTab;
}

export default useAddTab
