import React from 'react'

const PhoneIcon = (props) => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={17}
    viewBox="0 0 17 17"
    fill="none"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M11.373 11.46c-5.158 5.312-7.656 4.516-8.475 3.939-.131-.075-2.897-1.905-1.4-3.81C5.234 6.861 5.34 12.71 8.78 8.941c3.438-3.768-2.184-3.482 2.43-7.356 1.861-1.553 3.772 1.158 3.85 1.286.601.801 1.47 3.276-3.687 8.589Z"
      stroke={props.color || "#fff"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
  )
}

export default PhoneIcon