
import api from '../../services/api'

export default () => new Promise((resolve, reject) =>
    api()({
        method: 'GET',

        url: '/webshop/tires-service/getTiresGroups'
    }).then(e => {
        resolve(e.data.data)
    }).catch(f => {
        reject(f)
    })
) 