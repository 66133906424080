import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { deleteHistory, getHistory, insertHistory } from '../core/history/productHistory'
import { message } from 'antd'
const initialState = {
    historyList: [],
    loadingHistory: false,
    offset: 0,
    totalCount: 0
}

export const getProductHistoryList = createAsyncThunk(
    'productHistory/getProductHistoryList?limit=10',
    async (data, { getState }) => {
        const { offset } = getState().productHistory
        return await getHistory(offset);
    }
)

export const insertProductHistoryList = createAsyncThunk('productHistroy/insertProductHistoryList',

    async (data) => {
        let {ProductReference,Description,Photo,cb} = data;
       
        let create = await insertHistory(ProductReference,Description,Photo)
        if(cb){
            cb();
        }
        return create;
    })


export const deleteProductHistoryList = createAsyncThunk(
    'productHistory/deleteProductHistoryList',
    async (data, { getState }) => {
        return await deleteHistory(data.Id);
    }
)



export const productHistory = createSlice({
    name: 'productHistory',
    initialState,

    extraReducers: (builder) => {
    
        builder.addCase(getProductHistoryList.fulfilled, (state, action) => {
            state.historyList = action.payload.data;
            state.offset = action.payload.offset;
            state.totalCount = action.payload.totalCount;
            state.loadingHistory = false;
        })

        builder.addCase(getProductHistoryList.pending, (state, action) => {
            state.loadingHistory = true;
        })

        builder.addCase(getProductHistoryList.rejected, (state, action) => {
            state.loadingHistory = [];
        })


        builder.addCase(deleteProductHistoryList.fulfilled, (state, action) => {
            let qq = state.historyList.filter(e => e.Id != action.meta.arg.Id)
            state.historyList = qq
            state.totalCount = state.totalCount-1;

            //state.historyList = action.payload.data;
            //state.offset = action.payload.offset;
            //state.totalCount = action.payload.totalCount;
            //state.loadingHistory = false;
        })

        builder.addCase(deleteProductHistoryList.pending, (state, action) => {
            let findIndex = state.historyList.findIndex(e => e.Id == action.meta.arg.Id)
            if(findIndex == -1) return;
            state.historyList[findIndex].loading = true;
            //state.loadingHistory = true;
        })

        builder.addCase(deleteProductHistoryList.rejected, (state, action) => {
            message.error("Some think error :/");
            let findIndex = state.historyList.findIndex(e => e.Id == action.meta.arg.Id)
            if(findIndex == -1) return;
            state.historyList[findIndex].loading = false;
        })









    }
})



// Action creators are generated for each case reducer function
//export const { test } = history.actions
export default productHistory.reducer
