import React, { useEffect, useReducer } from 'react'


const initialValues = {
    products: [],
    originalProducts: [],
    filters: {
        ProductGroupName: [],
        BrandName: [],
        AldocPositions: []
    },
    ProductGroups: [],
    Brands: [],
    description: "",
    reference: "",
    loadingProducts: false,
    error: false,
    aldocPositions: [],
    showOnlyAvability: false,
    totalCount: 0,
    perPage: 20,
    currentPage: 1,
    filtersData : [],
    filterTree : {},
    deliveries : []
}

const reducer = (state, action) => {
    switch (action.type) {
        case "setProducts":
            return { ...state, products: action.payload }
        case "setLoadingProducts":
            return { ...state, loadingProducts: action.payload }
        case "setFilters":
            return { ...state, filters: action.payload }
        case "setProductGroups":
            return { ...state, ProductGroups: action.payload }
        case "setBrands":
            return { ...state, Brands: action.payload }
        case "setDescription":
            return { ...state, description: action.payload }
        case "setReference":
            return { ...state, reference: action.payload }
        case "setOriginalProducts":
            return { ...state, originalProducts: action.payload }
        case "setSayi":
            return { ...state, sayi: action.payload }
        case "setError":
            return { ...state, error: action.payload }
        case "setAldocPositions":
            return { ...state, aldocPositions: action.payload }
        case "setShowOnlyAvability":
            return { ...state, showOnlyAvability: action.payload }
        case "setTotalCount":
            return { ...state, totalCount: action.payload }
        case "setCurrentPage":
            return { ...state, currentPage: action.payload }
        case "setPerPage":
            return { ...state, perPage: action.payload }
        case "setFiltersData":
            return { ...state, filtersData: action.payload }
        case "setFilterTree":
            return {...state,filterTree:action.payload}
        case "setWholesalerDelivery":
            return {...state,wholesalerDelivery:action.payload}
        case "setDeliveries":
            return {...state,deliveries:action.payload}
        default:
            return state;
    }
};




const useProductApplicationReducer = () => {
    const [state, dispatch] = useReducer(reducer, initialValues);

    useEffect(() => {
        dispatch({ type: 'setTotalCount', payload: state.products.length })
        dispatch({ type: 'setCurrentPage', payload: 1 })
    }, [state.products])





    return { state, dispatch }
}

export default useProductApplicationReducer