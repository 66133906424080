import api from '@/services/api'
const control = (make) => new Promise((resolve, reject) => {
    api().get(`/webshop/auth-control`)
        .then((e) => {
            resolve(e.data)
        })
        .catch((e) => {
            
            reject(false)
        })

})


export default control;