import useAddTab from '@/hooks/assistant/useAddTab'
import VehiclePartsArea from '@/components/assistant/VehiclePartsArea/VehiclePartsArea';
import {Modal} from 'antd'
import React, { useEffect, useState } from 'react'

const ProductOEMList = ({ list }) => {

    const [show, setShow] = React.useState(false);
    const [selected, setSelected] = React.useState("");

    const [clearList,setClearList] = useState([]);


    useEffect(()=>{
        if(list && Array.isArray(list)){
            setClearList(list.filter((item,index)=>list.indexOf(item) === index))
        }
    },[list])

    const onClickHandler = (item) => {
        setSelected(item);
        setShow(true);
    }

    return (
        <React.Fragment>

            <Modal width={'100%'} open={show} onCancel={()=>setShow(false)} footer={null} title={"OEM : " + selected}  >
                <VehiclePartsArea  oem={selected} />
            </Modal>

            {clearList && Array.isArray(clearList) ? clearList.map((item, key) => <div key={key}>
                <a onClick={() => onClickHandler(item)} href="#" key={key} >{item}</a>
                <br />
            </div>) : null}

        </React.Fragment>
    )
}

export default ProductOEMList