import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { deleteHistory, getHistory, insertHistory } from '../core/history/history'
const initialState = {
    historyList: [],
    loadingHistory: false
}
const getHistoryList = createAsyncThunk(
    'history/getHistoryList?limit=10',
    async () => {
        return await getHistory();
    }
)

const insertHistoryList = createAsyncThunk('histroy/insertHistoryList',
    async (data,{dispatch}) => {
        let Plate = data.Plate;
        let Series = data.Series;
        const insert = await insertHistory(Plate, Series)
        dispatch(getHistoryList())
        return insert
    })

const deleteHistoryList = createAsyncThunk('histroy/deleteHistoryList',

    async () => {

        return await deleteHistory()
    })




export const history = createSlice({
    name: 'history',
    initialState,

    extraReducers: (builder) => {
        builder.addCase(getHistoryList.fulfilled, (state, action) => {
            if (typeof action.payload == 'object') {
                state.historyList = action.payload;
            } else state.historyList = [];

            state.loadingHistory = false;
        })

        builder.addCase(getHistoryList.pending, (state, action) => {
            state.loadingHistory = true;
        })

        builder.addCase(getHistoryList.rejected, (state, action) => {
            state.loadingHistory = [];
        })

        builder.addCase(insertHistoryList.fulfilled, (state, action) => {
            state.historyList = [...state.historyList, action.payload];
        })

        builder.addCase(insertHistoryList.pending, (state, action) => {

        })

        builder.addCase(insertHistoryList.rejected, (state, action) => {

        })

        builder.addCase(deleteHistoryList.fulfilled, (state, action) => {
            state.historyList = []
            state.loadingHistory = false;
        })


        builder.addCase(deleteHistoryList.pending, (state, action) => {
            state.loadingHistory = true;
        })







    }
})



// Action creators are generated for each case reducer function
//export const { test } = history.actions
export default history.reducer
export { getHistoryList }
export { insertHistoryList, deleteHistoryList }