import api from '../../services/api'

export default (TiresCode) => new Promise((resolve, reject) =>
    api()({
        method: 'POST',
        data: {
            TiresCode
        },
        url: '/webshop/tires-service/getTires'
    }).then(e => {
        resolve(e)
    }).catch(f => {
        reject(f)
    })
) 