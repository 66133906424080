import api from '../../services/api';

const insertHistory = (Plate,Series) => {
    let data = {};
    if(Series) data['Series'] = Series;
    if(Plate) data['Plate'] = Plate;
    
    return new Promise((resolve, reject) => {
        api()({
            url : '/catalog/history',
            data,
            method : 'POST'
        }).then((e) => {
            resolve(e.data.data)
        }).catch((err) => {
            resolve(false)
        })
    }
    )
}



const getHistory = () => {
    return new Promise((resolve, reject) => {
        api().get('/catalog/history').then((e) => {
            resolve(e.data.data)
        }).catch((err) => {
            resolve([])
        })
    }
    )
}

const deleteHistory = () => {
    return new Promise((resolve, reject) => {
        api().delete('/catalog/history').then((e) => {
            resolve(true)
        }).catch((err) => {
            resolve(false)
        })
    }
    )
}



export {getHistory,insertHistory,deleteHistory}