import React, { useEffect, useState } from "react";
import { Button, Card, Col, DatePicker, Form, Input, message, Modal, Row, Table } from "antd";
import { DownloadOutlined, SearchOutlined } from "@ant-design/icons";
import moment from 'moment'
import axios from "axios";
import transactions from "@/core/transactions/transactions";
import ShowDocument from "@/components/invoices/ShowDocument";
import { useTranslation } from "react-i18next";
import NumberRound from "number-zero-round";
import { useSelector } from "react-redux";


/**
 * 
 * @returns 
 * 1 -> Invoice
 * 2 -> Packing Slip
 * 3 -> Offer
 * 4 -> Verkbon
 * 
 * 
 */

const ArticleHistoryTable = () => {

    const [selectedDocumentNumber, setSelectedDocumentNumber] = useState(null)
    const [showDocumentModal, setShowDocumentModal] = useState(false)
    const [type, setType] = useState(null)
    const { t } = useTranslation();
    const columns = [
        {
            title: t('transaction-type'),
            dataIndex: "TransactionTypeId",
            key: "TransactionTypeId",
            sorter: (a, b) => a.TransactionTypeId - b.TransactionTypeId,
            render : (a,b) => {

                if(a == 1)
                    return t('invoice')
                else if(a == 2)
                    return t('packing-slip')
                else if(a == 3)
                    return t('offer')
                else if(a == 4)
                    return t('work-order')
                else
                    return "Unknown"
            }
        },
        {
            title: t('document-number'),
            dataIndex: "DocumentNumber",
            key: "DocumentNumber",
            sorter: (a, b) => a.DocumentNumber - b.DocumentNumber,
        },
        {
            title: t('invoice-date'),
            dataIndex: "DocumentDate",
            key: "DocumentDate",
            sorter: (a, b) => moment(a.DocumentDate).unix() - moment(b.DocumentDate).unix(),

        },
        {
            title: t('product-reference'),
            dataIndex: "ProductReference",
            key: "ProductReference",
            sorter: (a, b) => moment(a.DocumentDate).unix() - moment(b.DocumentDate).unix(),

        },
        {
            title: t('plate'),
            dataIndex: "LicensePlate",
            key: "LicensePlate",
            sorter: (a, b) => moment(a.DocumentDate).unix() - moment(b.DocumentDate).unix(),

        },
        {
            title: t('quantity'),
            dataIndex: "Quantity",
            key: "Quantity",
            sorter: (a, b) => moment(a.DocumentDate).unix() - moment(b.DocumentDate).unix(),
            render: (a, b) => NumberRound(a)

        },
        {
            title: t('gross-price'),
            dataIndex: "GrossPrice",
            key: "GrossPrice",
            sorter: (a, b) => moment(a.DocumentDate).unix() - moment(b.DocumentDate).unix(),
            render: (a, b) => '€' + NumberRound(a)

        },
        {
            title: t('net-price'),
            dataIndex: "NetPrice",
            key: "NetPrice",
            sorter: (a, b) => moment(a.DocumentDate).unix() - moment(b.DocumentDate).unix(),
            render: (a, b) => '€' + NumberRound(a)

        },
        {
            title: "",
            dataIndex: "",
            key: "download",
            render: (a, b) => {
                return (
                    <div onClick={() => {
                        setSelectedDocumentNumber(b.DocumentNumber)
                        setShowDocumentModal(true)
                        setType(b.TransactionTypeId)
                    }}>
                        <DownloadOutlined style={{ fontSize: "19px", color: "var(--primaryColor)", cursor: "pointer" }} />
                    </div>
                );
            },
        },
    ];


    const [data, setData] = useState([])
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false)


    const search = () => {

        let term = form.getFieldsValue()['term']
        if (term.length < 3)
            return message.info("Please field in minimum 3 character.")
        setLoading(true)
        transactions.searchArticleHistory(term)
            .then(e => {
                setData(e.data.data)
            }).catch(f => {
                message.error("Invoice info not fetching !")
            }).finally(() => setLoading(false))


    }


    const session = useSelector(e => e?.session);



    useEffect(()=>{
        form.getFieldsValue()['term'] && search()
    },[session?.relationId])

    return (
        <div>
            <Modal bodyStyle={{ height: '1000px', padding: 0 }} open={showDocumentModal} footer={null} width="100%" onCancel={() => setShowDocumentModal(false)}>
                <ShowDocument documentNumber={selectedDocumentNumber} type={type} />
            </Modal>
            <Form layout="vertical" form={form} onFinish={search} >
                <Form.Item name="term" label={`${t('article')},${t('plate')} `}>
                    <div className="flex">
                        <Input />
                        <button type="submit" className="mega-button ml-2">Search</button>
                    </div>
                </Form.Item>
            </Form>
            <Table uid={"DocumentNumber"} loading={loading} size="small" dataSource={data} className="table-2" columns={columns} />
        </div>
    );
};

export default ArticleHistoryTable;
