import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RightOutlined } from "@ant-design/icons";
import useAddTab from '@/hooks/assistant/useAddTab';
import { Avatar, List, Spin } from "antd";
import helpers from "@/helpers/helpers";
const ResultProduct = ({ reducer }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const addTab = useAddTab()
  const text = reducer.state.text;
  const productResults = reducer.state.productResults;
  useEffect(() => {
    setShow(text.length > 2 ? true : false);
  }, []);

  const onClickHandler = (e) => {
    reducer.insertSearchProductHistory(e)

    addTab({
      type: 'CarParts',
      productReference: helpers.onTrimProductReference(e.ProductReference1)
    }, 'redirect')
  };


  return (
    <>
      <p style={{ color: "#A5A5A5", fontSize: "20px" }}>{t("product")}</p>
      {show ? (
        <div className="mt-5">


          {(reducer.state.productResultLoading) ? <div style={{ width: '100%', height: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Spin /></div> : null}

          {reducer.state.productResults.length ? <List
            itemLayout="horizontal"
            dataSource={productResults}
            loading={reducer.state.productResultLoading}
            renderItem={(item) => (
              <List.Item onClick={() => onClickHandler(item)} >
                <List.Item.Meta
                  avatar={<Avatar src={item.Photo} />}
                  title={<a>{item.ProductReference1}</a>}
                  description={item.Description}
                />
              </List.Item>
            )}
          /> : null}

          <div className="flex items-center cursor-pointer" onClick={() => onClickHandler({ ProductReference1: text })}>
            {<RightOutlined />}
            {<p style={{ fontWeight: 500, fontSize: "13px", padding: 0 }} className="ml-2">
              {t('search')} :<a style={{ color: "#2b94c8" }}>{text.toUpperCase()}</a>
            </p>}
          </div>
          <div className="flex items-center mt-3">
            {<RightOutlined />}
            <p style={{ fontWeight: 500, fontSize: "13px", padding: 0 }} className="ml-2">
              {'Zoek op Beschrijving'} :<a onClick={() => {
                reducer.searchByDescription(reducer.state.text)
              }} style={{ color: "#2b94c8" }}>{text}</a>
            </p>
          </div>
        </div>
      ) : null}



    </>
  );
};

export default ResultProduct;
