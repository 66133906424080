const CrossIcon = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={19}
      height={16}
      viewBox="0 0 19 16"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.14 1.758a6.402 6.402 0 0 1 5.941 1.718l.014.013L16.6 5.844h-3.153a.6.6 0 1 0 0 1.2h4.668a.6.6 0 0 0 .6-.6V1.776a.6.6 0 1 0-1.2 0v3.282L14.923 2.62A7.602 7.602 0 0 0 2.387 5.466a.6.6 0 0 0 1.131.4 6.402 6.402 0 0 1 4.623-4.108ZM.763 9.005a.598.598 0 0 0-.362.551v4.668a.6.6 0 1 0 1.2 0v-3.28l2.592 2.435a7.601 7.601 0 0 0 12.536-2.845.6.6 0 1 0-1.13-.4 6.4 6.4 0 0 1-10.564 2.39l-.013-.013-2.506-2.355h3.153a.6.6 0 0 0 0-1.2H.998a.601.601 0 0 0-.236.05Z"
        fill={props.color || "#8D8D8D"}
      />
    </svg>
  )


  export default CrossIcon