const { default: jwtDecode } = require("jwt-decode")

const getCatalogName = () => {

    try {
        let a = jwtDecode(window?.REST_API_TOKEN)
        return a?.CatalogName
    } catch (e) {
        return null
    }

}

export default getCatalogName;