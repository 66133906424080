import { Alert, Button, List } from 'antd'
import React from 'react'

const SearchValidation = ({ motorCodes,onClickMotorCode }) => {
    return (
        <div>

            <Alert
                message="meerdere motorcodes gevonden. Gelieve te kiezen"
                type="warning"
            />
                {motorCodes.others.map((item, key) => <p><Button onClick={()=>onClickMotorCode({motorCode : item,line : motorCodes.line})} className='mt-3'>{item}</Button></p>)}

        </div>
    )
}

export default SearchValidation