import api from '@/services/api'
export default (ProductReference,BrandId) => new Promise((resolve, reject) => {
    api().post('/webshop/description-service/group-name', {
        ProductReference,
        BrandId
    }).then(res => {
        resolve(res?.data?.data?.ProductGroupName)
    }).catch(f => {
        reject(f)
    })
})