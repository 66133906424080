import VehicleMenuItem from "./VehicleMenuItem";
// import IconMatch from "./IconMatch";

const Header = ({ title, MenuCode }) => {
  return (
    <div className="car-menu-list__column__card__header">
      <p className="car-menu-list__column__card__header__title">{title}</p>
      <div className="car-menu-list__column__card__header__img">
      </div>
    </div>
  );
};
const VehicleMenuGroup = ({ items, ColumnId, title, onClick, MenuCode, t }) => {
  return (
    <>
      <div className="car-menu-list__column__card">
        <Header title={title} MenuCode={MenuCode} />
        <div className="car-menu-list__column__card__body">{typeof items == "object" ? items.map((item,key) => <VehicleMenuItem key={key} icon={item.Icon.IconName} onClick={(a,b)=>onClick(item.MenuCode,b)} text={item.Menu} />) : null}</div>
      </div>
    </>
  );
};

export default VehicleMenuGroup;
