import React from 'react'

const ProductDocuments = ({Documents}) => {
  return (
    <div>
      {Documents.map((item, key) => (
        <div>
          <a href={item} target="__blank" key={key}>
            {item}
          </a>
        </div>
      ))}
    </div>
  )
}

export default ProductDocuments