import PackingSlipTable from '@/components/account/PackingSlipTable'
import React, { useTransition } from 'react'
import AccountTitle from '@/layouts/AccountLayout/AccountTitle'
import { useTranslation } from 'react-i18next';

const PackingSlip = () => {
    
    const {t} = useTranslation()
    return (
    <div>
        <AccountTitle title={t('packing-slips')}/>
        <PackingSlipTable/>
    </div>
  )
}

export default PackingSlip


