  import React from 'react'
import { useSelector } from 'react-redux'
  
  const CounterButton = ({value,onChange}) => {
    const bg = useSelector(e => e.settings['HeaderBgColor'])

    return (
        <div style={{
          border : '1px solid ' + bg
        }} className=" flex items-center justify-around p-[5px] relative rounded-[6px] w-[100%]">
        <div className='cursor-pointer' onClick={()=>{
            onChange('decrease')
        }}>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2.4 8.16h11" stroke={bg} strokeWidth="2" strokeLinecap="round" />
        </svg>
        </div>
    
        <input style={{
          color : bg
        }} type="text" className="text-center w-12 text-center font-['Poppins-Regular',_sans-serif] text-[14px] font-normal relative flex items-center justify-center focus:outline-none focus:none focus:none focus:none bg-transparent border-0" value={value} onChange={(e)=>{
          onChange('change',e.target.value)
        }} />
    
        <div onClick={()=>{
            onChange('increment')
        }} className='cursor-pointer'>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2.4 8.16h11.52m-5.76 5.76V2.4" stroke={bg} strokeWidth="2" strokeLinecap="round" />
        </svg>
        </div>
      </div>
    )
  }
  
  export default CounterButton