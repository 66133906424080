import React, { useEffect, useState } from 'react'
import { arrayMoveImmutable } from 'array-move';
import CategoryListSortable from '@/components/tecdocSettings/TecdocMenuCreate/CategoryList';
import { Breadcrumb, Button, Skeleton } from 'antd';
import CreateMainCategory from '@/components/tecdocSettings/TecdocMenuCreate/CreateMainCategory';
import api from '@/services/api';
import useFetchCategories from '@/hooks/adminTecdoc/useFetchCategories';
import useCreateCategory from '@/hooks/adminTecdoc/useCreateCategory';
import useChangeSort from '@/hooks/adminTecdoc/useChangeSort';
import { Link, useNavigate, useParams } from 'react-router-dom';
import DispatchGenericArticleToCategory from '@/components/tecdocSettings/TecdocMenuCreate/DispatchGenericArticleToCategory';
import useUpdateCategory from '@/hooks/adminTecdoc/useUpdateCategory';











const AddTecdocCategories = () => {

    

    const { subcategoryId,categoryId } = useParams()

    const [category, setCategory] = useState({})
    const [subCategory, setSubcategory] = useState([])

    const up1 = useUpdateCategory()
    const up2 = useUpdateCategory()

    useEffect(() => {
        up1.fetch(subcategoryId,categoryId).then(e => {
            setCategory(e)
        })
    }, [subcategoryId,categoryId])

    useEffect(() => {
        up2.fetch(categoryId).then(e => {
            setSubcategory(e)
        })
    }, [categoryId])



    const fetchCategories = useFetchCategories(subcategoryId);
    const changeSort = useChangeSort();
    const navigate = useNavigate()
    const onSortEnd = ({ oldIndex, newIndex }) => {
        let newList = arrayMoveImmutable(fetchCategories.categories, oldIndex, newIndex)
        fetchCategories.setCategories(newList);
        let ids = newList.map((item, index) => {
            return [item?.id, index]
        })
        changeSort.changeSort(ids)
    };

    const [showCreateModal, setShowCreateModal] = useState(false)


    const onClickDeleteHandler = (id) => {
        fetchCategories.setCategories(prev => prev.filter(e => e?.id !== id))
    }


    const onUpdateHandler = (e) => {
        fetchCategories.setCategories(prev => prev.map(f => {
            if (f?.id === e?.id) {
                return e
            }
            return f
        }))
    }


    const onCreateHandler = (e) => {
        fetchCategories.setCategories(prev => [...prev, e])
    }


    const onClickHandler = (e) => {
         navigate(`/tecdoc/menu-create/brands/subcategory/${subcategoryId}/${e?.genericArticleId}/`)
    }



    return (
        <Skeleton loading={fetchCategories.loading}>
            <DispatchGenericArticleToCategory onCreate={onCreateHandler} categoryId={subcategoryId} show={showCreateModal} onCancel={() => setShowCreateModal(false)} />
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}>

                <div>
                    <Breadcrumb>
                        <Breadcrumb.Item><Link to={'/tecdoc/menu-create'}>Categories</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to={`/tecdoc/menu-create/categories/${categoryId}`}>{subCategory?.categoryName}</Link>
                            
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <a href="#">{category?.categoryName}</a>
                        </Breadcrumb.Item>


                    </Breadcrumb>
                </div>

                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                }}>
                    <Button onClick={() => setShowCreateModal(true)} type='primary'>New Category</Button>
                </div>






            </div>



            <div className='mt-3'>
                <CategoryListSortable showSubCategory={false} hideEdit={true} onClickItem={onClickHandler} onUpdate={onUpdateHandler} onClickDelete={onClickDeleteHandler} items={fetchCategories.categories} onSortEnd={onSortEnd} />
            </div>


        </Skeleton>
    )
}

export default AddTecdocCategories