const CopyIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
      <rect x={-0.6} y={0.6} width={11.752} height={13.276} rx={3.4} transform="matrix(-1 0 0 1 11.752 1.524)" stroke="#A4A4A4" strokeWidth={1.2} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.238 1.2H6.286a2.78 2.78 0 0 0-1.308.324H4a4.01 4.01 0 0 0-.94.111A3.994 3.994 0 0 1 6.286 0h4.952a4 4 0 0 1 4 4v6.476a4.002 4.002 0 0 1-3.06 3.889 3.981 3.981 0 0 0 .706-1.623 2.796 2.796 0 0 0 1.154-2.266V4a2.8 2.8 0 0 0-2.8-2.8Z"
        fill="#A4A4A4"
      />
    </svg>
  )

  export default CopyIcon