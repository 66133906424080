import React, { useTransition } from "react";
import { UserOutlined, HistoryOutlined, CreditCardOutlined,SettingOutlined } from "@ant-design/icons";
import { Menu, Row, Col, Card, Divider } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

//getItem(t('unpaid') + (' ') + t('invoices'), "unpaided"),

const AccountSidebar = ({ element }) => {

  const navigate = useNavigate();
  const { t } = useTranslation()
  const handleClick = (e) => {
    navigate('/account/' + e.key);
  };

  const items = [
    getItem(t('settings'), "settings", <SettingOutlined />),
    getItem(t('profile'), "profile", <UserOutlined />),
    getItem(t('history'), "article-history", <HistoryOutlined />),
  
    getItem(t('order-history'), "order-history", <CreditCardOutlined />,
      [
        getItem(t('invoices'), "invoices", null,
          [
            getItem(t('all'), 'invoices'),
            getItem(t('unpaided'), 'unpaid-invoices'),
            getItem(t('paided'), 'paided-invoices')
          ]),
        ,
        getItem(t('packing-slips'), "packing-slips")
      ]),
      
    
    ]; // submenu keys of first level



  return (
    <div className="profile-sidebar-menu">
      <Menu
        onClick={handleClick}
        mode="inline"
        style={{
          width: "100%",
        }}
        items={items}
      />
    </div>
  );
};

export default AccountSidebar;
