import TransactionsTable from '@/components/account/TransactionsTable'
import React from 'react';
import AccountTitle from '@/layouts/AccountLayout/AccountTitle'
import { useTranslation } from 'react-i18next';

const Invoices = () => {
    const {t} = useTranslation();
    return (
        <div>
            <AccountTitle title={t('invoices')} />

            <TransactionsTable paid={1} />

        </div>
    )
}






export default Invoices



