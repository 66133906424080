import api from '@/services/api'
export default (lineId) => {



    return new Promise((resolve, reject) => {
        api().get(`/catalog/vehicleSeriesList?LineCode=${encodeURIComponent(lineId)}`)
            .then((e) => {
                if (e.data.status == 'true') {

                    if (!e.data.data)
                        resolve([])
                    
                    resolve(e.data.data)
                }

                else reject(false);
            })
            .catch((e) => {
                reject(false)
            })

    })

}