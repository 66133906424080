
const BasketIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={16} viewBox="0 0 20 16" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.018.538C.018.241.258 0 .556 0h2.395c.738 0 1.382.5 1.565 1.214l2.277 8.881a.539.539 0 0 0 .522.405h8.897c.23 0 .434-.146.509-.363l1.95-5.654a.539.539 0 0 0-.51-.714H8.633a.538.538 0 1 1 0-1.077h9.528a1.615 1.615 0 0 1 1.527 2.143l-1.95 5.653a1.615 1.615 0 0 1-1.527 1.089H7.315a1.615 1.615 0 0 1-1.565-1.214L3.473 1.481a.538.538 0 0 0-.522-.405H.556A.539.539 0 0 1 .018.538Zm8 15.461a1.54 1.54 0 1 0 0-3.076 1.539 1.539 0 0 0 0 3.076Zm9.077-1.538a1.539 1.539 0 1 1-3.078 0 1.539 1.539 0 0 1 3.078 0Z"
        fill="#fff"
        {...props}
      />
    </svg>
  )


  export default BasketIcon