import useGetGenericArticles from '@/hooks/adminTecdoc/useGetGenericArticles'
import { Button, Input, message, Modal, Select, Skeleton, Typography } from 'antd'
import React, { useEffect } from 'react'
import { CheckOutlined } from '@ant-design/icons'
import useCreateCategory from '@/hooks/adminTecdoc/useCreateCategory'

const DispatchGenericArticleToCategory = ({ onCreate,show, onCancel, categoryId }) => {

    const fetchGenericArticles = useGetGenericArticles();
    const genericArticleId = useCreateCategory();
    const onSelectHandler = (id) => {
        let find = fetchGenericArticles.addedGenerics.find(x => x.parentId == categoryId && x.genericArticleId == id)


        if (find) {
            message.error("This generic article already added")
            return
        }

        const data = fetchGenericArticles.data.find(e => e?.genericArticleId === id)
        genericArticleId.create(data?.designation, categoryId, id).then(e => {
            console.log(e)
            message.success("Success")
            onCreate && onCreate(e)
            onCancel()
            fetchGenericArticles.setAddedGenerics([...fetchGenericArticles.addedGenerics, {
                id : e?.id,
                parentId : categoryId,
                genericArticleId : id
            }])

        }).catch(f => {
            message.error(f?.response?.data?.message || "Error")
        })
    }
    useEffect(() => {

        fetchGenericArticles.fetch()
    }, [])



    return (
        <Modal footer={null} title="New generic articles" width={'40%'} open={show} onCancel={onCancel}>

            <Skeleton loading={fetchGenericArticles.loading}>
                <Select style={{
                    width: '100%'
                }}
                    onSelect={onSelectHandler}
                    showSearch
                    placeholder="Select a category"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        (option?.text ?? '').toLowerCase().includes(input.toLowerCase())
                    }

                    options={fetchGenericArticles.data.map(e => ({
                        label: <div style={
                            {
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'

                            }
                        }>
                            <Typography.Text> {e?.designation + ` (${e?.genericArticleId})` }</Typography.Text>
                            {
                                fetchGenericArticles.addedGenerics.find(x => x.parentId == categoryId && x.genericArticleId == e?.genericArticleId) && <CheckOutlined />
                            }

                        </div>,
                        value: e?.genericArticleId,
                        text: e?.designation + ` (${e?.genericArticleId})` 
                    }))}


                    size='large' />
            </Skeleton>

        </Modal>
    )
}

export default DispatchGenericArticleToCategory