import useAddTab from '@/hooks/assistant/useAddTab'
import searchProductService from '@/core/product/searchProduct';
import searchVehicle from '@/core/vehicle/searchVehicleFromPlate';
import { insertProductHistoryList } from '@/store/productHistory';
import { ValidatorFactory } from '@randock/license-plate-validator';
import React, { useEffect, useReducer } from 'react'
import { useDispatch } from 'react-redux';
import vinValidate from '@vroomlabs/vin-validate'
const validator = ValidatorFactory.forCountry("NL");
let _searchTimeProduct = null;

const getType = (text) => {

  let isKBA = text?.length == 7
  let isVIN = vinValidate.vinValidate(text);
  let isPlate = validator.validate(text);
  if (isKBA) {
    return { isKBA: isKBA, isPlate: false };
  }
  else if (isVIN) {
    return { isVIN: isVIN, isPlate: false };
  } else if (isPlate) {
    return { isVIN: false, isPlate: true };
  } else {
    return { isVIN: false, isPlate: false };
  }

};



const initial = {
  text: "",
  isPlate: false,
  isProduct: false,
  description: "",
  plate: "",
  plateList: [],
  productResults: [],
  productResultLoading: false,
  isVINN: false,
  vinnNumber: null,
  isKBA : false
}

const reducer = (state, action) => {
  switch (action.type) {
    case "setText":
      return { ...state, text: action.payload }
    case "setIsPlate":
      return { ...state, isPlate: action.payload }
    case "setIsProduct":
      return { ...state, isProduct: action.payload }
    case "setIsVIN":
      return { ...state, isVIN: action.payload }
    case "setIsKBA":
      return { ...state, isKBA: action.payload }
    case "setDescription":
      return { ...state, description: action.payload }
    case "setPlate":
      return { ...state, plate: action.payload }
    case "setPlateList":
      return { ...state, plateList: action.payload }
    case "setProductResults":
      return { ...state, productResults: action.payload }
    case "setProductResultLoading":
      return { ...state, productResultLoading: action.payload }
    case "setVinnNumber":
      return { ...state, vinnNumber: action.payload }



    default:
      return state;
  }
};

const useSearchReducer = () => {
  const [state, dispatch] = useReducer(reducer, initial);
  const d = useDispatch()
  const addTab = useAddTab();

  const searchProduct = (e) => addTab({
    type: 'CarParts',
    productReference: e.toUpperCase(),
    title: e
  }, 'redirect')


  const searchPlate = (item,plate) => {
    addTab({
      vehicleLineId: item.VehicleLineId,
      vehicleLineLineName: item.VehicleLineName,
      section: "vehicleMenu",
      make: item.VehicleMakeId,
      plate: state.text || plate,
      makeName: item.VehicleMakeName,
      type: "assistant",
      vehicleSeriesName: item.VehicleReferenceName,
      vehicleSeriesId: item.VehicleReferenceId,
      title: item.VehicleMakeName + " " + item.VehicleLineName + " " + item.VehicleReferenceName + " / " + state.text.toUpperCase()
    }, 'redirect')

  };


  const searchByDescription = (e) => {
    addTab({
      type: 'CarParts',
      searchDescription:e,
      title: e
    }, 'redirect')

  }


  const searchProductAction = (text, callback) => setTimeout(() => {
    dispatch({ type: "setProductResultLoading", payload: true })
    searchProductService(text).then(e => {
      callback(null, e)
    }).catch(f => {
      callback(true)
    }).finally(() => dispatch({ type: "setProductResultLoading", payload: false }))
  }, 300)



  const insertSearchProductHistory = (data) => {

    if (data) {

    } else if (state.productResults.length) {
      data = state.productResults[0]
    } else {
      return
    }

    let description = data.Description;
    let reference = data.ProductReference1;
    let img = data.Photo
    d(insertProductHistoryList({
      Description: description,
      ProductReference: reference,
      Photo: img,
      cb: () => {

        // d(getProductHistoryList())
      }
    }))


  }



  useEffect(() => {

    let { isVIN, isPlate, isKBA } = getType(state.text);

    dispatch({ type: 'setIsPlate', payload: isPlate })

    if (isKBA) {
      dispatch({ type: 'setIsKBA', payload: true })
      dispatch({ type: 'setVinnNumber', payload: state.text })
    }
    else if (isVIN) {
      dispatch({ type: 'setIsVIN', payload: true })
      dispatch({ type: 'setVinnNumber', payload: state.text })
    } else {
      dispatch({ type: 'setIsVIN', payload: false })
      dispatch({ type: 'setVinnNumber', payload: null })
    }


    if (isVIN) {
      //some action
    } else if (isPlate) {
      //some action
      searchVehicle(state.text).then(e => {
        dispatch({ type: 'setPlateList', payload: e })
      }).catch(f => { }).finally(() => { })
    } else if (state.text.length > 2) {
      //    searchProductService(state.text)
      clearTimeout(_searchTimeProduct);
      _searchTimeProduct = searchProductAction(state.text, (err, data) => {
        if (!err) {
          dispatch({ type: "setProductResults", payload: data })
        }
      })
      //Ürün arama işlemi başlat
    } else {
      dispatch({ type: "setProductResults", payload: [] })

    }


  }, [state.text])













  return { state, dispatch, searchProduct, searchPlate, insertSearchProductHistory,searchByDescription }
}

export default useSearchReducer