import React, { useEffect, useRef, useState } from 'react'
import { Button, Divider, Form, message, Select, Spin } from 'antd';
import fetchMakes from '@/core/vehicle/fetchVehicleMakes';
import fetchLine from '@/core/vehicle/fetchVehicleLines';
import fetchSeries from '@/core/vehicle/fetchVehicleSeries';
import { SearchOutlined } from '@ant-design/icons'
import useAddTab from '@/hooks/assistant/useAddTab';
import { ValidatorFactory } from '@randock/license-plate-validator';
import searchVehicle from '@/core/vehicle/searchVehicleFromPlate';
import { useTranslation } from 'react-i18next';

const validator = ValidatorFactory.forCountry('NL');

const { Option } = Select;


const kwToHp = (kw) => {
    return Math.round(kw * 1.34102209)
}

const getPower = item => {

    let str = ""

    if (item.KWCat) {
        str = '(' + kwToHp(item.KWCat) + " HP" + ')'
    }

    return str

}

const VehicleSelectorModal = ({ onSelectMotor }) => {

    const { t } = useTranslation()

    const [form] = Form.useForm();
    const [make, setMake] = useState(false);
    const [model, setModel] = useState(false);
    const [motor, setMotor] = useState(false);

    const [loadingPlate, setLoadingPlate] = useState(false);

    const [disabledModel, setDisabledModel] = useState(true);
    const [disabledMotor, setDisabledMotor] = useState(true);
    const [disabledSearch, setDisabledSearch] = useState(true);

    const [makeList, setMakeList] = useState([])
    const [modelList, setModelList] = useState([])
    const [motorList, setMotorList] = useState([])
    const [loadingList, setLoadingList] = useState({
        make: false,
        model: false,
        motor: false
    })

    const [plate, setPlate] = useState(null)

    const addTab = useAddTab()

    const refSearchButton = useRef();

    const onFinish = () => {
        let _make = makeList.find(item => item.CatalogMakeCode == make)
        let _model = modelList.find(item => item.VehicleMakeId == model)
        let _motor = motorList.find(item => item.VehicleReferenceId == motor)

        addTab({
            vehicleLineId: _model.VehicleMakeId,
            vehicleLineLineName: _model.Line,
            section: "vehicleMenu",
            make: make,
            plate: null,
            makeName: _make.Name,
            type: "assistant",
            vehicleSeriesName: _motor.VehicleReferenceName,
            vehicleSeriesId: _motor.VehicleReferenceId,
            title: _make.Name + " " + _motor.VehicleReferenceName
        }, 'redirect')
        onSelectMotor(motor)
    }

    const handlerFormChange = (type, value) => {


        if (type == 'make') {
            setModel(undefined);
            setMotor(undefined);
            setMake(value);
            setDisabledModel(false)
            setDisabledMotor(true);
            setDisabledSearch(true);

            setLoadingList({ ...loadingList, model: true })
            fetchLine(value).then(e => {
                setModelList(e)
            }).catch(f => {


            }).finally(() => setLoadingList({ ...loadingList, model: false }))




        } else if (type == 'model') {
            setModel(value)
            setMotor(undefined);
            setDisabledMotor(false);
            setDisabledSearch(true);
            setLoadingList({ ...loadingList, model: true })
            fetchSeries(value).then(e => {
                setMotorList(e)
            }).catch(f => {


            }).finally(() => setLoadingList({ ...loadingList, model: false }))


        } else if (type == 'motor') {
            setMotor(value)
            refSearchButton.current.focus();
            setDisabledSearch(false)
        }

    }


    useEffect(() => {
        setLoadingList({ ...loadingList, make: true })
        fetchMakes().then(e => {
            setMakeList(e)
        }).catch(f => {


        }).finally(() => setLoadingList({ ...loadingList, make: false }))
    }, [])



    const searchPlate = (e) => {
        if (plate && plate.length) {


            if (validator.validate(plate)) {

                setLoadingPlate(true)
                searchVehicle(plate).then(e => {
                    if (e && e.length) {
                        let data = e[0];
                        addTab({
                            vehicleLineId: data.VehicleLineId,
                            vehicleLineLineName: data.VehicleLineName,
                            section: "vehicleMenu",
                            make: data.VehicleMakeId,
                            plate: plate,
                            makeName: data.VehicleMakeName,
                            type: "assistant",
                            vehicleSeriesName: data.VehicleReferenceName,
                            vehicleSeriesId: data.VehicleReferenceId,
                            title: data.VehicleMakeName + " " + data.VehicleLineName
                        }, 'redirect')
                        onSelectMotor()
                    } else {
                        message.error('Vehicle not found.')
                    }

                }).catch(f => {

                }).finally(() => setLoadingPlate(false))
            } else {
                message.error("Invalid plate")
            }





        }
    }



    return (
        <div className=''>

            <Form labelCol={{
                span: 3,
            }}
                wrapperCol={{
                    span: 21,
                }}
                onFinish={onFinish}
            >
                <Form.Item label={t('brand')} >
                    <Select
                        placeholder={t('search-to-make')}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        onChange={(e) => handlerFormChange('make', e)}
                        loading={loadingList.make}
                    >
                        {makeList.map((item, key) => <Option key={item.CatalogMakeCode} value={item.CatalogMakeCode}>{item.Name}</Option>)}
                    </Select>
                </Form.Item>


                <Form.Item label={t('model')} >
                    <Select
                        showSearch
                        placeholder={t('search-to-model')}
                        optionFilterProp="children"
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        disabled={disabledModel}
                        onChange={(e) => handlerFormChange('model', e)}
                        loading={loadingList.model}

                    >
                        {modelList.map((item, key) => <Option key={item.VehicleMakeId} value={item.VehicleMakeId}>{item.Line}</Option>)}

                    </Select>
                </Form.Item>

                <Form.Item label={t('series')}>
                    <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) => option.children.includes(input)}
                        // filterSort={(optionA, optionB) =>
                        //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        // }
                        onChange={(e) => handlerFormChange('motor', e)}
                        disabled={disabledMotor}

                    >
                        {console.log(motorList)}
                        {motorList.map((item, key) => <Option key={item.VehicleReferenceId} value={item.VehicleReferenceId}>{item?.Remark + ' ' + item.EngineType + ' ' + getPower(item)}</Option>)}
                    </Select>
                </Form.Item>

                <Form.Item label={t('search')}>
                    <Button ref={refSearchButton} htmlType='submit' className='w-full' disabled={disabledSearch} type={disabledSearch ? '' : 'primary'} icon={<SearchOutlined />} />
                </Form.Item>
            </Form>


            <Divider />


            <Spin spinning={loadingPlate}>
                <div className="kenteken">
                    <div className="inset">
                        <div className="blue"></div>
                        <input onKeyDown={(e) => {
                            if (e.key == 'Enter') searchPlate()
                        }} type="text" placeholder="" onChange={(e) => setPlate(e.target.value)} value={plate} />
                    </div>
                </div>
            </Spin>







        </div>

    )
}

export default VehicleSelectorModal
