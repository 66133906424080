import React, { useEffect, useState } from 'react'
import { Image as ImgAnt } from 'antd'

const UpButton = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={10}
    viewBox="0 0 18 10"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.703 9.716a1 1 0 0 1-1.414-.009L9.08 2.417l-7.207 7.29A1 1 0 0 1 .45 8.301L8.37.291a1 1 0 0 1 1.421 0l7.92 8.01a1 1 0 0 1-.009 1.415Z"
      fill="#C8C8C8"
    />
  </svg>
);

const DownButton = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={10}
    viewBox="0 0 18 10"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.46.284a1 1 0 0 1 1.414.008l7.207 7.29L16.29.292a1 1 0 0 1 1.422 1.406l-7.919 8.01a1 1 0 0 1-1.422 0l-7.92-8.01A1 1 0 0 1 .46.284Z"
      fill="#C8C8C8"
    />
  </svg>
);

const BasketItemCollapseHeader = ({ Reference, Image, BrandName, Plate, Description, BrandId }) => {

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(true)
  }, [])



  return (
    <div className='basket-item-collapse-header flex flex-row justify-between p-[10px]' onClick={() => setOpen(!open)}>
      <div className='flex flex-row gap-[10px]'>
        <div className='basket-item-collapse-header__image-container'>
          <ImgAnt style={{
            maxWidth: '50px',
            maxHeight: '50px',
            objectFit: 'contain',
          }} onError={e => e.target.src = 'https://material.parts-soft.net/image.php?brandId=0&productreference=0'} width={'100%'} src={Image} />
        </div>

        <div className='basket-item-collapse-header__product-info'>
          {/* <div className='basket-item-collapse-header__product-info__text'>
            {BrandName}
          </div> */}
          <div className="inline-flex items-center gap-[5px] p-[3px] relative flex-[0_0_auto] bg-[#f6f7f9] rounded-[6px] overflow-hidden">
            <div className="inline-flex flex-col items-start p-[3px] relative flex-[0_0_auto] bg-white rounded-[3px] overflow-hidden">
              <img
                className="w-full h-[10px] relative object-cover"
                alt="Image"
                src={`https://aksamlar.com/public/brands-aksamlar/${BrandId}.png`}
                onError={(e) => {
                  e.target.src = 'https://material.parts-soft.net/image.php?brandId=-1&productreference=-1';
                }}
              />
            </div>
            <div className="basket-item-collapse-header__product-info__text__brand">
              {BrandName}
            </div>
          </div>


          <div className='basket-item-collapse-header__product-info__text__reference'>
            {Reference}
          </div>
          <div className='basket-item-collapse-header__product-info__text__description'>
            {Description}
          </div>
        </div>
      </div>

      <div className='flex flex-row gap-[10px]'>
        <div className='flex flex-col justify-center'>{Plate &&

          <div className="inline-flex flex-row items-center p-[5px] relative flex-[0_0_auto] bg-[#ffc501] rounded-[3px] overflow-hidden">
            <div className="relative w-fit [font-family:'Poppins-Bold',Helvetica] font-bold text-[#333333] text-[11px] tracking-[0] leading-[normal]">
              {Plate?.toString().toUpperCase().replaceAll(' ', '').replaceAll('-', '')}
            </div>
          </div>
        }</div>

        <div className='flex flex-col justify-center'>
          {open ? <UpButton /> : <DownButton />}
        </div>
      </div>

      {/* <div className='basket-item-collapse-header__plate'>
        {Plate ? <div className="search-card-item">
          <div className="search-card-item__plate">
            <div className="search-card-item__plate__1">{"NL"}</div>
            <div className="search-card-item__plate__2">{Plate?.toString().toUpperCase().replaceAll(' ', '').replaceAll('-', '')}</div>
          </div>
        </div> : null}
      </div> */}
    </div>
  )
}

export default BasketItemCollapseHeader
