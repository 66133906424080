import React, { useEffect, useState } from 'react'
import api from '@/services/api'
import { Button, Col, Input, Row, Skeleton, Table } from 'antd'
import { useTranslation } from 'react-i18next'
import { PlusOutlined } from '@ant-design/icons'
import useAddTab from '@/hooks/assistant/useAddTab'
const useFindArticleId = (reference, brandId) => {
    const [articleId, setArticleId] = useState(null)
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        if (reference && brandId) {
            setLoading(true)
            api().get('/catalog/getArticleId?Reference=' + reference + '&BrandId=' + brandId).then((e) => {
                setArticleId(e.data.data)
            }).catch(f => {

            }).finally(() => setLoading(false))
        }
    }, [reference, brandId])
    return { loading, articleId }
}


const useGetManifacturer = (articleId) => {
    const [manifacturer, setManifacturer] = useState([]);
    const [loadingMfr, setLoadingMfr] = useState(false);

    useEffect(() => {
        if (articleId) {
            setLoadingMfr(true)
            api().get('/catalog/getManifacturerByArticleId/' + articleId).then((e) => {
                setManifacturer(e.data.data)
            }).catch(f => {

            }).finally(() => setLoadingMfr(false))
        }
    }
        , [articleId])

    return { loadingMfr, manifacturer }
}


const MfrCard = ({ item, onClick }) => {

    return (<div onClick={() => onClick(item)} style={{
        border: '1px solid #e8e8e8',
        borderRadius: '2px',
        padding: '10px',
        marginBottom: '10px',
        textAlign: 'center',
        cursor: 'pointer'

    }}>
        <img src={item.logo} style={{
            width: '100%', height: '100px',

            objectFit: 'contain'
        }} />
        <div style={{ marginTop: '10px' }}>{item.manuName}</div>



    </div>)
}


const useGetApplicationlist = (articleId, manifacturerId) => {

    const [applicationList, setApplicationList] = useState([]);
    const [loadingApp, setLoadingApp] = useState(false);


    useEffect(() => {
        if (articleId && manifacturerId) {
            setLoadingApp(true)
            api().post('/catalog/applicationList/', {
                articleId: articleId,
                manuId: manifacturerId
            }).then((e) => {
                setApplicationList(e.data.data)
            }).catch(f => {

            }).finally(() => setLoadingApp(false))
        }
    }, [articleId, manifacturerId])

    return { loadingApp, applicationList }
}


const formatSixYear = (year) => {
    year = year?.toString()
    if (year) {
        if (year.length === 6) {
            return year.substring(0, 4) + '/' + year.substring(4, 6)
        }

        return year
    }

    return ''
}


const ApplicationListTecdoc = ({ reference, brandId,onSelectVehicle }) => {

    const [selectedManifacturer, setSelectedManifacturer] = useState(null);
    const [term, setTerm] = useState(null)
    const { loading, articleId } = useFindArticleId(reference, brandId);
    const { loadingMfr, manifacturer } = useGetManifacturer(articleId);
    const { loadingApp, applicationList } = useGetApplicationlist(articleId, selectedManifacturer?.manuId);

    const { t } = useTranslation()

    const addTab = useAddTab();

    const withSearch = (data, searchTerm) => {

        if (searchTerm) {
            return data.filter((item) => {
                return item.manuDesc.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.modelDesc.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.carDesc.toLowerCase().includes(searchTerm.toLowerCase())
            })
        }

        return data
    }


    const addVehicleToTab = (b) => {
        addTab({
            make: b.manuId, makeName: b.manuDesc, section: "vehicleMenu",
            vehicleLineId: b.modelId, vehiclineLineName: b.modelDesc,
            vehicleSeriesId: b.carId, vehicleSeriesName: b.carDesc,
            title: `${b.manuDesc} ${b.modelDesc} ${b.carDesc}`
        })

        if(onSelectVehicle) onSelectVehicle(b)

    }


    const columns = [
        {
            title: t('brand'),
            dataIndex: 'manuDesc',
            key: 'manuDesc',
        },

        {
            title: t('model'),
            dataIndex: 'modelDesc',
            key: 'modelDesc',
            render: (text, record) => record.carDesc + ' ' + record.modelDesc
        },
        {
            title: t('cyclinder'),
            dataIndex: 'cylinderCapacity',
            key: 'cylinderCapacity',
        },
        {
            title: t('horsePower'),
            dataIndex: 'powerHpFrom',
            key: 'powerHpFrom',
        },
        {
            title: t('yearOfConstructionFrom'),
            dataIndex: 'yearOfConstructionFrom',
            key: 'yearOfConstructionFrom',
            render: (text, record) => formatSixYear(text)

        },

        {
            title: t('yearOfConstructionTo'),
            dataIndex: 'yearOfConstructionTo',
            key: 'yearOfConstructionTo',
            render: (text, record) => formatSixYear(text)

        },
        {
            title: t('add'),
            dataIndex: 'add',
            key: 'add',
            render: (text, record) => <Button icon={<PlusOutlined />} type='primary' onClick={() => {
                addVehicleToTab(record)

            }} />

        }
    ]



    if (loading || loadingMfr || loadingApp) return (<Skeleton />)




    const onClickMfrCardHandler = (item) => {
        setSelectedManifacturer(item)
    }

    if (!selectedManifacturer)
        return (
            <Row gutter={16}>
                {manifacturer.map((item, index) => <Col key={index} lg={2}>
                    <MfrCard onClick={onClickMfrCardHandler} item={item} />
                </Col>)}

            </Row>
        )

    return (<div>
        <div style={{
            display: 'flex'
        }}>

            <Input.Search value={term} onChange={e => setTerm(e.target.value)} style={{
                borderRadius: '6px !important',
                marginRight: '6px'
            }}></Input.Search>
            <Button onClick={() => setSelectedManifacturer(null)} type='primary'>{t('back')}</Button>


        </div>
        <Table dataSource={withSearch(applicationList, term)} columns={columns} />

    </div>)



}

export default ApplicationListTecdoc
