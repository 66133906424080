import useAddTab from '@/hooks/assistant/useAddTab';
import { setActiveKey, setLastKey, setTabs } from '@/store/tabs';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';

//Local storage'e kaydedilmiş olan tabları okur ve yükler.
const useLoadTabFromStorage = () => {



    const tabsStore = useSelector((e) => e.tabs)
    const lastKey = tabsStore.lastKey

    const d = useDispatch();
    const addTab = useAddTab()
    useEffect(() => {
        try {


            let tabs = JSON.parse(localStorage.getItem("last-tabs"));
            let _tabs = tabs.map((item, index) => ({ ...item, key: lastKey + index + 1 }))
            d(setTabs(_tabs))
            d(setActiveKey(_tabs[_tabs.length - 1].key))
            d(setLastKey(_tabs[_tabs.length - 1].key +1))
        } catch (e) {

            addTab();

            //Hata oluşursa veya hiç bir tab yüklü değil ise 




        }
    }, [])

    return null;
}

export default useLoadTabFromStorage